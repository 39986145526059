<template>
  <div
    class="t-select-suggestion-wrapper"
    :class="{
       't-select-suggestion-wrapper--required': required,
    }"
  >
    <t-icon
      v-if="leftIcon"
      :name="leftIcon"
      class="t-select-suggestion-wrapper__left-icon"
    />
    <el-select
      class="t-select-suggestion"
      :class="additionalClasses"
      :value="value"
      :name="name"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      :disabled="disabled"
      @focus="onFocus();"
      @change="lostFocus();onSelectChange($event)"
      @blur="lostFocus();"
      :loading="loading"
      filterable
      :remote="remote"
      :filter-method="filterMethod"
      :remote-method="decorateRemoteMethod"
      :clearable="!hideClearIcon"
      popper-class="t-select-popper"
      ref="suggestion"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
      <div
        v-if="summary && options.length > 0"
        class="t-select-popper__summary"
        @click="summaryClick"
      >{{summary}}{{options.length}}</div>
    </el-select>
  </div>
</template>

<script>
  import {baseFieldMixin} from "../mixins/base-field-mixin"
  const { detect } = require('detect-browser');
  const browserName = detect().name

  export default {
    name: "t-select-suggestion",
    mixins: [baseFieldMixin],
    data: () => ({
      loading: false
    }),
    mounted() {
      this.$nextTick(this.disableAutocomplete)
    },
    updated() {
      this.disableAutocomplete()
    },
    watch: {
      'value': 'validateField',
    },
    props: {
      options: {
        type: Array,
        default: () => ([])
      },
      remoteMethod: {
        type: Function,
        default: async function () {}
      },
      filterMethod: {
        type: Function,
        default: function () {}
      },
      remote: {
        type: Boolean,
        default: false
      },
      allowCreate: {
        type: Boolean,
        default: false
      },
      summary: {
        type: String,
        default: ``,
      }
    },
    methods: {
      /**
       * Отключение автозаполнения
       */
      disableAutocomplete() {
        const inputs = this.$el.getElementsByTagName('input')

        for (const input of inputs) {
          if (browserName === `chrome`) {
            input.autocomplete = `chrome-off`
          } else {
            input.autocomplete = `off`
          }

          input.removeEventListener('keydown', this.onInputKeyDown)
          input.addEventListener('keydown', this.onInputKeyDown)
        }
      },
      onInputKeyDown(evt) {
        if (this.summary && evt.key === 'Enter') {
          this.$emit('summary', evt.target.value)

          this.$refs.suggestion.handleClose()
        }
      },
      async decorateRemoteMethod (query = ``) {
        const term = (query || ``).trim()

        try {
          this.loading = true
          await this.remoteMethod(term);
        } catch (e) {
          this.$emit('techError', e)
        } finally {
          this.loading = false
        }
      },
      /**
       * Возвращает domElement например для фокусировки на нем
       * если валидация провалена
       * @returns {HTMLElement}
       */
      getElement () {
        const node = this.$el.getElementsByTagName('input')
        if (node) {
          return node[0]
        }
        return null
      },
      summaryClick() {
        this.$emit('summary', this.$refs.suggestion.query)

        this.$refs.suggestion.handleClose()
      }
    },
    computed: {
      additionalClasses() {
        return {
          't-select-suggestion--is-invalid': this.hasError,
          't-select-suggestion--left-icon': this.leftIcon,
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";
  @import "./select-popper";

  .t-select-suggestion-wrapper {
    position: relative;

    .t-select-suggestion-wrapper__left-icon {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translate(0, -50%);
      color: $GrayG100;
    }

    .t-fake-placeholder {
      right: 30px;
    }

    .t-select-suggestion-wrapper__left-icon  ~ .t-fake-placeholder {
      left: 50px;
    }
  }

  .t-select-suggestion {
    font-family: $fontFamily;
    width: 100%;

    &.t-select-suggestion--is-invalid {
      &.el-select {
        .el-input {
          .el-input__inner {
            border: 1px solid $Error;
            background: $ErrorBg;
          }
        }
      }
    }

    &.el-select {
      height: 46px;

      .el-input {
        height: 100%;

        .el-input__inner {
          width: 100%;
          font-family: $fontFamily;
          border: 1px solid transparent;
          background: $GrayG20;
          border-radius: 16px;
          padding: 11px 30px 11px 15px;
          font-size: 14px;
          font-weight: 500;
          color: $BlackB80;
          outline: none;
          height: 46px;

          &:focus {
            border: 1px solid $Focus;
          }

          &:active {
            border: 1px solid $Focus;
          }

          &:disabled {
            border: 1px solid transparent;
          }

          &:disabled {
            border: 1px solid transparent;
            background-color: $BlackB10;
            color: $BlackB60;
          }

          &::placeholder {
            color: $Placeholder;
          }
        }

        .el-select__caret {
          &:before, &:after {
            display: none;
          }

          background: url('./icons/down.svg');
          background-repeat: no-repeat;
          background-position: center;

          .is-reverse {
            background: url('./icons/up.svg');
          }

          &.el-icon-circle-close {
            background: url('./icons/reset-icon.svg');
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &.is-disabled {
          .el-select__caret {
            background: url('./icons/down--disabled.svg');
            background-repeat: no-repeat;
            background-position: center;

            .is-reverse {
              background: url('./icons/up--disabled.svg');
            }
          }
        }
      }
    }

    &.t-select-suggestion--left-icon {
      &.el-select {
        .el-input {
          .el-input__inner {
            padding-left: 45px;
          }
        }
      }
    }
  }

  .t-select-suggestion-wrapper__left-icon ~ .t-fake-placeholder {
    left: 48px;
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-select-suggestion
        v-model="foo"
        name="bar"
        :options="posts"
        :remote-method="postsRemoteMethod"
        :remote="true"
        placeholder="Введите qui"
      ></t-select-suggestion>
      <p>used: https://element.eleme.io/#/en-US/component/select</p>
    </div>
  </template>

  <script>
    export default {
      data: () => ({
        posts: [],
        foo: ''
      }),
      methods: {
        postsRemoteMethod (q) {
          if (q) {
            return fetch(`https://jsonplaceholder.typicode.com/posts?q=${q}`)
              .then(r => r.json())
              .then(data => {
                this.posts = data.map(({id, title}) => ({id, name: title}))
              })
          } else {
            this.posts = []
            return Promise.resolve()
          }
        }
      }
    }
  </script>
  ```
</docs>

