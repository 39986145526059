import {fabric} from 'fabric'
import {textToPoly} from './textToPoly'

function makePolyline(coords) {
  return new fabric.Polyline(coords, {
    fill: '#0071A8',
    stroke: '#0071A8',
    strokeWidth: 5,
    objectCaching: false,
    selectable: false,
    evented: false,
  })
}

function makeCircle(point) {
  const radius = 4
  const strokeWidth = 1

  return new fabric.Circle({
    left: point.x - radius - strokeWidth,
    top: point.y - radius - strokeWidth,
    strokeWidth,
    radius,
    fill: '#fff',
    stroke: '#666',
    hasBorders: false,
    hasControls: false,
    hasRotatingPoint: false,
  })
}

export function errorStatusCodeDrawer(canvasFabricInstance, canvasRef, code, parentRef) {
  const rect = parentRef.getBoundingClientRect()
  const height = 400

  const canvas = canvasFabricInstance || new fabric.Canvas(canvasRef)
  canvas.setWidth(rect.width)
  canvas.setHeight(height)
  canvas.selection = false
  canvas.calcOffset()
  canvas.clear()

  //console.time('calc poly')
  const {polygons} = textToPoly(code, {
    fontFamily: '"Montserrat", Tahoma',
    textHeight: Math.round(height * 0.8),
    paperHeight: height,
    paperWidth: rect.width,
    align: 'center',
  })
  //console.timeEnd('calc poly')

  //console.time('draw poly')
  polygons.forEach((poly, polyIndex) => {
    const letter = makePolyline(poly)
    canvas.add(letter)

    poly.forEach((point, pointIndex) => {
      const c = makeCircle(point)
      c.pointIndex = pointIndex
      c.polyIndex = polyIndex
      c.letter = letter
      canvas.add(c)
    })
  })
  //console.timeEnd('draw poly')

  canvas.on('object:moving', function(e) {
    const c = e.target

    // check for canvas bounds
    const position = c.getCenterPoint()
    if (position.x < 0) {
      c.left = 0
    }
    if (position.y < 0) {
      c.top = 0
    }
    if (position.x > c.canvas.width) {
      c.left = c.canvas.width - 10
    }
    if (position.y > c.canvas.height) {
      c.top = c.canvas.height - 10
    }
    c.setCoords()

    if (c.letter) {
      const position = c.getCenterPoint()
      c.letter.points[c.pointIndex] = {
        x: position.x,
        y: position.y
      }
    }
  })

  return canvas
}
