<template>
  <menu class="left-menu">

    <t-button
      @click="createReq()"
      design="primary"
    >Создание Заявки на ремонт</t-button>

    <ul>
      <li>
        <router-link
          :to="{name: 'requests'}"
        >
          <t-icon name="readme"></t-icon>
          Журнал заявок
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: 'notifications'}"
        >
          <t-icon name="bell"></t-icon>
          Уведомления
          <t-counter
            v-if="$store.state.unreadCount"
          >
            {{$store.state.unreadCount}}
          </t-counter>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{name: 'orders'}"
        >
          <t-icon name="eReader"></t-icon>
          Журнал заказ нарядов
        </router-link>
      </li>

      <li>
        <router-link
          :to="{name: 'acts'}"
        >
          <t-icon name="circleTick"></t-icon>
          Акты выполненных работ
        </router-link>
      </li>

      <li>
        <router-link
          :to="{name: 'reports'}"
        >
          <t-icon name="report"></t-icon>
          Отчеты
        </router-link>
      </li>

      <li>
        <router-link
          :to="{name: 'support'}"
        >
          <t-icon name="help"></t-icon>
          Поддержка
        </router-link>
      </li>
    </ul>
  </menu>
</template>

<script>
  export default {
    name: "left-menu",
    methods: {
      createReq() {
        this.$router.push({ name: 'requests.requestCreate' }, () => '', () => '')
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .left-menu {
    padding: 0;
    margin: 0;
    font-family: $fontFamily;
    letter-spacing: -0.4px;

    .t-button {
      margin: 24px 30px;
      font-size: 14px;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        margin-bottom: 11px;

        a {
          width: 100%;
          height: 100%;
          padding: 16px 20px;
          text-decoration: none;
          font-weight: bold;
          font-size: 15px;
          color: $BlackB100;
          border: 1px solid transparent;
          border-left: 3px solid transparent;
          display: flex;
          align-items: center;
          position: relative;

          .t-icon {
            margin-right: 16px;
            width: 24px;
            text-align: center;
          }

          .t-counter {
            position: absolute;
            right: 20px;
          }

          &.router-link-active {
            color: $BlueB100;
            background: $BlueB10;
            border-left: 3px solid $BlueB100;
          }
        }
      }
    }
  }
</style>
