<template>
  <component
    :is="tagAndSpecialClass[0]"
    :class="[`t-title`, tagAndSpecialClass[1]]"
  >
    <slot></slot>
  </component>
</template>

<script>
  export default {
    name: "t-title",
    props: {
      type: {
        type: String,
        default: 'h2',
        required: true,
      },
    },
    computed: {
      tagAndSpecialClass() {
        return this.type.split('-')
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-title {
    font-family: $fontFamily;
  }

  h2 {
    &.t-title {
      font-weight: bold;
      font-size: 22px;
      letter-spacing: -0.4px;
      color: $BlackB100;
      line-height: 30px;
    }
  }

  h3 {
    &.t-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4px;
      color: $BlackB100;

      &.primary {
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $BlueB100;
        line-height: 20px;

        margin-top: 18px;
        margin-right: 0px;
        margin-bottom: 8px;
        margin-left: 0px;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-title type="h2">Title h2</t-title>
      <t-title type="h3">Title h3</t-title>
      <t-title type="h3-primary">Title h3-primary</t-title>
    </div>
  </template>
  <script>
    export default {}
  </script>
  ```
</docs>
