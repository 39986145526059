import {getRouteCopy} from "./url"
import {DEFAULT_REDIRECT, HIDDEN_ROUTES_FOR_REGISTERED, DEVELOPER_MENU_ROUTE_PREFIX} from "../configs/config"

/**
 * Перед переходом к новому роуту проверяет
 * разрешен или запрещен переход
 * @param to
 * @param from
 * @param next
 * @param store
 */
export function handleBeforeEach (to, from, next, store) {
  if (to && to.name && to.name.startsWith(DEVELOPER_MENU_ROUTE_PREFIX)) {
    // eslint-disable-next-line
    console.warn('Переход в dev menu, все проверки на переход отключены')
    next()
    return
  }

  if (to.meta && to.meta.requiresAuth === false) {
    if (HIDDEN_ROUTES_FOR_REGISTERED.indexOf(to.name) > -1 && store.getters.isGuest === false) {
      next(DEFAULT_REDIRECT.mainPage)
    } else {
      next()
    }
  } else {
    if (store.getters.isGuest) {
      if (HIDDEN_ROUTES_FOR_REGISTERED.indexOf(to.name) === -1) {
        store.commit('userSetRedirectUrl', getRouteCopy(to))
      }
      next(DEFAULT_REDIRECT.loginPage)
    } else {
      next()
    }
  }
}

/**
 * При изменении роута, вызывает метод asyncData
 * обратно совместим с ssr
 * @link https://github.com/vuejs/vue-hackernews-2.0/blob/d82eec8098b09945b8828a3d531e90bb0469d931/src/entry-client.js#L40
 * @param to
 * @param from
 * @param next
 * @param router
 * @param store
 * @param bar
 * @returns {*}
 */
export function handleBeforeResolve ({to, from, next}, {router, store, bar}) {
  const matched = router.getMatchedComponents(to)
  const prevMatched = router.getMatchedComponents(from)
  let diffed = false
  const activated = matched.filter((c, i) => {
    return diffed || (diffed = (prevMatched[i] !== c))
  })
  if (!activated.length) {
    return next()
  }

  if (router.skipAsync) {
    return next()
  }

  bar.start()
  Promise.all(
    activated.map(c => {
      if (c.asyncData) {
        return c.asyncData({
          store,
          route: to,
          fromRoute: from,
          methods: c.methods,
          ctx: c
        })
      }
      return Promise.resolve()
    })
  ).then(() => {
    if (to && to.name && to.name !== 'error') {
      store.commit('mutateError', null)
    }
    bar.finish()
    next()
  }).catch(e => {
    bar.finish()
    store.commit('mutateError', e)
    next()
  })
}
