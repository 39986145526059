<template>
  <t-div-table
    :table-data="item"
    @rowClick="onRowClick"
    :activeItemId="activeItemId"
    class="orders-table"
  >
    <div
      slot="number"
      slot-scope="{row}"
    >
      {{row.number}}
      <time>{{row.create_dt | formatFullDate}}</time>
    </div>
    <div
      slot="status"
      slot-scope="{row}"
    >
      <t-color-progress
        :optionId="row.status"
        :options="statuses"
      />
    </div>
  </t-div-table>
</template>

<script>
  import {ORDER_STATUSES} from "../../../configs/config";

  export default {
    name: "orders-table",
    props: {
      itemData: {
        type: Object,
        default: () => ({
          items: [],
        }),
      },
      activeItemId: undefined,
    },
    methods: {
      onRowClick(row) {
        this.$router.push({
          name: 'orders.orderView',
          params: {id: row.id},
        }, () => {}, () => {})
      },
    },
    computed: {
      statuses() {
        return [...ORDER_STATUSES]
      },
      item() {
        return {
          "data": this.itemData.items,
          "_meta": {
            "showedFields": {
              "number": {
                "title": "№ Заказ наряда"
              },
              "vehicle": {
                "title": "Гос номер. модель ТС"
              },
              "work_type": {
                "title": "Вид ремонта"
              },
              "start_date_plan": {
                "title": "Плановая дата начала",
                date: true,
              },
              "status": {
                "title": "Статус"
              },
              "customer": {
                "title": "Заказчик"
              },
              "department": {
                "title": "Подразделение заказчика"
              },
              "sum_nds": {
                "title": "Сумма, руб",
                number: true,
              },
            }
          }
        }
      }
    },
  }
</script>

<style lang="scss">
  .orders-table {
    .t-div-table__col--number {
      time {
        margin-top: 7px;
        display: block;
        font-size: 10px;
      }
    }

    .t-div-table__col--status {
      .t-color-progress {
        margin-top: -8px;

        .t-color-progress__bar {
          width: 100px;
        }
      }

      .t-div-table__col__title {
        display: none;
      }
    }

    .t-div-table__row {
      cursor: pointer;

      .t-div-table__col {
        width: 8.8%;

        &.t-div-table__col--status {
          flex-grow: 0;
          width: 150px;
        }

        &:last-child {
          flex-grow: 0;
          width: 100px;
        }
      }
    }
  }
</style>
