/**
 * Глобальный миксин, вызывается при смене параметров роута
 * служит для презагрузки данных, обратно совместим с ssr
 * @link https://github.com/vuejs/vue-hackernews-2.0/blob/d82eec8098b09945b8828a3d531e90bb0469d931/src/entry-client.js#L12
 */

export const routerMixin = {
  beforeRouteUpdate (to, from, next) {
    if (this.$router.skipAsync) {
      return next()
    }

    const { asyncData } = this.$options

    if (asyncData) {
      this.$bar.start()
      asyncData({
        store: this.$store,
        route: to,
        fromRoute: from,
        methods: this,
        ctx: this
      }).then(() => {
        /*if (to && to.name && to.name !== 'error') {
          this.$store.commit('mutateError', null)
        }*/
        this.$bar.finish()
        next()
      }).catch(e => {
        this.$bar.finish()
        this.$store.commit('mutateError', e)
        next()
      })
    } else {
      next()
    }
  }
}
