<template>
  <div class="t-list-numeric">
    <div
      v-for="(item, index) in options"
      :key="index"
      class="t-list-numeric__item"
    >
      <div class="t-list-numeric__item__index">
        {{index + 1}}
      </div>
      <div class="t-list-numeric__item__name">
        {{item.name || item}}
      </div>
    </div>
    <div v-if="options.length === 0">
      <p> </p>
      <p>Нет элементов доступных для отображения</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-list-numeric",
    props: {
      options: {
        type: Array,
        default: () => ([]),
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-list-numeric {
    font-family: $fontFamily;
    letter-spacing: -0.4px;
    color: $GrayG100;
    padding: 12px 0;

    .t-list-numeric__item {
      display: flex;
      align-items: center;

      > div {
        margin: 12px 24px 12px 0;

        &:last-child {
          margin-right: 0;
        }
      }

      .t-list-numeric__item__index {
        height: 24px;
        min-width: 24px;
        width: 24px;
        font-weight: bold;
        font-size: 12px;
        line-height: 21px;
        border: 1px solid $GrayG100;
        border-radius: 50%;
        text-align: center;
      }

      .t-list-numeric__item__name {
        font-size: 14px;
        background: $GrayG05;
        padding: 11px 24px;
        border-radius: 16px;
        width: 100%;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-list-numeric
        :options="defects"
      />
    </div>
  </template>
  <script>
    export default {
      data: () => ({
        defects: ["Не работают фары", "Разбито крыло"],
      })
    }
  </script>
  ```
</docs>
