<template>
  <div
    class="reports reports-journal"
  >
    <build-report-modal
      v-if="buildReportModalIsOpened"
      @close="buildReportModalIsOpened=false"
      ref="buildReportModal"
    />

    <t-filter-block>
      <template slot="right-actions">
        <t-button
          design="primary"
          @click="openBuildReportForm()"
        >
          Создать отчет
        </t-button>
      </template>

      <t-form
        name="reportsFilter"
        :formValidator="formValidator"
      >
        <t-form-group
          placeholder="Сортировка"
          name="sort"
          v-model="model.sort"
          type="select"
          :options="sort"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
        <t-form-group
          placeholder="Дата"
          name="_d"
          v-model="model.dt_from"
          type="calendar"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
        <t-form-group
          placeholder="Организация"
          name="_rc"
          v-model="model[keys.CUSTOMER_KEY]"
          type="select"
          :options="customers"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
        <t-form-group
          placeholder="Подразделение"
          name="_rd"
          v-model="model[keys.DEPARTMENT_KEY]"
          type="select-suggestion"
          :options="customersDepartments"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
      </t-form>

      <template slot="actions">
        <t-button
          iconType="resetFilter"
          icon
          transparent
          @click="resetFilters()"
        ></t-button>
      </template>
    </t-filter-block>

    <t-round-block
      class="reports-journal__table-toolbar"
      v-if="item.data.length > 0"
    >
      <div class="reports-journal__table-toolbar__links">
        <a
          href="#"
          @click.stop.prevent="selectAll()"
        >Выбрать все</a>

        <a
          v-if="checkedIds.length"
          href="#"
          @click.stop.prevent="resetSelected()"
          class="reports-journal__table-toolbar__links__reset"
        >Сбросить выделенное</a>

        <a
          v-if="checkedIds.length"
          href="#"
          @click.stop.prevent="downloadSelected()"
          class="reports-journal__table-toolbar__links__download"
        >
          <t-icon name="download"/>
          Скачать выбранные
        </a>
      </div>
      <div>
        <t-pagination-simple
          :meta="itemMeta"
          :show-info="false"
          show-pages
        ></t-pagination-simple>
      </div>
    </t-round-block>

    <t-div-table
      :table-data="item"
      :getRowClass="getRowClass"
      @rowClick="onRowClick"
    >
      <div
        slot="customer"
        slot-scope="{row}"
      >
        {{(customers.find(c => c.id === row.meta.customerId) || { name: `-` }).name}}
      </div>
      <div
        slot="department"
        slot-scope="{row}"
      >
        {{(departments.find(d => d.id === row.meta.customerDepartmentId) || { name: `-` }).name}}
      </div>
      <div
        slot="period"
        slot-scope="{row}"
      >
        {{row.meta.dateFrom | formatDate}}-{{row.meta.dateTo | formatDate}}
      </div>
      <div
        slot="file_size"
        slot-scope="{row}"
      >
        {{row.file_size | formatBytes}}
      </div>
      <div
        slot="download"
        slot-scope="{row}"
      >
        <t-icon
          :name="downloadingIds.includes(row.id) ? `loading` : `download`"
          @click="downloadFile(row)"
        />
      </div>
    </t-div-table>
  </div>
</template>

<script>
  import {FormValidator} from "../../components";
  import {queryToModel} from "../../utils/helpers";
  import {REST} from "vue-fast-rest";
  import {reports, voc} from "../../api/endpoints";
  import BuildReportModal from "./components/BuildReportModal";
  import { saveAs } from 'file-saver';
  import {DEFAULT_ORDERS} from "../../configs/config";

  const DEPARTMENT_KEY = 'root_department_id'
  const CUSTOMER_KEY = `customer_id`

  export default {
    components: {BuildReportModal},
    data: () => ({
      checkedIds: [],
      buildReportModalIsOpened: false,
      downloadingIds: [],
      model: {
        sort: '',
        dt_from: '',
        [CUSTOMER_KEY]: '',
        [DEPARTMENT_KEY]: '',
      },
    }),
    created() {
      this.formValidator = new FormValidator()

      queryToModel(this.$route.query, this.model, {
        onTransform: this.transformQueryToModel,
        disableArrays: true,
      })

      this.$watch(
        () => this.model[CUSTOMER_KEY],
        this.onCustomerIdChanged,
      )

      this.subsGroup = this.$emitter.group(
        this.$emitter.listen('report.newModel', this.onNewReport),
      )
    },
    destroyed () {
      this.subsGroup.unsubscribe()
    },
    watch: {
      '$route': {
        handler() {
          queryToModel(this.$route.query, this.model, {
            onTransform: this.transformQueryToModel,
            disableArrays: true,
          })
        }
      },
    },
    methods: {
      onNewReport(reportItem) {
        const itemData = JSON.parse(JSON.stringify(this.itemData))

        if (this.$refs.buildReportModal && this.$refs.buildReportModal.model) {
          reportItem = {
            ...reportItem,
            meta: {
              ...this.$refs.buildReportModal.model,
              dateFrom: this.$refs.buildReportModal.model.dateFrom,
              dateTo: this.$refs.buildReportModal.model.dateTo,
              ...reportItem.meta,
            }
          }
        }

        this.$store.commit(REST.mutations.updateEndpoint, {
          endpoint: reports.listOfReports(this.$route),
          response: {
            data: {
              ...itemData,
              items: [
                reportItem,
                ...itemData.items,
              ],
            },
          }
        })
      },
      selectAll() {
        this.itemData.items.forEach(row => {
          this.checkedIds.push(row.id)
        })
      },
      resetSelected() {
        this.checkedIds = []
      },
      async downloadSelected() {
        let lastError = null

        for (const row of this.itemData.items) {
          if (this.checkedIds.includes(row.id)) {
            try {
              await this.downloadFile(row)
            } catch (e) {
              lastError = e
            }
          }
        }

        if (lastError) {
          throw lastError
        }
      },
      async downloadFile(row) {
        try {
          this.downloadingIds.push(row.id)

          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            url: reports.downloadReport(row.id),
            params: {
              responseType: 'blob',
              disableGlobalError: true,
            }
          })

          saveAs(data, row.file_name)
        } catch (e) {
          this.$notification.jsError(e)
        } finally {
          this.downloadingIds = this.downloadingIds.filter(id => id !== row.id)
        }
      },
      onRowClick(row) {
        if (!this.checkedIds.includes(row.id)) {
          this.checkedIds.push(row.id)
        } else {
          this.checkedIds = this.checkedIds.filter(id => id !== row.id)
        }
      },
      getRowClass(row) {
        if (this.checkedIds.includes(row.id)) {
          return `t-div-table__row--selected`
        }
        return ``
      },
      openBuildReportForm() {
        this.buildReportModalIsOpened = true
      },
      transformQueryToModel(v, name) {
        if (name === `sort` && !v) {
          return DEFAULT_ORDERS.reports
        }
        if ([`sort`].includes(name)) {
          return `${v || ``}`
        }
        if (v && [`dt_from`].includes(name)) {
          return new Date(v)
        }
        return v ? +v : ``
      },
      onSomeFieldChangedInFilter() {
        const query = {
          ...this.$route.query,
          ...this.model,
        }
        query.dt_from = query.dt_from ? new Date(query.dt_from).toISOString() : undefined

        this.$router.push({query}, () => {}, () => {})
      },
      resetFilters() {
        this.$router.push({name: 'reports'}, () => {}, () => {})
      },
      async onCustomerIdChanged(customerId) {
        this.model[DEPARTMENT_KEY] = ``

        this.onSomeFieldChangedInFilter()

        if (customerId) {
          await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            endpoint: voc.customerDepartments(customerId),
            cache: Infinity,
          })
        }
      },
    },
    computed: {
      customers() {
        if (this.$store.getters.userProfile && this.$store.getters.userProfile.customers) {
          return this.$store.getters.userProfile.customers
        }
        return []
      },
      departments() {
        const data = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.department(``),
        )

        if (data && data.data && data.data.items) {
          return data.data.items
        }

        return []
      },
      customersDepartments() {
        const data = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.customerDepartments(this.model[CUSTOMER_KEY]),
        )

        if (data && data.data && data.data.items) {
          return data.data.items
        }

        return []
      },
      keys() {
        return {
          DEPARTMENT_KEY,
          CUSTOMER_KEY,
        }
      },
      sort() {
        return [
          {id: 'create_dt-', name: 'Сначала новые'},
          {id: 'create_dt+', name: 'Сначала старые'},
        ]
      },
      item() {
        return {
          data: this.itemData.items,
          "_meta": {
            "showedFields": {
              "create_dt": {
                "title": "Дата и время создания",
                date: true,
              },
              "file_name": {
                "title": "Наименование",
              },
              "period": {
                "title": "За период",
              },
              "customer": {
                "title": "Организация"
              },
              "department": {
                "title": "Подразделение"
              },
              "file_size": {
                "title": "Размер"
              },
              "download": {},
            }
          }
        }
      },
      itemData() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          reports.listOfReports(this.$route),
        )
      },
      itemMeta() {
        return {
          page: this.$route.query.page || 0,
          page_count: this.itemData.page_count,
          page_size: this.itemData.page_size,
          total_count: this.itemData.total_count,
          count: this.itemData.items.length,
        }
      },
    },
    async asyncData({store, route}) {
      if (route.query[CUSTOMER_KEY]) {
        await store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.customerDepartments(route.query[CUSTOMER_KEY]),
          cache: Infinity,
        })
      }

      await Promise.all([
        store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.department(``),
          cache: Infinity,
        }),
        store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: reports.listOfReports(route),
        }),
      ])
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .reports-journal {
    .t-round-block {
      box-shadow: $Sh_1;
    }

    .t-filter-block {
      margin-bottom: 10px;
      box-shadow: $Shadows_cell_cards;

      .t-button {
        font-weight: bold;
        font-size: 14px;
        margin: 8px;
      }

      .t-filter-block__right-actions {
        width: 225px;

        .t-button {
          width: 155px;
        }
      }

      .t-form {
        justify-content: end;
      }

      .t-filter-block__actions {
        .t-button {
          width: 90px;
          justify-content: flex-end;
          color: $Error;
        }
      }
    }

    .reports-journal__table-toolbar {
      display: flex;
      align-content: center;
      justify-content: space-between;
      padding: 0 24px;
      padding-left: 33px;
      padding-right: 18px;
      border-radius: 8px;
      margin-bottom: -2px;

      > div {
        display: flex;
        align-content: center;
      }

      .reports-journal__table-toolbar__links {
        font-size: 12px;
        display: flex;
        align-items: center;

        a {
          text-decoration: none;
          margin-right: 40px;
          color: $BlueB100;
        }

        .reports-journal__table-toolbar__links__reset {
          color: $RedR100;
        }

        .reports-journal__table-toolbar__links__download {
          display: flex;
          align-items: center;

          .t-icon {
            margin-right: 16px;
          }
        }
      }
    }

    .t-div-table {
      .t-div-table__row {
        cursor: pointer;
        box-shadow: $Shadows_cell_cards;

        .t-div-table__col {
          width: 13%;

          &.t-div-table__col--file_size {
            width: 50px;
            margin-right: 0;
          }
          &.t-div-table__col--download {
            width: 50px;
            margin-right: 0;
          }
        }

        &:hover {
          background: $ItemTableHover;
          cursor: pointer;

          .t-div-table__col {
            .t-div-table__col__title {
              color: $BlackB60;
            }

            .t-div-table__col__content {
              color: $BlackB60;
            }
          }
        }
        &.t-div-table__row--selected {
          background: $ItemSelectedBg;
        }
      }

      .t-div-table__col--download {
        .t-div-table__col__title {
          display: none;
        }

        .t-div-table__col__content {
          height: 100%;
          align-items: center;
          display: flex;
          margin-top: 0;
          color: $GrayG80;

          .t-icon {
            color: $GrayG100;
            cursor: pointer;

            &:hover {
              color: $BlueB100;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1320px) {
    .reports-journal {
      .t-filter-block {
        .t-form {
          flex-wrap: wrap;

          .t-form-group {
            width: 40%;
            margin-bottom: 14px;
          }
        }
      }
    }
  }

  @media (max-width: $mdDeviceWidth) {
    .reports-journal {
      .t-filter-block {
        .t-form {
          .t-form-group {
            width: 100%;
          }
        }
      }
    }
  }
</style>
