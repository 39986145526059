var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-div-table',{staticClass:"repair-requests-table",attrs:{"table-data":_vm.item,"activeItemId":_vm.activeItemId},on:{"rowClick":_vm.onRowClick},scopedSlots:_vm._u([{key:"account",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("fullName")(row.account))+" ")])}},{key:"number",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.number || 'n/a')+" "),_c('time',[_vm._v(" "+_vm._s(_vm._f("formatFullDate")(row.created_at))+" ")])])}},{key:"car",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.vehicle)?_c('div',[_vm._v(" "+_vm._s(row.vehicle.name)+" ")]):_vm._e()])}},{key:"work_orders",fn:function(ref){
var row = ref.row;
return _c('div',{},[((row.work_orders || []).length > 0)?_vm._l((row.work_orders),function(order){return _c('div',{key:order.id,on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":{
            name: "orders.orderView",
            params: { id: order.id },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(order.number)+" ")])],1)}):[_vm._v(" - ")]],2)}},{key:"status",fn:function(ref){
          var row = ref.row;
return _c('div',{},[_c('t-color-progress',{attrs:{"optionId":row.status,"options":_vm.statuses}})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }