/**
 * Maskable
 *
 * @mixin
 * @source https://github.com/vuetifyjs/vuetify/blob/980f04b6b0c82b1a4895b935bffa1cbb5eb19e19/src/mixins/maskable.js
 *
 * Creates an input mask that is
 * generated from a masked str
 *
 * Example: mask="#### #### #### ####"
 */

import {
  isMaskDelimiter,
  maskText,
  unmaskText,
} from './mask-util'

export default {
  name: 'maskable',

  data: () => ({
    selection: 0,
    lazySelection: 0,
    preDefined: {
      'credit-card': '#### - #### - #### - ####',
      'date': '##/##/####',
      'date-with-time': '##/##/#### ##:##',
      'phone': '(###) ### - ####',
      'social': '###-##-####',
      'time': '##:##',
      'time-with-seconds': '##:##:##'
    }
  }),

  props: {
    dontFillMaskBlanks: Boolean,
    mask: {
      type: [Object, String],
      default: null
    },
    returnMaskedValue: Boolean
  },

  mounted() {
    if (this.value) {
      this.$emit(`input`, this.maskText(this.value))
    }
  },

  updated() {
    if (this.value) {
      this.$emit(`input`, this.maskText(this.value))
    }
  },

  computed: {
    masked () {
      const preDefined = this.preDefined[this.mask]
      const mask = preDefined || this.mask || ''

      return mask.split('')
    }
  },

  methods: {
    setCaretPosition (selection) {
      this.selection = selection
      window.setTimeout(() => {
        this.getElement() && this.getElement().setSelectionRange(this.selection, this.selection)
      }, 0)
    },
    updateRange () {
      if (!this.getElement()) return

      const newValue = this.maskText(this.lazyValue)
      let selection = 0

      this.getElement().value = newValue
      if (newValue) {
        for (let index = 0; index < newValue.length; index++) {
          if (this.lazySelection <= 0) break
          isMaskDelimiter(newValue[index]) || this.lazySelection--
          selection++
        }

        // fix: https://task.tetron.ru/issue/AL-112
        if (!this.value) {
          selection = newValue.length
        }
      }

      this.setCaretPosition(selection)
      // this.$emit() must occur only when all internal values are correct
      // this.$emit('input', this.returnMaskedValue ? this.getElement().value : this.lazyValue)
    },
    maskText (text) {
      return this.mask ? maskText(text, this.masked, this.dontFillMaskBlanks) : text
    },
    unmaskText (text) {
      return this.mask && !this.returnMaskedValue ? unmaskText(text) : text
    },
    // When the input changes and is
    // re-created, ensure that the
    // caret location is correct
    setSelectionRange () {
      this.$nextTick(this.updateRange)
    },
    resetSelections (input) {
      if (!input.selectionEnd) return
      this.selection = input.selectionEnd
      this.lazySelection = 0

      for (let index = 0; index < this.selection; index++) {
        isMaskDelimiter(input.value[index]) || this.lazySelection++
      }
    }
  }
}
