/**
 * @param delayInMs
 * @returns {Promise<any>}
 */
import {formatFullDate} from "./string";

export function sleep(delayInMs = 200) {
  return new Promise(resolve => setTimeout(resolve, delayInMs))
}

export function transformQueryParamsValue(v) {
  if (!v) {
    return "";
  }
  if(isNaN(+v)) {
    return v;
  }
  return +v;
}

/**
 * Преробразует foo=1&var=2 в model:{foo: 1, var: 2}
 */
export function queryToModel(
  query = {},
  model = {}, {
    onTransform,
    disableArrays = false,
  } = { onTransform: transformQueryParamsValue }) {
  for (let attrName in model) {
    if (Array.isArray(model[attrName]) && !disableArrays) {
      if (!Array.isArray(query[attrName])) {
        model[attrName] = [onTransform(query[attrName], attrName)]
      } else {
        model[attrName] = onTransform(query[attrName], attrName)
      }
    } else {
      model[attrName] = onTransform(query[attrName], attrName)
    }
  }
}

export function onlyUniqItemsInRef(oldItems = [], newItems = [], key = `id`) {
  const arr = [
    ...oldItems,
    ...newItems,
  ]

  return [...new Map(arr.map(item =>
    [item[key], item])).values()]
}

export function replacePlaceholders(text = ``, obj = {}) {
  return text.replace(/<!--([a-zA-Z0-9]+):([a-zA-Z0-9_-]+)-->/g, function (match, type, name) {
    if (type === `datetime`) {
      return formatFullDate(obj[name])
    }
    return obj[name] || ``
  })
}
