<template>
  <div
    class="t-round-block"
    :class="{
      't-round-block--active': active,
    }"
    @click="$emit('click', $event)"
    @mouseover="$emit('mouseover', $event)"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "t-round-block",
    props: {
      active: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-round-block {
    background: $WhiteW100;
    border-radius: 16px;
    padding: 16px;

    &.t-round-block--active {
      background-color: $BlueB10;
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div style="background: #E0E3E9; padding: 10px">
      <t-round-block>
        param pam pam
      </t-round-block>
    </div>
  </template>
  ```
</docs>
