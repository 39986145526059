export default {
  userServerlessData: state => {
    return state._userServerlessData
  },
  authStatus: state => {
    return state._authStatus
  },
  jwtToken: (state, getters) => {
    const userData = getters.userData
    if (userData && userData.jwt) {
      return userData.jwt
    }
    return null
  },
  authToken: (state, getters) => {
    const userData = getters.userData
    if (userData && userData.jwt) {
      return userData && userData.jwt.token
    }
    return null
  },
  isGuest: (state, getters) => {
    return !(getters.authToken && getters.userProfile)
  },
  userData: state => {
    return state._userData || null
  },
  userProfile: (state, getters) => {
    const userData = getters.userData
    if (userData && userData.user) {
      return userData.user
    }
    return null
  },
  redirectUrl: state => {
    return state._redirectUrl
  },
  error: state => {
    return state._error
  }
}
