<template>
  <!-- set id for ssr reasons -->
  <div
    id="app"
    :class="classes"
  >
    <router-view/>
    <wss v-if="!$store.getters.isGuest"/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import {loginMixin} from '../../mixins/login-mixin'
  import {currentUserBrowser} from "../../components/utils/helpers"
  import Wss from "../../common/wss/wss";

  export default {
    components: {Wss},
    mixins: [loginMixin],
    computed: {
      ...mapGetters([
        'authStatus'
      ]),
      classes () {
        const parts = (this.$route.name || '').split('.')

        return [
          `route-${parts.join('-')}`,
          `browser-${currentUserBrowser.name}`
        ]
      },
    },
    watch: {
      'authStatus': 'onAuthStatus'
    },
    created () {
      this.$store.dispatch('startJwtTokenRenewTimeChecker')

      if (!this.$store.getters.isGuest) {
        this.$store.dispatch('updateUnread')
      }
    },
    methods: {
      onAuthStatus({loggedIn = false} = {}) {
        if (loggedIn === false) {
          this.logoutAndGoToLoginPage()
        } else {
          this.goToPrevOrMainPage()
        }
      }
    }
  }
</script>

<style lang="scss">
  html, body {
    height: 100%;

    #app {
      height: 100%;
    }
  }
</style>
