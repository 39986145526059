<template>
  <t-page-layout-article
    title="Страница диагностики"
    :onBackRedirectToRoute="{path: '/'}"
  >
    <div class="row">
      <div class="col-md-6">
        <h3>Проверки</h3>

        <div>
          <h4>Отчеты об ошибках</h4>
          <p>Нажмите на кнопку, чтобы отправить отчет об ошибке в трекер ошибок</p>
          <div>
            <t-button
              full-width
              @click="generateTestError()"
            >Создать ошибку</t-button>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="m-b-45">
          <h3>Сводная информация</h3>
          <ul>
            <li>Версия: {{VUE_APP_VERSION}}</li>
            <li>Имя присвоенное системой этому браузеру (deviceId): {{deviceId}}</li>
            <li>Дата компиляции: {{VUE_APP_LAST_BUILD}}</li>
            <li>Адрес api: {{ROOT_API}}</li>
            <li>Адрес сервера AD авторизации: {{AUTH_SERVER || '(авторизация по ad выключена)'}}</li>
            <li>Браузер: {{currentUserBrowser.name}} {{currentUserBrowser.version}}</li>
            <li>ОС: {{currentUserBrowser.os}}</li>
            <li>Разница в минутах между локальным временем и UTC: {{new Date().getTimezoneOffset()}}</li>
            <li>Юзерагент: {{userAgent}}</li>
          </ul>
        </div>

        <div class="m-b-45">
          <h3>Пользователь</h3>
          <ul>
            <li>Авторизован: {{$store.getters.isGuest ? 'Нет' : 'Да'}}</li>
            <template
              v-if="!$store.getters.isGuest"
            >
              <li>ID: {{$store.getters.userProfile.id}}</li>
              <li>Имя: {{$store.getters.userProfile.name}}</li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </t-page-layout-article>
</template>

<script>
  import {ROOT_API} from "../../configs/config"
  import {getDeviceId} from "../../utils/random"
  import {currentUserBrowser} from "../../components/utils/helpers"

  export default {
    methods: {
      generateTestError () {
        throw new Error(`Моя тестовая ошибка созданная в ${new Date().toLocaleString()} и отправленная с диагностической страницы`)
      },
    },
    computed: {
      /**
       * @return {string}
       */
      VUE_APP_VERSION () {
        return process.env.VUE_APP_RELEASE
      },
      /**
       * @return {string}
       */
      VUE_APP_LAST_BUILD () {
        return process.env.VUE_APP_LAST_BUILD
      },
      ROOT_API () {
        return ROOT_API
      },
      deviceId () {
        return getDeviceId()
      },
      currentUserBrowser () {
        return currentUserBrowser
      },
      userAgent () {
        return window.navigator.userAgent
      },
      env () {
        return process.env
      }
    },
  }
</script>

<style lang="scss">
</style>
