<template>
  <label
    class="t-checkbox"
    :class="additionalClasses"
  >
    <span
      class="t-checkbox__placeholder"
      v-if="placeholder || $slots.placeholder"
    >
      <slot name="placeholder">{{placeholder}}</slot>
    </span>

    <input
      type="checkbox"
      :name="name"
      v-bind:checked="value"
      @change="onChange"
      v-bind:disabled="disabled"
      class="t-checkbox__input"
    >
    <span class="t-checkbox__input__checkmark">
      <t-icon
        name="tick"
      />
    </span>
  </label>
</template>

<script>
  import {baseFieldMixin} from "../mixins/base-field-mixin"

  export default {
    name: "t-checkbox",
    mixins: [baseFieldMixin],
    props: {
      design: {
        type: String,
        default: ``,
        validator: function (value) {
          return [
            ``,
            `green`,
          ].includes(value)
        },
      },
      orientation: {
        type: String,
        default: `left`,
        validator: function (value) {
          return [
            `left`,
            `right`,
          ].includes(value)
        },
      },
      size: {
        type: String,
        default: `normal`,
        validator: function (value) {
          return [
            `normal`,
            `small`,
          ].includes(value)
        },
      }
    },
    methods: {
      onChange ($event) {
        this.$emit('input', $event.target.checked)
        this.validateFieldWithDelay()
      }
    },
    computed: {
      additionalClasses() {
        return [
          {
            't-checkbox--is-invalid': this.hasError,
          },
          this.design && `t-checkbox--${this.design}`,
          this.value && `t-checkbox--checked`,
          `t-checkbox--${this.orientation}`,
          `t-checkbox--size-${this.size}`,
        ]
      }
    },
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-checkbox {
    font-family: $fontFamily;
    font-size: 14px;
    color: $BlackB60;
    font-weight: 500;
    text-transform: uppercase;
    height: 46px;
    cursor: pointer;

    display: flex;
    align-items: center;

    &.t-checkbox--is-invalid {
      .t-checkbox__input__checkmark {
        border-color: $Error;
      }
    }

    .t-checkbox__placeholder {
      padding-right: 24px;
    }

    .t-checkbox__input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    .t-checkbox__input__checkmark {
      flex-shrink: 0;
      display: inline-block;
      border: 2px solid $BlueB100;
      border-radius: 1px;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      background: $Gray_Checkbox_BG;

      .t-icon {
        display: none;
        width: 16px;
        height: 16px;

        .t-icon__wrapper {
          position: absolute;
          top: -6px;
          left: 2px;
        }

        svg {
          width: 8px;
          height: 8px;
        }
      }
    }

    &.t-checkbox--checked {
      .t-checkbox__input__checkmark {
        background: $BlueB100;

        .t-icon {
          display: block;
          color: $WhiteW100;
        }
      }
    }

    .t-checkbox__input:disabled ~ .t-checkbox__input__checkmark {
      background: $GrayG100;
      border-color: $GrayG100;
    }

    &.t-checkbox--right {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .t-checkbox__placeholder {
        padding-left: 14px;
        padding-right: 0;
      }
    }

    &.t-checkbox--green {
      color: $GrayG80;
      text-transform: none;
      letter-spacing: -0.4px;
      margin-bottom: 0;

      &.t-checkbox--checked {
        .t-checkbox__input__checkmark {
          background: $GreenG100;

          .t-icon {
            display: block;
            color: $WhiteW100;
          }
        }
      }

      .t-checkbox__input__checkmark {
        border: 2px solid $GreenG100;
        width: 14px;
        height: 14px;

        .t-icon {
          display: none;
          width: 14px;
          height: 14px;

          .t-icon__wrapper {
            position: absolute;
            top: -7px;
            left: 1px;
          }
        }
      }

      &.t-checkbox--checked {
        .t-checkbox__placeholder {
          color: $GreenG100;
        }
      }
    }

    &.t-checkbox--size-small {
      font-size: 12px;
      text-transform: none;
      height: unset;
      margin-bottom: 0;

      .t-checkbox__input__checkmark {
        width: 12px;
        height: 12px;

        .t-icon {
          width: 12px;
          height: 12px;

          .t-icon__wrapper {
            position: absolute;
            top: -5px;
            left: 1px;
          }

          svg {
            width: 7px;
            height: 7px;
          }
        }
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <div>
        <t-checkbox
          v-model="selVal"
          name="selVal"
        >
          <template slot="placeholder"><b>placeholder</b> placeholder</template>
        </t-checkbox>
      </div>

      <div>
        <t-checkbox
          v-model="selVal"
          name="selVal"
          disabled
        >
          <template slot="placeholder"><b>disabled</b> placeholder</template>
        </t-checkbox>
      </div>

      <div>
        <t-checkbox
          v-model="selVal"
          name="selVal"
          placeholder="placeholder"
        ></t-checkbox>
      </div>

      <div>
        selVal: {{selVal}}
      </div>
    </div>
  </template>

  <script>
    export default {
      data: () => ({
        selVal: false
      })
    }
  </script>
  ```
</docs>
