import {REST} from "vue-fast-rest";
import {repairRequests, user, voc} from "../../../api/endpoints";

export const repairRequestUpdateAsyncData = async ({ store, route }) => {
  const vocs = [
    store.dispatch(REST.actions.updateUrlEndpoint, {
      endpoint: voc.company(),
      cache: Infinity,
    }),
    store.dispatch(REST.actions.updateUrlEndpoint, {
      endpoint: voc.workType(),
      cache: Infinity,
    }),
    store.dispatch(REST.actions.updateUrlEndpoint, {
      endpoint: voc.city(),
      cache: Infinity,
      params: {
        transformResponse: [function (data) {
          return JSON.parse(data).slice(0, 50);
        }],
      }
    }),
  ]

  await Promise.all([
    ...vocs,
  ])

  if (route.params.id) {
    await store.dispatch(REST.actions.updateUrlEndpoint, {
      endpoint: repairRequests.orderView(route.params.id),
    })
  } else {
    // получение свежих данных по юзеру
    const {data: { data: selfInfo }} = await store.dispatch(REST.actions.updateUrlEndpoint, {
      endpoint: user.selfInfo(),
    })
    // проставка свежих данных
    store.commit('userSetAuthData', {
      authData: {
        user: selfInfo,
      },
      dontSaveInStorage: false,
    })
  }
}
