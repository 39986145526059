const {detect} = require('detect-browser')

/**
 * @param ctx
 * @param name
 */
export function findParentComponentByName(ctx, name) {
  let parentName = null
  let parent = ctx.$parent
  let inputName = ctx.$options.name

  while (parent) {
    parentName = parent.$options.name
    if (parentName === name) {
      break
    }
    parent = parent.$parent
  }

  if (parentName !== name) {
    throw new Error('Cant find ' + name + ' for ' + inputName)
  }

  return parent
}

export const currentUserBrowser = detect()
