<template>
  <div
    class="t-modal"
    :class="{
      't-modal--fog': fog,
      't-modal--center': center,
    }"
  >
    <div
      v-if="value || isOpened"
      class="t-modal-window"
      role="dialog"
      data-e2e="modal"
    >
      <div
        class="t-modal-window__dialog"
      >
        <div
          class="t-modal-window__dialog__content"
          role="document"
        >
          <div class="t-modal-window__dialog__content__header"></div>

          <div
            class="t-modal-window__dialog__content__body"
            data-e2e="modal-body"
          >
            <slot></slot>
          </div>
          <div
            class="t-modal-window__dialog__content__footer"
            data-e2e="modal-footer"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-modal",
    props: {
      value: {
        type: Boolean,
        default: false
      },
      isOpened: {
        type: Boolean,
        default: false
      },
      fog: {
        type: Boolean,
        default: false
      },
      center: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onDismiss() {
        this.$emit('input', false)
        this.$emit('dismiss')
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  $maxLargeModalSize: 85vh;
  $maxLargeModalSizeOffset: 191px;

  .t-modal {
    &.t-modal--fog {
      background: $BlackB20;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $modalZIndex;
    }

    &.t-modal--center {
      .t-modal-window {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .t-modal-window {
    font-family: $fontFamily;
    position: fixed;
    right: 21px;
    width: 50%;
    top: 120px;
    z-index: $modalZIndex;

    .t-modal-window__dialog {
      width: 100%;
      background: #fff;

      background: $WhiteW100;
      box-shadow: $Sh_modal;
      border-radius: 16px;

      .t-modal-window__dialog__content {

        &__body {
          overflow: auto;
          min-height: 300px;
          max-height: calc(100vh - 200px);
          position: relative;

          > .t-page-layout-article {
            box-shadow: none;
            border: none;
            border-radius: 0;
            background: none;
          }
        }

        &__footer {
          position: relative;
        }
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-button @click="modalOpened = !modalOpened">Open/Close modal</t-button>

      <t-modal
        v-model="modalOpened"
      >
        <p>Modal body</p>
      </t-modal>
    </div>
  </template>
  <script>
    export default {
      data: () => ({
        modalOpened: false
      })
    }
  </script>
  ```
</docs>
