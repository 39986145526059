<template>
  <nav
    class="top-navbar"
    data-e2e="navbar"
  >
    <div class="top-navbar__content">
      <div class="top-navbar__content__logo">
        <div class="top-navbar__content__logo__burger">
          <t-button
            icon
            icon-type="burger"
          />
        </div>

        <router-link
          :to="{name: MAIN_PAGE_NAME}"
          class="top-navbar__content__logo__image"
        >
          <img
            src="/external/logo.png"
            alt="Logo"
            class="top-navbar__content__logo__image__big"
          />
          <img
            src="/external/logo_square.png"
            alt="Logo"
            class="top-navbar__content__logo__image__small"
          />
        </router-link>
      </div>

      <div class="top-navbar__content__search">
        <t-select-suggestion
          v-if="!$store.getters.isGuest"
          v-model="globalQ"
          :form-validator="searchValidator"
          name="__"
          placeholder="Поиск по номерам документов"
          left-icon="search"
          :remote-method="findNumbers"
          :remote="true"
          :options="numbers"
          @change="numberSelectedInSearch"
          summary="Перейти ко всем результатам: "
          @summary="onSummary"
        />
      </div>

      <div
        class="top-navbar__content__menu-right"
      >
        <div
          v-if="!$store.getters.isGuest"
          class="top-navbar__content__menu-right__profile-info"
        >
          <div
            data-e2e="profile-name"
            :data-profile-id="$store.getters.userProfile.id"
            class="top-navbar__content__menu-right__profile-info__name"
          >
            {{$store.getters.userProfile.name}}
          </div>

<!--          <div-->
<!--            class="top-navbar__content__menu-right__profile-info__avatar"-->
<!--          >-->
<!--            <img src="/external/avatar.png">-->
<!--          </div>-->
        </div>

<!--        <t-button-->
<!--          v-if="!$store.getters.isGuest"-->
<!--          icon-->
<!--          icon-type="settings"-->
<!--        />-->

        <t-button
          v-if="!$store.getters.isGuest && canLogout"
          data-e2e="navbar-link-logout"
          @click="logoutAndGoToLoginPage()"
          icon
          icon-type="exit"
          class="top-navbar__content__menu-right__logout"
        >Выйти</t-button>

      </div>
    </div>
  </nav>
</template>

<script>
  import {ADMIN_LOGGED_AS_USER_USER_DATA, MAIN_PAGE_NAME} from "../../configs/config"
  import {loginMixin} from '../../mixins/login-mixin'
  import {FormValidator} from "../../components";
  import {REST} from "vue-fast-rest";
  import {repairRequests, workOrders} from "../../api/endpoints";

  export default {
    name: "navbar",
    mixins: [loginMixin],
    data: () => ({
      globalQ: '',
      numbers: [],
    }),
    created() {
      this.searchValidator = new FormValidator()

      this.globalQ = this.$route.query.globalQ || ``

      this.fillNumbers(
        this.requests,
        this.orders,
      )
    },
    methods: {
      async findNumbers(term = ``) {
        if (term && term.length > 0) {
          const [requests, orders] = await Promise.all([
            this.$store.dispatch(REST.actions.updateUrlEndpoint, {
              endpoint: repairRequests.ordersByNumber(term),
            }),
            await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
              endpoint: workOrders.ordersByNumber(term),
            })
          ])

          this.fillNumbers(
            requests.data,
            orders.data,
          )

        } else {
          this.numbers = []
        }
      },
      fillNumbers(requests, orders) {
        this.numbers = [
          ...requests.data.items.map(v => ({
            id: `requests.requestView_${v.id}`,
            name: v.number || `n/a`,
          })),
          ...orders.data.items.map(v => ({
            id: `orders.orderView_${v.id}`,
            name: v.number || `n/a`,
          }))
        ]
      },
      numberSelectedInSearch({ value }) {
        const [name, id] = `${value}`.split(`_`)

        this.$router.push({
          name,
          params: { id },
        }, () => ``, () => ``)
      },
      onSummary(globalQ = ``) {
        this.globalQ = globalQ

        this.$router.push({
          name: 'search.searchResults',
          query: {
            globalQ: globalQ,
          }
        }, () => ``, () => ``)
      },
    },
    computed: {
      /**
       * @return {string}
       */
      MAIN_PAGE_NAME() {
        return MAIN_PAGE_NAME
      },
      canLogout() {
        if (ADMIN_LOGGED_AS_USER_USER_DATA) {
          return false
        }
        return true
      },
      requests() {
        return this.$store.getters[REST.getters.readUrlEndpoint](`requests`) || {
          data: { items: [] }
        }
      },
      orders() {
        return this.$store.getters[REST.getters.readUrlEndpoint](`orders`) || {
          data: { items: [] }
        }
      },
    },
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .top-navbar {
    height: $topNavHeight;
    position: relative;

    font-family: $fontFamily;
    font-size: 16px;
    color: $BlackB80;
    z-index: $fixedNavZIndex;

    .top-navbar__content {
      background: $WhiteW100;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: $topNavHeight;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-navbar__content__logo {
        min-width: 100px;
        padding: 0 18px;
        width: 33.333%;
        overflow: hidden;

        display: flex;
        align-items: center;

        .top-navbar__content__logo__burger {
          .t-button {
            color: $GrayG100;
            background: none;
          }
        }

        .top-navbar__content__logo__image {
          padding: 0 8px;

          img {
            height: 21px;
            width: auto;
          }

          .top-navbar__content__logo__image__small {
            display: none;
          }
        }
      }

      .top-navbar__content__search {
        width: 400px;

        .t-select-suggestion-wrapper {
          .t-icon {
            color: $GrayG100;
          }

          .t-select-suggestion {
            &.el-select {
              height: 56px;
              font-size: 14px;

              .el-input {
                .el-input__inner {
                  height: 100%;
                  border-radius: 12px;
                  padding-left: 49px;
                  background: $GrayG05;

                  &::placeholder {
                    color: $GrayG100;
                  }
                }
              }
            }
          }
        }
      }

      .top-navbar__content__menu-right {
        width: 33.333%;
        padding: 0 18px;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        .t-button {
          margin-right: 10px;
          font-size: 14px;
          font-weight: bold;
          background: none;
          color: $BlackB80;

          .t-button-text {
            padding: 0 10px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .top-navbar__content__menu-right__profile-info {
          overflow: hidden;

          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 700;

          .top-navbar__content__menu-right__profile-info__name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 24px;
          }

          .top-navbar__content__menu-right__profile-info__avatar {
            min-width: 40px;
            margin: 0 16px 0 0;

            img {
              height: 40px;
              width: auto;
              border: none;
              border-radius: 20px;
            }
          }
        }

        .top-navbar__content__menu-right__logout {
          color: $RedR100;
          margin-top: -3px;
        }
      }
    }
  }

  @media (max-width: $mdDeviceWidth) {
    .top-navbar {
      .top-navbar__content {
        .top-navbar__content__logo {
          width: 50px;

          .top-navbar__content__logo__image {
            .top-navbar__content__logo__image__big {
              display: none;
            }
            .top-navbar__content__logo__image__small {
              display: block;
            }
          }
        }

        .top-navbar__content__search {
          width: 250px;
        }

        .top-navbar__content__menu-right {
          width: auto;
        }
      }
    }
  }
</style>
