import {ADMIN_LOGGED_AS_USER_USER_DATA, DEFAULT_REDIRECT, USER_DATA, USER_SERVERLESS_DATA} from '../configs/config'

const savedData = {
  user: null,
  userServerlessData: {},
}

if (typeof window !== "undefined") {
  if (ADMIN_LOGGED_AS_USER_USER_DATA) {
    savedData.user = ADMIN_LOGGED_AS_USER_USER_DATA
  } else {
    savedData.user = window.localStorage.getItem(USER_DATA) ? JSON.parse(window.localStorage.getItem(USER_DATA)) : null

    const serverlessData = window.localStorage.getItem(USER_SERVERLESS_DATA) ? JSON.parse(window.localStorage.getItem(USER_SERVERLESS_DATA)) : null
    if (serverlessData) {
      savedData.userServerlessData = Object.assign(savedData.userServerlessData, serverlessData)
    }
  }
}

export default {
  _userData: savedData.user,
  _userServerlessData: savedData.userServerlessData,
  _error: null,
  _redirectUrl: DEFAULT_REDIRECT.mainPage,
  _authStatus: null,
  unreadCount: 0,
}
