<template>
  <t-popup-block
    :class="`t-alert t-alert--${type}`"
  >
    <div
      v-if="type==='loading'"
      class="t-alert__icon"
    >
      <t-icon name="bigLoadingRing"/>
    </div>

    <div
      v-if="type==='error'"
      class="t-alert__icon"
    >
      <t-icon name="bigError"/>
    </div>

    <div
      v-if="type==='success'"
      class="t-alert__icon"
    >
      <t-icon name="bigDone"/>
    </div>

    <template
      v-if="title"
      slot="title"
    >
      {{title}}
    </template>
    <template
      v-if="message"
      slot="message"
    >
      {{message}}
    </template>

    <template
      v-if="type==='success'"
      slot="actions"
    >
      <t-button
        @click="onOk"
        type="submit"
        design="primary"
      >
        Ok
      </t-button>
    </template>

  </t-popup-block>
</template>

<script>
  import TPopupBlock from "../block/popup-block";

  export default {
    name: "t-alert",
    components: {TPopupBlock},
    props: {
      title: {
        type: String,
        default: ``
      },
      message: {
        type: String,
        default: ``
      },
      type: {
        type: String,
        default: `default`,
      },
      duration: {
        type: Number,
        default: 0,
      },
      onSuccessCb: {
        type: Function,
      }
    },
    mounted() {
      if (this.duration) {
        setTimeout(() => this.kill(), this.duration)
      }
    },
    methods: {
      async onOk() {
        if (this.onSuccessCb) {
          await this.onSuccessCb()
        }

        this.kill()
      },
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-alert {
    &.t-popup-block {
      .t-popup-block__box__container {
        min-width: 400px;
        max-width: 400px;
      }

      .t-popup-block__box__container__title {
        margin-top: 16px;
      }
    }

    &.t-alert--loading {
      &.t-popup-block {
        .t-popup-block__box__container {
          min-width: 400px;
          max-width: 400px;
          min-height: 225px;

          .t-alert__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &.t-alert--error {
      &.t-popup-block {
        .t-popup-block__box__container__message {
          margin-bottom: 0;
        }
      }
    }

    &.t-alert--success {
      &.t-popup-block {
        .t-popup-block__box__container__actions {
          .t-button {
            width: 100%;
            margin: 0 16px;
          }
        }
      }
    }

    .t-alert__icon {
      height: 80px;
      text-align: center;

      .t-icon svg {
        width: 80px;
        height: 80px;
      }

      .t-icon--bigError {
        color: $RedR100;
      }

      .t-icon--bigLoadingRing {
        color: $BlueB100;
      }

      .t-icon--bigDone {
        color: $BlueB100;
      }

      .t-icon--close {
        color: $RedR100;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <p>
      <t-button @click="alertLoading">
        alertLoading
      </t-button>

      <t-button @click="openErr">
        JS Error
      </t-button>

      <t-button @click="success">
        success
      </t-button>
    </p>
  </template>

  <script>
    export default {
      methods: {
        async alertLoading() {
          const alert = this.$alert.loading()

          await new Promise(resolve => setTimeout(resolve, 5000))

          alert.kill()
        },
        openErr() {
          try {
            throw new Error('js error message')
          } catch (e) {
            this.$alert.jsError(e);
          }
        },
        success() {
          this.$alert.success({
            title: `Данные успешно отправлены`,
          })
        }
      }
    }
  </script>
  ```
</docs>
