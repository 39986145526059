import Vue from 'vue'
import Router from 'vue-router'

import {
  DEFAULT_REDIRECT,
  DEVELOPER_MENU_ROUTE_PREFIX,
  ERROR_PAGE_NAME,
  LOGIN_PAGE_NAME,
} from "../configs/config"

import MainLayout from '../common/layouts/main-layout'
import MainLayoutClean from '../common/layouts/main-layout-clean'

import UserLogin from '../app/user/Login'
import UserRestoreRequest from '../app/user/RestoreRequest'
import UserChangeLostPassword from '../app/user/ChangeLostPassword'

import RequestsRequestsJournal from '../app/requests/RequestsJournal'
import RequestsRequestUpdate from '../app/requests/RepairRequestUpdate'
import RepairRequestView from '../app/requests/RepairRequestView'

import NotificationsNotificationsList from '../app/notifications/NotificationsList'

import OrdersOrdersJournal from "../app/orders/OrdersJournal";
import OrdersOrderView from "../app/orders/OrderView";

import ReportsReportsJournal from "../app/reports/ReportsJournal";

import ActsActsJournal from "../app/acts/ActsJournal";

import DevelopmentSettingUpdate from '../app/development/SettingUpdate'
import DevelopmentDiagnostic from '../app/development/Diagnostic'

import SearchSearchResults from "../app/search/SearchResults";

import SupportInfo from "../app/support/Info";

import MainError from '../app/main/Error'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/', redirect: {...DEFAULT_REDIRECT.mainPage},
    },
    {
      path: '/user',
      name: 'user',
      component: MainLayoutClean ,
      redirect: { name: LOGIN_PAGE_NAME },
      children: [
        { path: 'login', name: LOGIN_PAGE_NAME, component: UserLogin, meta: {requiresAuth: false, title: 'Вход'}},
        { path: 'restore-request', name: 'user.restoreRequest', component: UserRestoreRequest, meta: {requiresAuth: false, title: 'Восстановление пароля'}},
        { path: 'change-lost-password', name: 'user.changeLostPassword', component: UserChangeLostPassword, meta: {requiresAuth: false, title: 'Восстановление пароля'}},
      ]
    },
    {
      path: '/requests',
      name: 'requests',
      component: MainLayout,
      redirect: { name: 'requests.requestsJournal' },
      children: [
        { path: 'journal', name: 'requests.requestsJournal', component: RequestsRequestsJournal, meta: {title: 'Журнал заявок'},
          children: [
            { path: 'view/:id', name: 'requests.requestView', component: RepairRequestView , meta: {title: 'Просмотр Заявки на ремонт'}},
          ]
        },
        { path: 'create', name: 'requests.requestCreate', component: RequestsRequestUpdate , meta: {title: 'Создание Заявки на ремонт'}},
      ]
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: MainLayout,
      redirect: { name: 'notifications.notificationsList' },
      children: [
        { path: 'list', name: 'notifications.notificationsList', component: NotificationsNotificationsList, meta: {title: 'Уведомления'}},
      ]
    },
    {
      path: '/orders',
      name: 'orders',
      component: MainLayout,
      redirect: { name: 'orders.ordersJournal' },
      children: [
        { path: 'journal', name: 'orders.ordersJournal', component: OrdersOrdersJournal, meta: {title: 'Журнал заказ нарядов'}},
        { path: 'journal/view/:id', name: 'orders.orderView', component: OrdersOrderView, meta: {title: 'Просмотр Заказ наряда'}},
      ]
    },
    {
      path: '/reports',
      name: 'reports',
      component: MainLayout,
      redirect: { name: 'reports.reportsJournal' },
      children: [
        { path: 'journal', name: 'reports.reportsJournal', component: ReportsReportsJournal, meta: {title: 'Отчеты'}},
      ]
    },
    {
      path: '/acts',
      name: 'acts',
      component: MainLayout,
      redirect: { name: 'acts.actsJournal' },
      children: [
        { path: 'acts', name: 'acts.actsJournal', component: ActsActsJournal, meta: {title: 'Акты выполненных работ'}},
      ]
    },
    {
      path: '/search',
      name: 'search',
      component: MainLayout,
      redirect: { name: 'search.searchResults' },
      children: [
        { path: 'results', name: 'search.searchResults', component: SearchSearchResults, meta: {title: 'Результаты поиска'}},
      ]
    },
    {
      path: '/support',
      name: 'support',
      component: MainLayout,
      redirect: { name: 'support.info' },
      children: [
        { path: 'info', name: 'support.info', component: SupportInfo, meta: {title: 'Поддержка'}},
      ]
    },
    {
      path: '/development',
      name: DEVELOPER_MENU_ROUTE_PREFIX,
      component: MainLayout,
      redirect: { name: `${DEVELOPER_MENU_ROUTE_PREFIX}.diagnostic` },
      children: [
        { path: 'setting-update', name: `${DEVELOPER_MENU_ROUTE_PREFIX}.settingUpdate`, component: DevelopmentSettingUpdate, meta: {title: 'Dev settings', requiresAuth: false}},
        { path: 'diagnostic', name: `${DEVELOPER_MENU_ROUTE_PREFIX}.diagnostic`, component: DevelopmentDiagnostic, meta: {title: 'Диагностика', requiresAuth: false}},
      ]
    },
    {
      path: '*', name: ERROR_PAGE_NAME, component: MainError, meta: {requiresAuth: false}, props: true,
    }
  ]
})

/**
 * Расширяем стандартный роутер, добавляя функционал по пропуску
 * вызова asyncData в хуках
 * @param route
 */
router.skipAsyncReplace = function (route) {
  this.skipAsync = true
  this.replace(route, () => this.skipAsync = false, () => this.skipAsync = false)
}

router.skipAsyncPush = function (route) {
  this.skipAsync = true
  this.push(route, () => this.skipAsync = false, () => this.skipAsync = false)
}

export default router
