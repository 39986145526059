<template>
  <div class="t-pagination-simple">
    <small
      class="t-pagination-simple__info"
      v-if="showInfo"
    >
      {{rangeFrom}}-{{rangeTo}} из {{meta.total_count}}
    </small>
    <div class="t-pagination-simple__buttons">
      <t-button
        icon
        icon-type="left"
        transparent
        @click="go(-1)"
      ></t-button>
      <div
        v-if="showPages"
        class="t-pagination-simple__buttons__pages"
      >
        <t-button
          v-for="page of pages"
          :key="page.page"
          :active="+page.page === +meta.page"
          @click="goToPageNumber(page.page)"
        >
          {{page.title}}
        </t-button>
      </div>
      <t-button
        icon
        icon-type="right"
        transparent
        @click="go(1)"
      ></t-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-pagination-simple",
    props: {
      meta: {
        type: Object,
        required: true,
        validator: (meta = {}) => {
          const isValid = meta.hasOwnProperty('page_count') && meta.hasOwnProperty('page') && meta.hasOwnProperty('page_size') && meta.hasOwnProperty('total_count')
          if (isValid === false) {
            console.warn('Need meta with: {page_count: 0, page: 0 ...}')
          }
          return isValid
        }
      },
      autoNavigation: {
        type: Boolean,
        default: true
      },
      showPages: {
        type: Boolean,
        default: false,
      },
      showInfo: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      rangeFrom() {
        if (this.meta.total_count === 0) {
          return 0
        }

        return (this.meta.page) * this.meta.page_size + 1
      },
      rangeTo() {
        let to = (this.rangeFrom - 1) + this.meta.count

        if (to > this.meta.total_count || to < 0) {
          return this.meta.count
        }

        return to
      },
      pages() {
        const pages = []

        const max = 3
        let start = Math.round(+this.meta.page - (max / 2))
        let end = +this.meta.page + max

        if (end > this.meta.page_count) {
          start = start - (end - this.meta.page_count) + 1
        }

        for (let i = start; i < end; i++) {
          if (i < 0) {
            continue
          }

          if (pages.length >= max) {
            break
          }

          if (i >= this.meta.page_count) {
            break
          }

          pages.push({
            page: i,
            title: `${i + 1}`,
          })
        }

        return pages
      }
    },
    methods: {
      go(pageDecrement) {
        const next = +this.meta.page + pageDecrement

        if (pageDecrement < 0 && next <= -1) {
          return
        }

        if (pageDecrement > 0 && next > this.meta.page_count - 1) {
          return
        }

        this.$emit('goToPage', {page: next})

        if (this.autoNavigation) {
          this.$router.push({
            query: {
              ...this.$route.query,
              page: next,
            }
          }).catch(() => '')
        }
      },
      goToPageNumber(page = 0) {
        this.$emit('goToPage', {page})

        if (this.autoNavigation) {
          this.$router.push({
            query: {
              ...this.$route.query,
              page,
            }
          }).catch(() => '')
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-pagination-simple {
    font-family: $fontFamily;
    color: $GrayG100;
    display: flex;

    .t-pagination-simple__info {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: -0.4px;
      min-width: 60px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .t-pagination-simple__buttons {
      display: flex;

      .t-button {
        color: inherit;
      }

      .t-pagination-simple__buttons__pages {
        display: flex;
        align-items: center;
        margin-left: -8px;
        margin-right: -8px;
        margin-top: -2px;

        .t-button {
          height: 16px;
          min-width: 16px;
          border: 1px solid $GrayG80;
          color: $BlackB40;
          border-radius: 2px;
          margin: 4px;
          background: none;
          padding: 0;

          .t-button-text {
            font-weight: 500;
            font-size: 12px;
            padding: 0;
          }

          &.t-button--active {
            border: 1px solid $BlueB100;
            color: $BlueB100;
          }
        }
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-pagination-simple
        :meta="{
          page: this.page, // страница
          page_count: 25, // число страниц
          page_size: 25, // размер страницы
          total_count: 625, // всего записей
          count: 25, // число элементов на тек странице
        }"
        @goToPage="goToPage"
        :auto-navigation="false"
      ></t-pagination-simple>

      <p>{{evt}}</p>


      <t-pagination-simple
        :meta="{
          page: this.page, // страница
          page_count: 25, // число страниц
          page_size: 25, // размер страницы
          total_count: 625, // всего записей
          count: 25, // число элементов на тек странице
        }"
        @goToPage="goToPage"
        :auto-navigation="false"
        show-pages
      ></t-pagination-simple>

      <t-pagination-simple
        :meta="{
          page: 5, // страница
          page_count: 25, // число страниц
          page_size: 25, // размер страницы
          total_count: 625, // всего записей
          count: 25, // число элементов на тек странице
        }"
        @goToPage="goToPage"
        :auto-navigation="false"
        show-pages
      ></t-pagination-simple>

      <t-pagination-simple
        :meta="{
          page: 24, // страница
          page_count: 25, // число страниц
          page_size: 25, // размер страницы
          total_count: 625, // всего записей
          count: 25, // число элементов на тек странице
        }"
        @goToPage="goToPage"
        :auto-navigation="false"
        show-pages
      ></t-pagination-simple>
    </div>
  </template>
  <script>
    export default {
      data: () => ({
        evt: {},
        page: 0
      }),
      methods: {
        goToPage(evt) {
          this.evt = evt
          this.page = evt.page
        }
      }
    }
  </script>
  ```
</docs>
