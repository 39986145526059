export class FormValidationResult {
  _errors = {}

  addError(name, error) {
    this._errors[name] = error
  }

  getErrors() {
    return { ...this._errors }
  }

  hasErrors() {
    return Object.keys(this._errors).length > 0
  }
}
