<template>
  <t-page-layout-article
    v-if="error"
  >
    <t-title type="h2">{{title}}</t-title>
    <template
      v-if="isAuthorizationError"
    >
      <div>
        Ваша сессия истекла, нужно авторизоваться заново, <a href="#" @click.stop.prevent="$emit('goToLogin')">перейти
        на страницу входа</a>
      </div>
    </template>
    <template v-else>
      <div
        v-if="error.message"
        v-html="error.message"
      ></div>
      <div
        v-if="error.additional"
      >
        Техническая информация: {{error.additional}}
      </div>
    </template>
    <t-error-canvas-status
      v-if="error.status"
      :status="error.status"
    />
  </t-page-layout-article>
</template>

<script>
  export default {
    name: 't-error',
    props: {
      error: null
    },
    computed: {
      isAuthorizationError() {
        return this.error && this.error.status === 401
      },
      title() {
        if (this.error) {
          return `${this.error.status}: ${this.error.name}`
        }
        return 'Упс...'
      }
    },
    created() {
      this.setTitle()
    },
    mounted() {
      this.$bar && this.$bar.finish()
    },
    watch: {
      'error': {
        handler: 'onError',
        deep: true
      }
    },
    methods: {
      onError() {
        this.setTitle()
      },
      setTitle() {
        if (this.error) {
          document.title = `${this.title}`
        }
      },
    }
  }
</script>

<docs>
  ```vue
  <template>
    <div>
      <t-button @click="showError">Show error</t-button>
      <t-button @click="showAuthError">Auth error</t-button>

      <t-error
        :error="error"
      ></t-error>
    </div>
  </template>
  <script>
    export default {
      data: () => ({
        error: null
      }),
      methods: {
        showError() {
          this.error = {
            status: 500,
            name: 'title',
            message: 'message',
            additional: 'http://site.com/post/34'
          }
        },
        showAuthError() {
          this.error = {
            status: 401,
            name: 'title',
            message: 'message',
            additional: 'Foo bat baz'
          }
        }
      }
    }
  </script>
  ```
</docs>
