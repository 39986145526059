<template>
  <div class="t-counter">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "t-counter"
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-counter {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $RedR100;
    color: $WhiteW100;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 16px;
    text-align: center;
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-counter>1</t-counter>

      <t-counter>123</t-counter>
    </div>
  </template>
  ```
</docs>

