import Vue from 'vue'

import Notification from './notification'

function createNotify(type, props) {
  const notify = new Vue({
    ...Notification,
    propsData: {
      ...props,
    },
  }).$mount()

  notify.kill = () => {
    notify.$destroy()
    if (notify.$el && notify.$el.parentNode) {
      notify.$el.parentNode.removeChild(notify.$el)
    }
  }

  const position = props.position || 'right'
  const blockId = `t-notification-list--${position}`

  let block = document.getElementById(blockId)
  if (!block) {
    block = document.createElement('div')
    block.id = blockId
    document.body.appendChild(block)
  }

  block.insertBefore(notify.$el, block.firstChild)

  return notify
}

export default function ({ recognizeError }) {
  return {
    jsError: (error) => {
      const recognizedError = recognizeError(error)

      return createNotify(`jsError`, {
        title: recognizedError.name,
        message: recognizedError.message,
        duration: 25000,
      })
    },
    document: (options) => createNotify(`document`, options),
  }
}
