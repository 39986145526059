import locale from 'element-ui/lib/locale'
import elementLangRU from 'element-ui/lib/locale/lang/ru-RU'
import 'moment/locale/ru'

// import DatePicker from 'element-ui/packages/date-picker'
import {
  DatePicker as ElDatePicker,
  Select as ElSelect,
  Option as ElOption,
  Dropdown as ElDropdown,
  DropdownItem as ElDropdownItem,
  DropdownMenu as ElDropdownMenu,
  Tooltip as ElTooltip
} from 'element-ui'

import Bar from './bar/bar'
import Notification from './notification/index'
import Confirmation from './confirmation/index'
import Alert from './alert/index'

import Error from './error/error.vue'
import ErrorSmall from './error/error-small.vue'
import ErrorCanvasStatus from './error/error-canvas-status.vue'

import Form from './form/form'
import FormGroup from './form/form-group'
import FormActions from './form/form-actions'

import Input from './input/input'

import Checkbox from './checkbox/checkbox'

import Select from './select/select'
import SelectSuggestion from './select/select-suggestion'

import Button from './button/button'

import Modal from './modal/modal'

import DivTable from './table/div-table'

import Document from './document/document'
import DocumentList from './document/document-list'

import PaginationSimple from './pagination/pagination-simple'

import Icon from './icon/icon'

import Calendar from './calendar/calendar'

import PageLayoutArticle from './page-layout/page-layout-article'

import RoundBlock from './block/round-block'
import FilterBlock from './block/filter-block'
import StickyBlock from './block/sticky-block'

import Title from './typography/title'
import Heading from './typography/heading'

import FileUploader from './uploader/file-uploader'
import FileUploaded from './uploader/file-uploaded'

import TagInput from './tag-input/tag-input'
import Tag from './tag/tag'

import ColorProgress from './progress/color-progress'

import Counter from './counter/counter'

import Tabs from './tabs/tabs'

import ListNumeric from './list/list-numeric'

import Tooltip from "./tooltip/tooltip";

export {FormValidator} from './utils/validator/FormValidator'

export function createBar(Vue) {
  const bar = Vue.prototype.$bar = new Vue(Bar).$mount()
  document.body.appendChild(bar.$el)
  return bar
}

export default function (Vue, {
  recognizeError = (e) => e
} = {}) {
  // set lang for element ui
  locale.use(elementLangRU)

  // init global services
  Vue.prototype.$tServices = {
    recognizeError
  }

  // tetron components

  Vue.component(Error.name, Error)
  Vue.component(ErrorSmall.name, ErrorSmall)

  // единственная причина вгружать этот компонент таким образом, чтобы не настраивать node canvas для Mocha
  Vue.component(ErrorCanvasStatus.name, ErrorCanvasStatus)

  Vue.component(Form.name, Form)
  Vue.component(FormGroup.name, FormGroup)
  Vue.component(FormActions.name, FormActions)

  Vue.component(Checkbox.name, Checkbox)

  Vue.component(Input.name, Input)

  Vue.use(ElOption)
  Vue.use(ElSelect)
  Vue.component(Select.name, Select)
  Vue.component(SelectSuggestion.name, SelectSuggestion)

  Vue.component(Modal.name, Modal)

  Vue.component(Button.name, Button)

  Vue.component(DivTable.name, DivTable)

  Vue.component(Document.name, Document)
  Vue.component(DocumentList.name, DocumentList)

  Vue.component(PaginationSimple.name, PaginationSimple)

  Vue.component(Icon.name, Icon)

  Vue.use(ElDatePicker)
  Vue.component(Calendar.name, Calendar)

  Vue.component(PageLayoutArticle.name, PageLayoutArticle)

  Vue.component(ElDropdown.name, ElDropdown)
  Vue.component(ElDropdownItem.name, ElDropdownItem)
  Vue.component(ElDropdownMenu.name, ElDropdownMenu)

  Vue.component(RoundBlock.name, RoundBlock)
  Vue.component(FilterBlock.name, FilterBlock)
  Vue.component(StickyBlock.name, StickyBlock)

  Vue.component(Title.name, Title)
  Vue.component(Heading.name, Heading)

  Vue.component(FileUploader.name, FileUploader)
  Vue.component(FileUploaded.name, FileUploaded)

  Vue.component(TagInput.name, TagInput)
  Vue.component(Tag.name, Tag)

  Vue.component(ColorProgress.name, ColorProgress)

  Vue.component(Counter.name, Counter)

  Vue.component(Tabs.name, Tabs)

  Vue.component(ListNumeric.name, ListNumeric)

  Vue.component(ElTooltip.name, ElTooltip)

  Vue.component(Tooltip.name, Tooltip)

  // attach to vue
  Vue.prototype.$notification = Notification({ recognizeError })
  Vue.prototype.$confirmation = Confirmation();
  Vue.prototype.$alert = Alert({ recognizeError });
}
