<template>
  <div class="user login">

    <login-block
      welcome="Добро пожаловать"
      welcome-small="Мы рады Вас видеть"
    >
      <t-form
        @submit="onSubmit"
        :formValidator="formValidator"
        name="login"
      >
        <t-form-group
          placeholder="Логин"
          name="email"
          required
          v-model="model.email"
          behavior="material"
          hide-req-dot
        ></t-form-group>
        <t-form-group
          placeholder="Пароль"
          name="password"
          subType="password"
          v-model="model.password"
          required
          behavior="material"
          hide-req-dot
        ></t-form-group>

        <div class="login__actions">
<!--          <t-form-group-->
<!--            placeholder="Запомнить меня"-->
<!--            name="rememberMe"-->
<!--            v-model="model.rememberMe"-->
<!--            type="checkbox"-->
<!--          ></t-form-group>-->
<!--          <div class="login__actions__restore">-->
<!--            <router-link-->
<!--              :to="{name: 'user.restoreRequest'}"-->
<!--            >-->
<!--              Восстановление пароля-->
<!--            </router-link>-->
<!--          </div>-->
        </div>

        <t-button
          :loading="loading"
          type="submit"
          :disabled="loginDisabled"
          design="primary"
        >
          Войти
        </t-button>
      </t-form>
    </login-block>

  </div>
</template>

<script>
  import {FormValidator} from "../../components"
  import {user} from "../../api/endpoints"
  import {REST} from "vue-fast-rest"
  import {loginMixin} from '../../mixins/login-mixin'
  import {ADMIN_LOGGED_AS_USER_USER_DATA} from "../../configs/config"
  import LoginBlock from "./components/login-block";
  import {decodeJWTtoObject} from "@/utils/string";

  export default {
    name: "Login",
    components: {LoginBlock},
    mixins: [loginMixin],
    data: () => ({
      loading: false,
      model: {
        email: '',
        password: '',
        rememberMe: false,
      }
    }),
    created() {
      this.formValidator = new FormValidator()
    },
    computed: {
      loginDisabled() {
        return !!(ADMIN_LOGGED_AS_USER_USER_DATA)
      }
    },
    methods: {
      async onSubmit() {
        if (this.formValidator.validate().hasErrors() || this.loading) {
          return
        }

        try {
          this.loading = true

          // очистка всех предыдущих кэшей перед авторизацией
          this.$store.commit(`cleanAllCaches`)

          // авторизация
          const {data} = await this.$store.dispatch(REST.actions.createModel, {
             url: user.login(),
             data: this.model
          })
          // сохранение смещения времени
          await this.$store.dispatch(REST.actions.updateModel, {
            url: user.updateSelfInfo(),
            data: {
              data: {
                gmtOffset: Math.round((new Date().getTimezoneOffset() * -1) / 60),
              },
            },
            params: {
              headers: {'Authorization': `Bearer ${data.token}`},
            }
          })
          // получение данных по профилю
          const {data: { data: selfInfo }} = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            url: user.selfInfo(),
            params: {
              headers: {'Authorization': `Bearer ${data.token}`},
            }
          })
          // обновление счетчика непрочитанных
          await this.$store.dispatch(`updateUnread`, {
            token: data.token,
          })

          const jwtUser = decodeJWTtoObject(data.token)

          this.loginAndGoToPrevOrMainPage({
            user: selfInfo,
            expire_at: jwtUser.exp,
            token: data.token,
            refresh_token: data.refresh_token,
          })
        } catch (e) {
          this.loading = false

          if (e.response && e.response.status && (+e.response.status === 422 || +e.response.status === 401 || +e.response.status === 400)) {
            this.formValidator.markFieldAsInvalid('email', 'Неверный логин и/или пароль')
          } else {
            this.$notification.jsError(e)
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .login {
    .login-block {
      .login__actions {
        display: flex;
        justify-content: space-between;
        // margin: 36px 0 30px;
        margin: 36px 0 0;

        .t-form-group {
          margin: 0 0 0 6px;
        }

        .t-checkbox {
          position: relative;
          text-transform: none;
          color: $BlackB100;
          height: auto;
          font-size: 12px;

          .t-checkbox__placeholder {
            padding-left: 24px;
            padding-right: 0;
          }

          .t-checkbox__input__checkmark {
            position: absolute;
            left: 0;
            border-radius: 4px;
            width: 14px;
            height: 14px;
            border-color: $BlueB100;

            .t-icon__wrapper {
              position: absolute;
              left: 2px;
              top: -4px;
              color: $BlueB100;;
            }
          }
        }

        .login__actions__restore {
          display: flex;

          a {
            color: $BlueB100;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
