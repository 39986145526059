<template>
  <div
    class="main-layout main-layout--clean"
    data-e2e="main-layout-clean"
  >

    <admin-logged-as-user-warning></admin-logged-as-user-warning>

    <div class="main-layout__wrapper">
      <main>
        <section>
          <slot></slot>
          <router-view></router-view>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
  import AdminLoggedAsUserWarning from "../warnings/admin-logged-as-user-warning"

  export default {
    name: 'main-layout-clean',
    components: {AdminLoggedAsUserWarning}
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .main-layout {
    &.main-layout--clean {
      height: 100%;

      .main-layout__wrapper {
        height: 100%;

        > main {
          height: 100%;

          > section {
            height: 100%;
            position: relative;
            background: $LoginBG;
            font-family: $fontFamily;
          }
        }
      }
    }
  }
</style>
