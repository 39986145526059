<template>
  <div class="repair-request-view">
    <t-modal is-opened>
      <RepairRequestUpdate
        open-for-view-in-modal
      />
    </t-modal>
  </div>
</template>

<script>
  import RepairRequestUpdate from "./RepairRequestUpdate";
  import {repairRequestUpdateAsyncData} from "./async-methods";

  export default {
    name: "RepairRequestView",
    components: {RepairRequestUpdate},
    asyncData (options) {
      return repairRequestUpdateAsyncData(options)
    },
  }
</script>

<style lang="scss">
  .repair-request-view {
    .repair-request {
      padding: 30px;
    }
  }
</style>
