<template>
  <t-round-block
    class="t-filter-block"
  >
    <div class="t-filter-block__right-actions">
      <slot name="right-actions"></slot>
    </div>
    <div class="t-filter-block__form">
      <slot></slot>
    </div>
    <div class="t-filter-block__actions">
      <slot name="actions"></slot>
    </div>
  </t-round-block>
</template>

<script>
  export default {
    name: "t-filter-block"
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-filter-block {
    color: $GrayG100;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .t-filter-block__right-actions {
      display: flex;
      align-items: center;
    }

    .t-filter-block__actions {
      display: flex;
      align-items: center;

      .t-button {
        color: $GrayG100;
      }
    }

    .t-form {
      display: flex;
      $filtersHeight: 40px;

      .t-form-group {
        margin: 2px 9px;
        height: $filtersHeight;

        .t-select, .t-select-suggestion, .t-calendar {
          height: $filtersHeight;

          .el-input__inner {
            background: $GrayG05;
          }

          input {
            height: $filtersHeight;
            font-weight: bold;
          }
        }

        .t-input {
          height: $filtersHeight;
          font-weight: bold;
          background: $GrayG05;
        }
      }
    }

    .t-icon--resetFilter {
      color: $Error;
    }
  }

  @media (max-width: 1320px) {
    .t-filter-block {
      .t-form {
        flex-wrap: wrap;

        .t-form-group {
          width: 28%;
          margin-bottom: 14px;
        }
      }
    }
  }

  @media (max-width: $mdDeviceWidth) {
    .t-filter-block {
      display: block;

      .t-form {
        flex-wrap: wrap;

        .t-form-group {
          width: 100%;
          margin-bottom: 14px;
        }
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div style="background: #E0E3E9; padding: 10px">
      <t-filter-block>
        <t-form
          name="requestsFilter"
          :formValidator="formValidator"
        >
          <t-form-group
            placeholder="Сортировка"
            name="sort"
            type="select"
            :options="[]"
          ></t-form-group>

          <t-form-group
            placeholder="Заявки"
            name="req"
            type="select"
            :options="[]"
          ></t-form-group>
        </t-form>

        <template slot="actions">
          <t-button
            iconType="refresh"
            icon
            transparent
          ></t-button>
        </template>
      </t-filter-block>
    </div>
  </template>

  <script>
    import {FormValidator} from "../../components"

    export default {
      created () {
        // create validator instance
        this.formValidator = new FormValidator()
      },
    }
  </script>
  ```
</docs>
