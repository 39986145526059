<template>
  <div class="t-sticky-block">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: "t-sticky-block"
  }
</script>

<style lang="scss">
  .t-sticky-block {
    position: sticky;
    top: 0;
    z-index: 1;
  }
</style>
