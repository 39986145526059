<template>
  <div class="t-heading">
    <t-title type="h2">
      <slot></slot>
    </t-title>
  </div>
</template>

<script>
  export default {
    name: "t-heading"
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-heading {
    font-family: $fontFamily;
    border-bottom: 1px solid $Gray_Checkbox_BG;
    position: relative;
  }

  .t-round-block {
    .t-heading {
      margin-left: -16px;
      margin-right: -16px;
      margin-top: -7px;
      padding: 0 24px 3px;
    }
  }
</style>
