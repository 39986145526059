<template>
  <div class="t-div-table">
    <t-round-block
      v-if="items.length === 0"
      class="t-div-table__not-found"
    >
      Ничего не найдено...
    </t-round-block>
    <t-round-block
      v-for="row in items"
      :key="row[idKeyName]"
      class="t-div-table__row"
      :class="getRowClass(row)"
      @click="onRowClick(row)"
      :active="row[idKeyName]==activeItemId"
    >
      <div
        class="t-div-table__col"
        :class="`t-div-table__col--${fieldName}`"
        v-for="(field, fieldName) in showedFields"
        :key="fieldName"
      >
        <div
          class="t-div-table__col__title"
        >
          <template
            v-if="$scopedSlots[`${fieldName}-title`]"
          >
            <slot :name="`${fieldName}-title`" :row="row"></slot>
          </template>
          <template
            v-else
          >
            {{field.title}}
          </template>
        </div>
        <div class="t-div-table__col__content">
          <template
            v-if="$scopedSlots[fieldName]"
          >
            <slot :name="fieldName" :row="row" :fieldName="fieldName"></slot>
          </template>
          <div v-else-if="typeof row[fieldName] === 'boolean'">
            {{row[fieldName] ? 'Да' : 'Нет'}}
          </div>
          <div v-else-if="row[fieldName] && field.options">
            {{(field.options.find(v => v.id == row[fieldName]) || {name: ``}).name}}
          </div>
          <div v-else-if="row[fieldName] && field.date">
            {{row[fieldName] | formatFullDate}}
          </div>
          <div v-else-if="row[fieldName] && field.number">
            {{row[fieldName] | numberFormat}}
          </div>
          <div v-else-if="typeof(row[fieldName]) !== 'object'">
            {{row[fieldName]}}
          </div>
          <div v-else-if="Array.isArray(row[fieldName])">
            {{row[fieldName].map(v => v[field.path || 'name']).join(', ')}}
          </div>
          <div v-else-if="row[fieldName] && field.path">
            {{row[fieldName][field.path]}}
          </div>
          <div v-else-if="row[fieldName] && row[fieldName].name">
            {{row[fieldName].name}}
          </div>
        </div>
      </div>
      <template
        v-if="expandedIds.includes(row[idKeyName])"
      >
        <div
          class="t-div-table__expanded-line"
          :class="expandLineClass(row)"
        />
        <div
          class="t-div-table__expanded"
          :class="expandClass(row)"
          @click.stop=""
        >
          <slot name="expanded" :row="row"/>
        </div>
      </template>
    </t-round-block>
    <t-round-block
      v-if="showResult && items.length > 0"
      class="t-div-table__row t-div-table__row--results"
    >
      <div
        class="t-div-table__col"
        :class="`t-div-table__col--${fieldName}-results`"
        v-for="(field, fieldName) in showedFields"
        :key="fieldName"
      >
        <div class="t-div-table__col__content">
          <slot :name="`${fieldName}-results`" :items="items" :fieldName="fieldName"></slot>
        </div>
      </div>
    </t-round-block>
  </div>
</template>

<script>
  export default {
    name: "t-div-table",
    props: {
      expandedIds: {
        type: Array,
        default: () => ([])
      },
      idKeyName: {
        type: String,
        default: 'id'
      },
      tableData: {
        type: Object
      },
      activeItemId: {
        type: [String, Number],
        default: undefined
      },
      expandable: {
        type: Boolean,
        default: false,
      },
      showResult: {
        type: Boolean,
        default: false,
      },
      expandLineClass: {
        type: Function,
        default: () => ``,
      },
      expandClass: {
        type: Function,
        default: () => ``,
      },
      getRowClass: {
        type: Function,
        default: () => ``,
      },
    },
    computed: {
      items () {
        if (this.tableData && this.tableData.data) {
          return this.tableData.data
        }
        return []
      },
      showedFields () {
        if (this.tableData && this.tableData._meta && this.tableData._meta.showedFields) {
          return this.tableData._meta.showedFields
        }
        return {}
      },
    },
    methods: {
      toggleExpand(row) {
        const id = row[this.idKeyName]
        let next = []

        if (this.expandedIds.includes(id)) {
          next = this.expandedIds.filter(v => v !== id)
        } else {
          next = [...this.expandedIds, id]
        }

        this.$emit('syncExpanded', next)
      },
      onRowClick(row) {
        if (this.expandable) {
          this.toggleExpand(row)
        }

        this.$emit('rowClick', row);
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-div-table {
    font-family: $fontFamily;

    .t-div-table__not-found {
      font-size: 14px;
      color: $BlackB100;
      padding: 16px;
    }

    .t-div-table__row {
      margin: 12px 3px 12px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0;
      position: relative;

      &:hover {
        background-color: $BlueB10;

        .t-div-table__col {
          .t-div-table__col__title {
            color: $BlackB60;
          }

          .t-div-table__col__content {
            color: $BlackB100;
          }
        }
      }

      .t-div-table__expanded-line {
        width: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $GrayG100;
        height: calc(100% - 16px);
      }

      .t-div-table__expanded {
        width: 100%;
      }

      &.t-div-table__row--results {
        .t-div-table__col__content {
          margin-top: 0;
          font-weight: bold;
          color: $BlackB100;
        }
      }
    }

    .t-div-table__col {
      margin: 16px;
      flex-grow: 1;

      .t-div-table__col__title {
        font-size: 10px;
        color: $GrayG100;
        margin: 3px 0;
      }

      .t-div-table__col__content {
        margin-top: 7px;
        font-size: 14px;
        color: $BlackB60;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <div style="background: #E0E3E9; padding: 14px;">
        <t-div-table
          :tableData="tableData"
          :autoNavigation="false"
        >
          <div
            slot="type"
            slot-scope="{row, fieldName}"
          >
            Слот поля {{fieldName}}
          </div>
        </t-div-table>
      </div>

      <div>
        <br>
        Редактируйте данные в редакторе ниже, чтобы увидеть как изменится таблица<br>
        <v-jsoneditor v-model="tableData" height="400px"></v-jsoneditor>
      </div>

    </div>
  </template>
  <script>
    import VJsoneditor from 'v-jsoneditor'

    export default {
      data: () => ({
        tableData: {
          "data": [
            {
              "id": 107,
              "username": "Антон",
              "friends": [
                {
                  "id": 7,
                  "name": "Аня"
                },
                {
                  "id": 2,
                  "name": "Петя"
                }
              ],
              "type": 2,
              "is_friend": false,
              "gender": {
                "id": 1,
                "name": "Мужской"
              },
              "location": {
                "country": "RU",
                "region": "MSK",
                "city": "Москва"
              }
            },
            {
              "id": 108,
              "username": "Иван",
              "friends": [
                {
                  "id": 7,
                  "name": "Аня"
                },
                {
                  "id": 2,
                  "name": "Петя"
                }
              ],
              "type": 2,
              "is_friend": false,
              "gender": {
                "id": 1,
                "name": "Мужской"
              },
              "location": {
                "country": "UK",
                "region": "Kiev",
                "city": "Киев"
              }
            }
          ],
          "_meta": {
            "showedFields": {
              "username": {
                "sort": true,
                "title": "Имя",
                stickyAtLeft: true
              },
              "friends": {
                "title": "Друзья",
              },
              "is_friend": {
                "sort": true,
                "title": "Это мой друг?"
              },
              "gender": {
                "title": "Пол"
              },
              "type": {
                "title": "Тип"
              },
              "location": {
                "sort": false,
                "title": "Местоположение",
                "path": "city",
                stickyAtRight: true
              }
            }
          }
        }
      }),
      components: {
        VJsoneditor
      }
    }
  </script>
  ```
</docs>
