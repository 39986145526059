<template>
  <div class="user restore-request">

    <login-block
      welcome="Восстановление пароля"
      welcome-small="Мы отправим на вашу почту ссылку для сброса пароля"
    >
      <t-form
        @submit="onSubmit"
        :formValidator="formValidator"
        name="restoreRequest"
      >
        <t-form-group
          placeholder="Логин"
          name="username"
          required
          v-model="model.username"
          behavior="material"
          hide-req-dot
        ></t-form-group>

        <t-button
          :loading="loading"
          type="submit"
          design="primary"
        >
          Продолжить
        </t-button>
      </t-form>
    </login-block>

  </div>
</template>

<script>
  import {FormValidator} from "../../components"
  import LoginBlock from "./components/login-block";

  export default {
    name: "RestoreRequest",
    components: {LoginBlock},
    data: () => ({
      loading: false,
      model: {
        username: '',
      }
    }),
    created() {
      this.formValidator = new FormValidator()
    },
    methods: {
      async onSubmit() {
        if (this.formValidator.validate().hasErrors() || this.loading) {
          return
        }

        try {
          this.loading = true

          await this.$router.push('/user/change-lost-password?code=aHR0cHM6Ly93d3cuYmFzZTY0ZW5jb2RlLm9yZy8=')
        } catch (e) {
          this.loading = false

          if (e.response && e.response.status && (+e.response.status === 422 || +e.response.status === 401)) {
            this.formValidator.markFieldAsInvalid('username', 'Неверный логин и/или пароль')
          } else {
            this.$notification.jsError(e)
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .restore-request {

  }
</style>
