<template>
  <div
    class="search search-results"
  >
    <t-filter-block>
      <t-title type="h2">Результаты поиска: {{orders.items.length + requests.items.length}}</t-title>
      <template slot="actions">
        <t-tag
          v-for="(name, key) in sections"
          :key="key"
          :name="name"
          :disabled="model.hiddenSections.includes(key)"
          @remove="hideSection(key)"
          @click="showSection(key)"
        />
      </template>
    </t-filter-block>

    <div v-if="!model.hiddenSections.includes(`orders`) && orders.items.length">
      <t-title type="h2">Заказ-наряды</t-title>
      <orders-table
        :itemData="orders"
      ></orders-table>
    </div>

    <div v-if="!model.hiddenSections.includes(`requests`) && requests.items.length">
      <t-title type="h2">Заявки</t-title>
      <repair-requests-table
        :item-data="requests"
      ></repair-requests-table>
    </div>
  </div>
</template>

<script>
  import {REST} from "vue-fast-rest";
  import {repairRequests, workOrders} from "../../api/endpoints";
  import OrdersTable from "../orders/components/orders-table";
  import RepairRequestsTable from "../requests/components/repair-requests-table";

  export default {
    components: {RepairRequestsTable, OrdersTable},
    data: () => ({
      model: {
        hiddenSections: [],
      },
    }),
    methods: {
      hideSection(key) {
        this.model.hiddenSections.push(key)
      },
      showSection(key) {
        this.model.hiddenSections = this.model.hiddenSections.filter(k => k !== key)
      },
    },
    computed: {
      sections() {
        return {
          requests: `Заявки`,
          orders: `Заказ-наряды`,
        }
      },
      requests() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          repairRequests.ordersListStartPrefix(),
        ).data
      },
      orders() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          workOrders.workOrdersListStartPrefix(),
        ).data
      },
    },
    async asyncData({store, route}) {
      const term = route.query.globalQ

      await Promise.all([
        store.dispatch(REST.actions.updateUrlEndpoint, {
          url: repairRequests.ordersByNumber(term),
          endpoint: repairRequests.ordersListStartPrefix(),
        }),
        await store.dispatch(REST.actions.updateUrlEndpoint, {
          url: workOrders.ordersByNumber(term),
          endpoint: workOrders.workOrdersListStartPrefix(),
        })
      ])
    },
  }
</script>

<style lang="scss">
  .search.search-results {
    .t-filter-block {
      height: 88px;
      display: flex;
      justify-content: space-between;
      padding: 24px;

      .t-tag--disabled {
        cursor: pointer;
      }
    }
  }
</style>
