<template>
  <div ref="canvasParent">
    <canvas ref="canvas"/>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce'
  import {errorStatusCodeDrawer} from './utils/errorStatusCodeDrawer'

  export default {
    name: 't-error-canvas-status',
    props: {
      status: null
    },
    watch: {
      'status': {
        handler: 'drawStatusCode'
      }
    },
    created() {
      this.resizeHandler = debounce(() => {
        this.drawStatusCode()
      }, 100)
    },
    mounted() {
      this.$nextTick(() => this.drawStatusCode())
      window.addEventListener('resize', this.resizeHandler)
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeHandler)
      this.canvas = null
    },
    methods: {
      drawStatusCode() {
        if (this.$refs.canvas && this.status) {
          this.canvas = errorStatusCodeDrawer(
            this.canvas,
            this.$refs.canvas,
            //`1234567890`,
            `${this.status}`,
            this.$refs.canvasParent
          )
        }
      }
    }
  }
</script>

<docs>
  ```vue
  <template>
    <t-error-canvas-status
      status="404"
    />
  </template>
  ```
</docs>
