<template>
  <div class="orders order-view">
    <t-round-block
      class="order-view__block order-view__toolbar"
    >
      <router-link
        :to="{ name: `orders.ordersJournal`}"
        class="order-view__toolbar__back"
      >
        <t-icon name="left"></t-icon>
        Вернуться к списку
      </router-link>
      <t-tabs
        v-model="currentTab"
        :options="tabsOptions"
      />
    </t-round-block>

    <t-page-layout-article
      v-show="currentTab === TABS.details.id"
      class="order-view__tab-details"
    >
      <t-form
        :formValidator="formValidator"
        name="orderViewDetails"
      >
        <div class="order-view__tab-details__header">
          <div>
            <t-input
              disabled
              name="transformedFields.number"
              v-model="transformedFields.number"
              size="small"
            />
          </div>
          <div>
            <t-color-progress
              size="small"
              design="colored-inline"
              :options="statuses"
              :option-id="model.status"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Дата и время создания заказ-наряда"
              name="transformedFields.dt"
            >
              <div class="order-view__tab-details__req-datetime">
                <t-input
                  disabled
                  name="transformedFields.date"
                  v-model="transformedFields.date"
                  size="small"
                  leftIcon="calendar"
                />
                <t-input
                  disabled
                  name="transformedFields.time"
                  v-model="transformedFields.time"
                  size="small"
                  leftIcon="alarm"
                />
              </div>
            </t-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Организация исполнителя"
              name="performer_company.id"
              v-model="model.performer_company.id"
              type="select"
              :options="[model.performer_company]"
            ></t-form-group>
            <t-form-group
              disabled
              label="Гос номер, марка"
              name="vehicle.id"
              v-model="model.vehicle.id"
              type="select"
              :options="[model.vehicle]"
            ></t-form-group>
          </div>
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Наименования организации"
              name="customer.id"
              v-model="model.customer.id"
              type="select"
              :options="[model.customer]"
            ></t-form-group>
            <t-form-group
              disabled
              label="Год выпуска ТС"
              name="vehicle.year"
              v-model="model.vehicle.year"
            ></t-form-group>
          </div>
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Подразделение организации"
              name="department.id"
              v-model="model.department.id"
              type="select"
              :options="[model.department]"
            ></t-form-group>
            <t-form-group
              disabled
              label="VIN номер ТС"
              name="vehicle.vin"
              v-model="model.vehicle.vin"
            ></t-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Прицеп"
              placeholder="Наличие прицепа"
              name="has_trailer"
              v-model="model.has_trailer"
              type="checkbox"
            ></t-form-group>
          </div>
          <div class="col-lg-4">
            <t-form-group
              v-if="model.has_trailer"
              disabled
              label="Прицеп"
              name="trailer.id"
              v-model="model.trailer.id"
              type="select"
              :options="[model.trailer]"
            ></t-form-group>
          </div>
          <div class="col-lg-4">
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Основной ремонт"
              name="work_type.id"
              v-model="model.work_type.id"
              type="select"
              :options="[model.work_type]"
            ></t-form-group>
            <t-form-group
              disabled
              label="Начало работ по Плану"
              name="transformedFields.start_date_plan"
              v-model="transformedFields.start_date_plan"
            ></t-form-group>
            <t-form-group
              disabled
              label="Город"
              name="city.id"
              v-model="model.city.id"
              type="select"
              :options="[model.city]"
            ></t-form-group>
          </div>
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Сопутствующий ремонт"
              name="second_work_type.id"
              v-model="model.second_work_type.id"
              type="select"
              :options="[model.second_work_type]"
            ></t-form-group>
            <t-form-group
              disabled
              label="Начало работ по факту"
              name="transformedFields.start_date_fact"
              v-model="transformedFields.start_date_fact"
            ></t-form-group>
            <t-form-group
              disabled
              label="Адрес прибытия"
              name="address"
              v-model="model.address"
            ></t-form-group>
          </div>
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Пробег"
              name="odometer_fact"
              v-model="model.odometer_fact"
            ></t-form-group>
            <t-form-group
              disabled
              label="Окончание работ по факту"
              name="transformedFields.end_date_fact"
              v-model="transformedFields.end_date_fact"
            ></t-form-group>
            <t-form-group
              disabled
              label="Дата и время прибытия"
              name="transformedFields.arrival_date"
              v-model="transformedFields.arrival_date"
            ></t-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <t-form-group
              disabled
              label="Ссылка на Заявку"
              name="link_to_req"
            >
              <div
                class="order-view__tab-details__link"
              >
                <router-link
                  :to="reqLink"
                  target="_blank"
                >{{model.appl_form.number || model.appl_form_id}}</router-link>
                <t-icon
                  name="copy"
                  @click="copyLinkToClipBoard()"
                />
              </div>
            </t-form-group>
          </div>
          <div class="col-lg-4"/>
          <div class="col-lg-4"/>
        </div>
      </t-form>
    </t-page-layout-article>

    <div
      v-show="currentTab === TABS.defects.id"
      class="order-view__tab-defects"
    >
      <t-round-block
        class="order-view__block"
      >
        <t-heading>
          Заявленные неисправности
          <router-link
            :to="reqLink"
            target="_blank"
            class="order-view__tab-defects__head-link"
          >
            {{model.appl_form.number || model.appl_form_id}}
            <t-icon name="hyperlink"/>
          </router-link>
        </t-heading>
        <t-list-numeric
          :options="model.appl_form.defect_list"
        />

        <t-heading>Выявленные  неисправности</t-heading>
        <t-list-numeric
          :options="model.defects"
        />
      </t-round-block>
    </div>

    <div
      v-show="currentTab === TABS.worksAndParts.id"
      class="order-view__tab-works-and-parts"
    >
      <t-round-block
        class="order-view__block order-view__tab-works-and-parts__works"
      >
        <t-heading>
          Работы
          <div class="order-view__tab-works-and-parts__all-btns">
            <t-button
              design="transparent-blue"
              @click="acceptAllWorks"
              :disabled="!canSendPartsAndWorks"
            >
              Согласовать все
            </t-button>
            <t-button
              design="transparent-red"
              @click="declineAllWorks"
              :disabled="!canSendPartsAndWorks"
            >
              Отказать все
            </t-button>
          </div>
        </t-heading>
        <t-div-table
          :table-data="{
            data: model.works,
            _meta: workTableMeta,
          }"
          expandable
          show-result
          :expandedIds="expandedWorks"
          @syncExpanded="ids => expandedWorks = ids"
          :expand-class="expandClass"
          :expand-line-class="expandClass"
        >
          <div
            slot="btnActions"
            slot-scope="{row}"
            @click.stop=""
          >
            <t-button
              @click="accept(row)"
              design="fill-blue"
              :active="row.state === STATES.approve"
              :disabled="!canAccept(row)"
              :key="`a-${row.id}`"
            >
              {{row.state === STATES.approve ? `Согласовано` : `Согласовать`}}
            </t-button>
            <t-button
              @click="dismiss(row)"
              design="fill-red"
              :active="row.state === STATES.dismiss"
              :disabled="!canDismiss(row)"
              :key="`d-${row.id}`"
            >
              {{row.state === STATES.dismiss ? `Отказано` : `Отказать`}}
            </t-button>
          </div>
          <div
            slot="btnTicks"
            slot-scope="{row}"
          >
            <t-checkbox
              placeholder="Выполнено"
              name="workDone"
              :value="row.state===STATES.done"
              design="green"
              orientation="right"
            />
          </div>
          <template slot="expanded" slot-scope="{row}">
            <textarea
              placeholder="Оставьте комментарий"
              rows="2"
              v-model="row.comment"
              :id="`work_${row.id}`"
              @blur="validateWorkOrPartsComment(row.id)"
              @input="validateWorkOrPartsComment(row.id)"
              :disabled="!canSendPartsAndWorks"
            ></textarea>
            <span v-if="row.validationError">{{row.validationError}}</span>
          </template>
          <template slot="position-results">
            Итого, руб:
          </template>
          <template slot="sumNDS-results">
            {{worksSumNDS | numberFormat}}
          </template>
        </t-div-table>
      </t-round-block>

      <t-round-block
        class="order-view__block order-view__tab-works-and-parts__parts"
      >
        <t-heading>
          Запасные части
          <div class="order-view__tab-works-and-parts__all-btns">
            <t-button
              design="transparent-blue"
              @click="acceptAllParts"
              :disabled="!canSendPartsAndWorks"
            >
              Согласовать все
            </t-button>
            <t-button
              design="transparent-red"
              @click="declineAllParts"
              :disabled="!canSendPartsAndWorks"
            >
              Отказать все
            </t-button>
          </div>
        </t-heading>
        <t-div-table
          :table-data="{
            data: model.spares,
            _meta: partTableMeta,
          }"
          expandable
          show-result
          :expandedIds="expandedParts"
          @syncExpanded="ids => expandedParts = ids"
          :expand-class="expandClass"
          :expand-line-class="expandClass"
        >
          <div
            slot="btnActions"
            slot-scope="{row}"
            @click.stop=""
          >
            <t-button
              @click="accept(row)"
              design="fill-blue"
              :active="row.state === STATES.approve"
              :disabled="!canAccept(row)"
              :key="`a-${row.id}`"
            >
              {{row.state === STATES.approve ? `Согласовано` : `Согласовать`}}
            </t-button>
            <t-button
              @click="dismiss(row)"
              design="fill-red"
              :active="row.state === STATES.dismiss"
              :disabled="!canDismiss(row)"
              :key="`d-${row.id}`"
            >
              {{row.state === STATES.dismiss ? `Отказано` : `Отказать`}}
            </t-button>
          </div>
          <div
            slot="btnTicks"
            slot-scope="{row}"
          >
            <t-checkbox
              placeholder="Выполнено"
              name="workDone"
              :value="row.state===STATES.done"
              design="green"
              orientation="right"
            />
          </div>
          <template slot="expanded" slot-scope="{row}">
            <textarea
              placeholder="Оставьте комментарий"
              rows="2"
              v-model="row.comment"
              :id="`spare_${row.id}`"
              @blur="validateWorkOrPartsComment(row.id)"
              @input="validateWorkOrPartsComment(row.id)"
              :disabled="!canSendPartsAndWorks"
            ></textarea>
            <span v-if="row.validationError">{{row.validationError}}</span>
          </template>
          <template slot="position-results">
            Итого, руб:
          </template>
          <template slot="sumNDS-results">
            {{sparesSumNDS | numberFormat}}
          </template>
        </t-div-table>
      </t-round-block>

      <t-round-block
        class="order-view__block order-view__tab-works-and-parts__doc-results"
      >
        <div>
          Итого по документу, руб:
        </div>
        <div>
          {{sumNDS | numberFormat}}
        </div>
        <div>
          <t-button
            design="primary"
            @click="sendPartsAndWorks"
            :loading="approving"
            :disabled="!canSendPartsAndWorks"
          >Отправить</t-button>
        </div>
      </t-round-block>
    </div>

    <div
      v-show="currentTab === TABS.attachments.id"
    >
      <t-round-block
        class="order-view__block"
      >
        <t-heading>Вложенные файлы</t-heading>
        <t-file-uploader
          v-if="model.files.length > 0"
          v-model="model.files"
          disabled
          name="attachments"
          :formValidator="formValidator"
          enable-download
          enable-download-all
          :downloadMethod="downloadMethod"
        />
        <div v-else>
          <p> </p>
          <p>Нет вложений</p>
        </div>
      </t-round-block>
    </div>

  </div>
</template>

<script>
  import {FormValidator} from "../../components";
  import {REST} from "vue-fast-rest";
  import {workOrders} from "../../api/endpoints";
  import {formatDate, formatTime, formatFullDate} from "../../utils/string";
  import {
    ORDER_STATUSES,
    ORDER_STATUSES_NEW,
    ORDER_STATUSES_ON_REPAIR,
    ORDER_STATUSES_WAIT_FOR_REPAIR
  } from "../../configs/config";
  import { saveAs } from 'file-saver';

  const TABS = {
    details: {
      id: `details`,
      name: `Основные данные`,
      icon: `report`,
    },
    defects: {
      id: `defects`,
      name: `Неисправности`,
      icon: `settings`,
    },
    worksAndParts: {
      id: `worksAndParts`,
      name: `Работы и запасные части`,
      icon: `timer`,
    },
    attachments: {
      id: `attachments`,
      name: `Файлы`,
      icon: `attachment`,
    },
  }

  export default {
    name: "OrderView",
    data: () => ({
      checkedWorks: [],
      checkedParts: [],
      currentTab: ``,
      approving: false,
      model: {
        performer_company: {},
        vehicle: {},
        trailer: {},
        applicant: {},
        customer: {},
        department: {},
        work_type: {},
        second_work_type: {},
        city: {},
        defects: [],
        has_trailer: false,
        works: [],
        spares: [],
        appl_form: {},
        files: [],
      },
      expandedParts: [],
      expandedWorks: [],
      workTableMeta: {
        "showedFields": {
          position: {
            title: `№ п/п`,
          },
          name: {
            title: `Ремонтная операция`,
          },
          hours: {
            title: `Трудоемкость, ч`,
            number: true,
          },
          priceHour: {
            title: `Стоимость ч/руб.`,
            number: true,
          },
          sumNDS: {
            title: `Сумма, руб`,
            number: true,
          },
          btnActions: {
            title: ``,
          },
          btnTicks: {
            title: ``,
          }
        },
      },
      partTableMeta: {
        "showedFields": {
          position: {
            title: `№ п/п`,
          },
          name: {
            title: `Номенклатура`,
          },
          vendorCode: {
            title: `Артикул`,
          },
          unit: {
            title: `Ед. измерения`,
          },
          amount: {
            title: `Количество`,
            number: true,
          },
          price: {
            title: `Цена, руб.`,
            number: true,
          },
          sumNDS: {
            title: `Сумма,  руб`,
            number: true,
          },
          btnActions: {
            title: ``,
          },
          btnTicks: {
            title: ``,
          }
        },
      },
    }),
    created() {
      this.liveCounts = 0
      this.formValidator = new FormValidator()

      if (this.$route.query.currentTab) {
        this.currentTab = this.$route.query.currentTab
      }

      this.mapDataToModel()

      this.subsGroup = this.$emitter.group(
        this.$emitter.listen('order.updateModel', this.onSocketMessage),
      )
    },
    mounted() {
      window.addEventListener('beforeunload', this.onTabClose)
    },
    destroyed() {
      window.removeEventListener('beforeunload', this.onTabClose)
      this.subsGroup.unsubscribe()
    },
    watch: {
      'currentTab': {
        handler() {
          this.$router.skipAsyncReplace({
            query: {
              ...this.$route.query,
              currentTab: `${this.currentTab}`,
            }
          })
        }
      }
    },
    async beforeRouteLeave(to, from, next) {
      if (!this.hasUnsavedChanges()) {
        next()
        return
      }

      let isConfirmed = false
      try {
        await this.$confirmation({
          title: `Данные исполнителю не отправлены. Отправить данные Исполнителю на согласование?`,
          yes: `Отправить`,
          no: `Отменить`
        })
        isConfirmed = true
        this.liveCounts = 0
      } catch (e) {
        isConfirmed = false
        this.liveCounts++
      }

      if (isConfirmed === true) {
        const result = await this.sendPartsAndWorks()

        if (result) {
          next()
          return
        }
      } else {
        if (this.liveCounts > 1) {
          next()
          return
        }
      }
    },
    computed: {
      item() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          workOrders.workOrderView(this.$route.params.id)
        ).data
      },
      transformedFields() {
        return {
          date: formatDate(this.model.create_dt),
          time: formatTime(this.model.create_dt),
          number: `№: ${this.model.number}`,
          start_date_fact: this.model.start_date_fact ? formatFullDate(this.model.start_date_fact) : `-`,
          arrival_date: this.model.arrival_date ? formatFullDate(this.model.arrival_date) : `-`,
          end_date_fact: this.model.end_date_fact ? formatFullDate(this.model.end_date_fact) : `-`,
          start_date_plan: this.model.start_date_plan ? formatFullDate(this.model.start_date_plan) : `-`,
        }
      },
      STATES() {
        return {
          none: 1,
          approve: 2,
          dismiss: 3,
          done: 4,
        }
      },
      TABS() {
        return TABS
      },
      tabsOptions() {
        return [
          TABS.details,
          TABS.defects,
          TABS.worksAndParts,
          TABS.attachments,
        ]
      },
      reqLink() {
        return {
          name: 'requests.requestView',
          params: { id: this.model.appl_form_id },
        }
      },
      statuses() {
        return [...ORDER_STATUSES]
      },
      canSendPartsAndWorks() {
        return [
          ORDER_STATUSES_NEW,
          ORDER_STATUSES_WAIT_FOR_REPAIR,
          ORDER_STATUSES_ON_REPAIR,
        ].includes(this.model.status)
      },
      sparesSumNDS() {
        return this.model.spares.reduce((sum, row) => {
          if (row.state === this.STATES.none || row.state === this.STATES.approve || row.state === this.STATES.done) {
            sum = sum + Number(row.sumNDS || 0)
          }
          return sum
        }, 0)
      },
      worksSumNDS() {
        return this.model.works.reduce((sum, row) => {
          if (row.state === this.STATES.none || row.state === this.STATES.approve || row.state === this.STATES.done) {
            sum = sum + Number(row.sumNDS || 0)
          }
          return sum
        }, 0)
      },
      sumNDS() {
        return this.worksSumNDS + this.sparesSumNDS
      }
    },
    methods: {
      onSocketMessage(data) {
        if (data.id !== this.model.id) {
          return
        }

        this.model = {
          ...this.model,
          ...data,
        }
      },
      mapDataToModel() {
        const toMerge = {}
        for (const key in this.item) {
          if (this.item[key] !== null && this.item[key] !== undefined) {
            toMerge[key] = this.item[key]
          }
        }
        this.model = Object.assign({}, JSON.parse(JSON.stringify({
          ...this.model,
          ...toMerge,
        })))
        this.model.has_trailer = Boolean(this.model.trailer.id)

        // преобразование временных знач. к единому виду
        this.model.works = this.model.works.map(v => this.prepareSparesOrWorks(v))
        this.model.spares = this.model.spares.map(v => this.prepareSparesOrWorks(v))

        // раскрытие строк, если есть комментарий
        this.expandedWorks = this.model.works.filter(v => v.comment).map(v => v.id)
        this.expandedParts = this.model.spares.filter(v => v.comment).map(v => v.id)

        this.model.files = this.model.files.map(file => ({
          ...file,
          id: file.id,
          name: file.file_name || `n/a`,
          size: file.file_size || 0,
        }))
      },
      prepareSparesOrWorks(v) {
        const row = {
          position: v.Position || v.position,
          id: v.Id || v.id,
          name: v.Name || v.name,
          price: v.Price || v.price,
          hours: v.Hours || v.hours,
          priceHour: v.PriceHour || v.price_hour,
          unit: v.Unit || v.unit,
          vendorCode: v.VendorCode || v.vendor_code,
          sumNDS: v.SumNDS || v.sum_n_d_s,
          amount: v.Amount || v.amount,
          comment: v.Comment || v.comment,
          state: v.State || v.state,
          validationError: null,
        }

        return {
          ...row,
          origin: JSON.parse(JSON.stringify(row)),
        }
      },
      copyLinkToClipBoard() {
        navigator.clipboard.writeText(`${window.location.origin}${this.$router.resolve(this.reqLink).href}`)
      },
      canAccept(row) {
        if (!this.canSendPartsAndWorks) {
          return false
        }
        if (row.state === this.STATES.done) {
          return false
        }
        if (row.origin.state !== this.STATES.none) {
          return false
        }
        return true
      },
      canDismiss(row) {
        if (!this.canSendPartsAndWorks) {
          return false
        }
        if (row.state === this.STATES.done) {
          return false
        }
        if (row.origin.state !== this.STATES.none) {
          return false
        }
        return true
      },
      accept(row) {
        if (!this.canAccept(row)) {
          return false
        }

        row.state = this.STATES.approve
      },
      dismiss(row) {
        if (!this.canDismiss(row)) {
          return false
        }

        row.state = this.STATES.dismiss
      },
      async acceptAllWorks() {
        try {
          await this.$confirmation({
            title: `Согласовать?`,
            yes: `Согласовать`,
            no: `Отменить`
          })

          this.model.works.forEach(row => this.accept(row))
        } catch (e) {
          console.log(`no`)
        }
      },
      async declineAllWorks() {
        try {
          await this.$confirmation({
            title: `Отказать?`,
            yes: `Отказать`,
            no: `Отменить`
          })

          this.model.works.forEach(row => this.dismiss(row))
        } catch (e) {
          console.log(`no`)
        }
      },
      async acceptAllParts() {
        try {
          await this.$confirmation({
            title: `Согласовать?`,
            yes: `Согласовать`,
            no: `Отменить`
          })

          this.model.spares.forEach(row => this.accept(row))
        } catch (e) {
          console.log(`no`)
        }
      },
      async declineAllParts() {
        try {
          await this.$confirmation({
            title: `Отказать?`,
            yes: `Отказать`,
            no: `Отменить`
          })

          this.model.spares.forEach(row => this.dismiss(row))
        } catch (e) {
          console.log(`no`)
        }
      },
      async sendPartsAndWorks() {
        if (this.approving) {
          return false
        }
        if (!this.canSendPartsAndWorks) {
          return false
        }

        try {
          await this.$confirmation({
            title: `Согласовать?`,
            message: `Согласовать заказ-наряд и отправить исполнителю`,
            yes: `Отправить`,
            no: `Отменить`
          })
        } catch (e) {
          return false
        }

        let lasInvalidRowId = this.validateWorkOrPartsComment()

        if (lasInvalidRowId) {
          this.$nextTick(() => {
            const el = document.getElementById(lasInvalidRowId)
            if (el) {
              el.focus()
            }
          })

          return false
        }

        for (const v of [
          ...this.model.works,
          ...this.model.spares,
        ]) {
          if (![this.STATES.dismiss, this.STATES.approve, this.STATES.done].includes(v.state)) {
            this.$notification.jsError({
              name: `Ошибка`,
              message: `Согласуйте либо откажите в позиции "${v.name}"`
            })
            return false
          }
        }

        try {
          this.approving = true

          await this.$store.dispatch(REST.actions.createModel, {
            url: workOrders.approveWorkOrder(this.model.id),
            data: {
              data: {
                Works: this.model.works.map(w => ({
                  Id: w.id,
                  State: w.state,
                  Comment: w.comment,
                })),
                Spares: this.model.spares.map(s => ({
                  Id: s.id,
                  State: s.state,
                  Comment: s.comment,
                })),
              }
            }
          })

          this.$alert.success({
            title: `Данные успешно отправлены`,
          })

          await this.reload()

          return true
        } catch (e) {
          this.$alert.jsError(e)
        } finally {
          this.approving = false
        }

        return false
      },
      validateWorkOrPartsComment(id = null) {
        let lasInvalidRowId = null

        for (const v of this.model.works) {
          if (id && id !== v.id) {
            continue
          }

          if (v.state === this.STATES.dismiss && !v.comment) {
            v.validationError = `Заполните причину отказа`
            this.expandedWorks.push(v.id)
            lasInvalidRowId = `work_${v.id}`
          } else {
            v.validationError = null
          }
        }

        for (const v of this.model.spares) {
          if (id && id !== v.id) {
            continue
          }

          if (v.state === this.STATES.dismiss && !v.comment) {
            v.validationError = `Заполните причину отказа`
            this.expandedParts.push(v.id)
            lasInvalidRowId = `spare_${v.id}`
          } else {
            v.validationError = null
          }
        }

        return lasInvalidRowId
      },
      hasUnsavedChanges() {
        for (const v of [
          ...this.model.works,
          ...this.model.spares,
        ]) {
          if (v.state !== v.origin.state || v.comment !== v.origin.comment) {
            console.warn(`unsaved`, JSON.stringify(v, null, " "))
            return true
          }
        }

        return false
      },
      onTabClose(e) {
        if (this.liveCounts > 1) {
          return
        }

        if (this.hasUnsavedChanges()) {
          e.preventDefault()
          e.returnValue = ''

          this.liveCounts++
        }
      },
      async reload() {
        await this.$options.asyncData({
          store: this.$store,
          route: this.$route,
        })
        this.mapDataToModel()
      },
      async downloadMethod({ file, files }) {
        if (file) {
          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            url: file.http_url,
            params: {
              responseType: 'blob',
              disableGlobalError: true,
            }
          })

          saveAs(data, file.name)
        }

        if (files && files.length) {
          for (const file of files) {
            const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
              url: file.http_url,
              params: {
                responseType: 'blob',
                disableGlobalError: true,
              }
            })

            saveAs(data, file.name)
          }
        }
      },
      expandClass (v) {
        return v.validationError ? `invalid` : ``
      },
    },
    async asyncData({store, route}) {
      await store.dispatch(REST.actions.updateUrlEndpoint, {
        endpoint: workOrders.workOrderView(route.params.id),
      })
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .order-view {
    .order-view__block {
      margin-bottom: 10px;
      padding-left: 24px;
      padding-right: 24px;

      > .t-heading {
        margin-left: -24px;
        margin-right: -24px;
      }

      > .t-list-numeric {
        margin-left: -24px;
        margin-right: -24px;
        padding: 12px 24px;
        border-bottom: 1px solid $Gray_Checkbox_BG;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: -16px;
        }
      }

      > .t-div-table {
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: -16px;

        .t-div-table__row {
          background: transparent;
          margin: 0;
          border-radius: 0;
          border-bottom: 1px solid $Gray_Checkbox_BG;
        }

        .t-div-table__col {
          margin: 16px 24px;
        }
      }

      > .t-file-uploader {
        margin-top: 0;
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: -16px;

        .t-file-uploader__files {
          margin: 4px;
          background: transparent;

          .t-file-uploaded__details {
            > b {
              color: $BlackB60;
              font-weight: normal;
            }

            > small {
              color: $GrayG100;
              font-weight: normal;
            }
          }

          .t-file-uploaded__download {
            .t-button {
              color: $GrayG100;
            }
          }
        }

        .t-file-uploader__download-all {
          margin-left: 24px;
          margin-right: 24px;
        }
      }
    }

    .order-view__toolbar {
      padding-bottom: 10px;

      .order-view__toolbar__back {
        color: $BlueB100;
        font-size: 12px;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .t-icon {
          margin-right: 5px;
        }
      }
    }

    .order-view__tab-details {
      .t-form {
        padding: 0;

        .row {
          margin-top: 3px;
          margin-bottom: -14px;
        }
      }

      .order-view__tab-details__header {
        display: flex;
        align-items: center;

        > div {
          margin-right: 24px;
        }

        .t-input {
          width: 190px;
        }

        .t-color-progress {
          .t-color-progress__bar {
            width: 162px;
          }
        }
      }

      .order-view__tab-details__link {
        display: flex;
        font-size: 16px;
        color: $BlueB100;

        a {
          color: inherit;
          text-transform: none;
          text-decoration: none;
        }

        .t-icon {
          margin-top: -1px;
          margin-left: 8px;
          cursor: pointer;
        }
      }

      .order-view__tab-details__req-datetime {
        display: flex;
        align-items: center;

        .t-input {
          margin-right: 12px;

          &[name="transformedFields.date"] {
            width: 112px;
          }

          &[name="transformedFields.time"] {
            width: 77px;
          }
        }
      }
    }

    .order-view__tab-defects {
      .t-title {
        display: flex;
        justify-content: space-between;

        .order-view__tab-defects__head-link {
          font-size: 14px;
          color: $BlueB100;
          text-decoration: none;
          font-weight: normal;
          display: flex;

          .t-icon {
            padding-left: 4px;
            margin-top: -2px;
          }
        }
      }
    }

    .order-view__tab-works-and-parts {
      .t-div-table .t-div-table__row {
        .t-div-table__expanded-line {
          &.invalid {
            background: $RedR100;
          }
        }

        .t-div-table__expanded {
          &.invalid {
            textarea {
              background: $ErrorBg;
            }
          }
        }

        .t-div-table__col {
          margin: 16px 16px;
          margin-right: 10px;
          letter-spacing: -0.4px;
        }
      }

      .order-view__tab-works-and-parts__all-btns {
        position: absolute;
        top: -4px;
        right: 134px;
        display: flex;

        .t-button {
          letter-spacing: -0.2px;
          font-size: 10px;

          .t-button-text {
            padding: 10px 0;
          }
        }
      }

      .t-div-table__row {
        .t-div-table__col--btnActions {
          margin: 0 16px;
          flex-grow: 0;
          width: 200px;

          .t-button {
            letter-spacing: -0.2px;
            font-size: 10px;
          }
        }
        .t-div-table__col--btnTicks {
          margin: 0 24px;
          flex-grow: 0;
          width: 98px;

          .t-checkbox {
            cursor: not-allowed;

            .t-checkbox__placeholder {
              font-size: 12px;
            }
          }
        }
      }

      .order-view__tab-works-and-parts__works {
        .t-div-table__row {
          .t-div-table__col {
            &:nth-child(1) {
              flex-grow: 0;
              width: 30px;
            }
            &:nth-child(2) {
              flex-grow: 0;
              width: 360px;
            }
            &:nth-child(3) {
              flex-grow: 0;
              width: 80px;
            }
            &:nth-child(4) {
              flex-grow: 0;
              width: 80px;
            }

            &.t-div-table__col--position-results {
              width: 390px;
            }
            &.t-div-table__col--name-results {
              width: 0;
            }
          }
        }
      }
      .order-view__tab-works-and-parts__parts {
        .t-div-table__row {
          .t-div-table__col {
            &:nth-child(1) {
              flex-grow: 0;
              width: 30px;
            }
            &:nth-child(2) {
              flex-grow: 0;
              width: 148px;
            }
            &:nth-child(3) {
              flex-grow: 0;
              width: 80px;
            }
            &:nth-child(4) {
              flex-grow: 0;
              width: 80px;
            }
            &:nth-child(5) {
              flex-grow: 0;
              width: 80px;
            }
            &:nth-child(6) {
              flex-grow: 0;
              width: 80px;
            }

            &.t-div-table__col--position-results {
              width: 178px;
            }
            &.t-div-table__col--name-results {
              width: 0;
            }
          }
        }
      }

      .t-div-table__col--btnActions, .t-div-table__col--btnTicks {
        display: flex;
        align-items: center;

        .t-div-table__col__title {
          display: none;
        }

        .t-div-table__col__content {
          margin-top: 0;

          > div {
            display: flex;

            .t-button {
              margin: 0 6px;
            }
          }
        }
      }

      .t-div-table__expanded {
        border-top: 1px solid $Gray_Checkbox_BG;

        textarea {
          font-family: $fontFamily;
          font-size: 14px;
          color: $BlackB100;
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          padding: 12px;

          &::placeholder {
            color: $BlackB40;
          }

          &:focus, &:active, &:hover {
            border: none;
            outline: none;
          }

          &:disabled {
            background: none;
            cursor: not-allowed;
          }
        }

        span {
          padding: 0 12px;
          position: absolute;
          bottom: 0;
          left: 0;
          color: $Error;
          font-size: 12px;
        }
      }

      .order-view__tab-works-and-parts__doc-results {
        display: flex;
        align-items: center;
        font-family: $fontFamily;
        font-weight: bold;
        font-size: 14px;

        > div {
          &:first-child {
            flex-grow: 0;
            min-width: 200px;
            width: 200px;
          }

          &:nth-child(2) {
            width: 100%;
          }

          &:last-child {
            flex-grow: 0;
            width: 250px;

            .t-button {
              height: 32px;
              width: 100%;
              border-radius: 10px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1439px) {
    .order-view {
      .order-view__tab-works-and-parts {
        .order-view__tab-works-and-parts__all-btns {
          right: 24px;
        }
      }
    }
  }

  @media (min-width: 1840px) {
    .order-view {
      .order-view__tab-works-and-parts {
        .order-view__tab-works-and-parts__works {
          .t-div-table__row {
            .t-div-table__col {
              &:nth-child(1) {
                flex-grow: 0;
                width: 40px;
              }
              &:nth-child(2) {
                flex-grow: 0;
                width: 530px;
              }
              &:nth-child(3) {
                flex-grow: 0;
                width: 145px;
              }
              &:nth-child(4) {
                flex-grow: 0;
                width: 145px;
              }

              &.t-div-table__col--position-results {
                width: 570px;
              }
              &.t-div-table__col--name-results {
                width: 0;
              }
            }
          }
        }
        .order-view__tab-works-and-parts__parts {
          .t-div-table__row {
            .t-div-table__col {
              &:nth-child(1) {
                flex-grow: 0;
                width: 40px;
              }
              &:nth-child(2) {
                flex-grow: 0;
                width: 188px;
              }
              &:nth-child(3) {
                flex-grow: 0;
                width: 145px;
              }
              &:nth-child(4) {
                flex-grow: 0;
                width: 145px;
              }
              &:nth-child(5) {
                flex-grow: 0;
                width: 145px;
              }
              &:nth-child(6) {
                flex-grow: 0;
                width: 145px;
              }

              &.t-div-table__col--position-results {
                width: 228px;
              }
              &.t-div-table__col--name-results {
                width: 0;
              }
            }
          }
        }

        .order-view__tab-works-and-parts__all-btns {
          right: 190px;
        }
        .t-div-table__row {
          .t-div-table__col--btnActions {
            width: 250px;
          }
        }
      }
    }
  }
</style>
