import Vue from 'vue'

import Alert from "./alert.vue"

function createAlert(props) {
  let alert = new Vue({
    ...Alert,
    propsData: {
      ...props,
    },
  }).$mount()

  document.body.appendChild(alert.$el)

  alert.kill = () => {
    alert.$destroy()
    alert.$el.parentNode.removeChild(alert.$el)
    alert = null
  }

  return alert
}

export default function ({ recognizeError }) {
  return {
    loading: (props = {}) => {
      return createAlert({
        ...props,
        type: 'loading',
      })
    },
    success: (props = {}) => {
      return createAlert({
        ...props,
        type: 'success',
      })
    },
    jsError: (error) => {
      const recognizedError = recognizeError(error)

      return createAlert({
        title: `Ошибка`,
        message: recognizedError.message,
        duration: 5000,
        type: 'error',
      })
    },
  }
}
