<template>
  <div
    class="t-select-wrapper"
    :class="{
       't-select-wrapper--required': required,
    }"
  >
    <el-select
      class="t-select"
      :class="additionalClasses"
      :value="value"
      :name="name"
      @input="$emit('input', $event)"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="onSelectChange($event)"
      :clearable="!hideClearIcon"
      popper-class="t-select-popper"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {baseFieldMixin} from "../mixins/base-field-mixin"

  export default {
    name: "t-select",
    mixins: [baseFieldMixin],
    props: {
      options: {
        type: Array,
        default: () => ([])
      }
    },
    watch: {
      'value': 'validateField',
    },
    methods: {
      /**
       * Возвращает domElement например для фокусировки на нем
       * если валидация провалена
       * @returns {HTMLElement}
       */
      getElement () {
        const node = this.$el.getElementsByTagName('input')
        if (node) {
          return node[0]
        }
        return null
      }
    },
    computed: {
      additionalClasses() {
        return {
          't-select--is-invalid': this.hasError,
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";
  @import "./select-popper";

  .t-select {
    font-family: $fontFamily;
    width: 100%;

    &.t-select--is-invalid {
      &.el-select {
        .el-input {
          .el-input__inner {
            border: 1px solid $Error;
            background: $ErrorBg;
          }
        }
      }
    }

    &.el-select {
      height: 46px;

      .el-input {
        height: 100%;

        .el-input__inner {
          width: 100%;
          font-family: $fontFamily;
          border: 1px solid transparent;
          background: $GrayG20;
          border-radius: 16px;
          padding: 11px 30px 11px 15px;
          font-size: 14px;
          font-weight: 500;
          color: $BlackB80;
          outline: none;
          height: 46px;

          &:focus {
            border: 1px solid $Focus;
          }

          &:active {
            border: 1px solid $Focus;
          }

          &:disabled {
            border: 1px solid transparent;
            background-color: $BlackB10;
            color: $BlackB60;
          }

          &::placeholder {
            color: $Placeholder;
          }
        }

        .el-select__caret {
          &:before, &:after {
            display: none;
          }

          background: url('./icons/down.svg');
          background-repeat: no-repeat;
          background-position: center;

          .is-reverse {
            background: url('./icons/up.svg');
          }
        }

        &.is-disabled {
          .el-select__caret {
            background: url('./icons/down--disabled.svg');
            background-repeat: no-repeat;
            background-position: center;

            .is-reverse {
              background: url('./icons/up--disabled.svg');
            }
          }
        }
      }
    }
  }

  .t-select-wrapper {
    position: relative;

    .t-fake-placeholder {
      right: 30px;
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-select
        v-model="foo"
        name="bar"
        :options="[{id: 1, name: 'М'},{id: 2, name: 'Н'}]"
      ></t-select>
      <p>used: https://element.eleme.io/#/en-US/component/select</p>
    </div>
  </template>

  <script>
    export default {
      data: () => ({
        foo: ''
      })
    }
  </script>
  ```
</docs>

