<template>
  <div
    class="t-error-small"
    v-if="formattedError"
  >
    <t-icon name="close"/>
    <div
      class="t-error-small__message"
      v-html="formattedError"
    />
  </div>
</template>

<script>
  export default {
    name: 't-error-small',
    props: {
      error: null
    },
    computed: {
      formattedError () {
        const parts = []
        if (this.error) {
          if (this.error.name) {
            parts.push(`<b>Ошибка - ${this.error.status || ''} ${this.error.name}</b>`)
          }
          if (this.error.message) {
            parts.push(`${this.error.message} `)
          }
          if (this.error.additional) {
            parts.push(`Доп. информация: ${this.error.additional}`)
          }
        }
        return parts.join('<br>')
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-error-small {
    font-family: $fontFamily;
    font-size: 14px;

    background: rgb(237, 243, 246);
    border-radius: 14px;
    margin: 15px;
    padding: 15px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    user-select: all;

    .t-icon {
      position: relative;
      width: 30px;
      margin-right: 15px;

      svg {
        path {
          stroke: #EB5757;
        }
      }
    }

    .t-error-small__message {
      word-break: break-all;
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-button @click="showError">Show error</t-button>

      <t-error-small
        :error="error"
      ></t-error-small>
    </div>
  </template>
  <script>
    export default {
      data: () => ({
        error: null
      }),
      methods: {
        showError () {
          this.error = {
            name: 'title',
            message: 'message'
          }
        }
      }
    }
  </script>
  ```
</docs>
