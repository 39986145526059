var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-tag-input-wrapper row"},[_c('div',{staticClass:"t-tag-input",class:{
       'col-md-6': !_vm.disabled,
       'col-md-12': _vm.disabled,
    }},[(!_vm.disabled)?_c('div',{staticClass:"t-tag-input__select"},[_c('t-input',{ref:"input",attrs:{"name":_vm.name,"placeholder":_vm.placeholder,"hasError":_vm.hasError,"has-label":_vm.hasLabel,"required":_vm.required,"custom-enter-reaction":""},on:{"enter":_vm.onEnter},model:{value:(_vm.inputModel),callback:function ($$v) {_vm.inputModel=$$v},expression:"inputModel"}})],1):_vm._e(),(_vm.value && _vm.value.length)?_c('div',{staticClass:"t-tag-input__tags",class:{
      't-tag-input__tags--disabled': _vm.disabled,
      }},_vm._l((_vm.value),function(name){return _c('div',{key:name,staticClass:"t-tag-input__tags__tag"},[_c('div',[_vm._v(" "+_vm._s(name)+" ")]),(!_vm.disabled)?_c('div',{on:{"click":function($event){return _vm.removeTag(name)}}},[_c('t-icon',{attrs:{"name":"close"}})],1):_vm._e()])}),0):_vm._e()]),(!_vm.disabled)?_c('div',{staticClass:"t-tag-input-button col-md-6"},[_c('t-button',{class:{
        't-button--active-tag': this.inputModel.length > 0,
      },attrs:{"icon":"","icon-type":"plus"},on:{"click":_vm.addTag}},[_vm._v(_vm._s(_vm.addTagLabel))])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }