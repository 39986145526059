<template>
  <t-round-block
    class="t-document"
    :class="{
      't-document--is-unread': isUnread,
    }"
    @click="$emit('click', $event)"
    @mouseover="$emit('mouseover', $event)"
  >
    <div
      class="t-document__header"
    >
      <div class="t-document__header__pre">
        <time>{{time}}</time>
        <div class="t-document__header__pre__tags">
          <slot name="tags"></slot>
        </div>
      </div>
      <t-button
        icon
        icon-type="close"
        class="t-document__header__close"
        @click="$emit('close')"
        transparent
      />
      <t-title type="h2">{{title}}</t-title>
    </div>
    <div class="t-document__body">
      <slot></slot>
    </div>
    <div class="t-document__footer">
      <slot name="footer"></slot>
    </div>
  </t-round-block>
</template>

<script>
  export default {
    name: "t-document",
    props: {
      time: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      isUnread: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-document {
    font-family: $fontFamily;

    &.t-document--is-unread {
      .t-document__header {
        .t-title {
          font-weight: bold;
        }

        .t-document__header__pre {
          .t-document__header__pre__tags {
            color: $GreenG100;
          }
        }
      }
    }

    .t-document__header {
      position: relative;

      .t-title {
        font-weight: normal;
      }

      .t-document__header__close {
        position: absolute;
        right: -7px;
        top: -7px;

        svg {
          height: 14px;
          width: auto;
        }
      }

      .t-document__header__pre {
        display: flex;
        color: $BlackB100;
        font-size: 10px;
        padding: 4px 0;

        .t-document__header__pre__tags {
          padding: 0 12px;
          color: $PurpleP100;
          font-weight: bold;
        }
      }
    }

    .t-document__body {
      color: $BlackB80;
      font-size: 14px;
      padding: 2px 0;
    }

    .t-document__footer {
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      padding-top: 8px;

      a {
        color: $BlueB100;
      }

      .t-button {
        font-size: 14px;

        .t-button-text {
          padding: 10px 10px;
        }
      }
    }
  }
</style>


<docs>
  ```vue
  <template>
    <div style="background: #eee;padding: 1px 12px;">
      <t-document-list>
        <t-document
          title="Udjdks dssdf"
          time="12.04.2012 8:45"
          :is-unread="true"
        >
          <span slot="tags">Tag</span>
          Fnsffd sdfein sfdsf
          <template slot="footer">
            <t-button design="primary">
              Открыть документ
            </t-button>
          </template>
        </t-document>

        <t-document
          title="Udjdks dssdf"
          time="12.04.2012 8:45"
        >
          <span slot="tags">Tag</span>
          Fnsffd sdfein sfdsf
          <template slot="footer">
            <router-link :to="{name: 'requests.requestView', params: { id: 1 }}">
              Перейти
            </router-link>
          </template>
        </t-document>
      </t-document-list>
    </div>
  </template>
  ```
</docs>
