import {REST} from "vue-fast-rest"

import apiClient from '../api/ApiClient'
import {notifications, user} from '../api/endpoints'
import {ADMIN_LOGGED_AS_USER_USER_DATA, JWT_RENEW_CHECK_INTERVAL, JWT_TOLERANCE} from '../configs/config'

export default {
  startJwtTokenRenewTimeChecker: function (context, {
    interval = JWT_RENEW_CHECK_INTERVAL
  } = {}) {
    context.dispatch('renewToken')

    if (this._jwtRenewInterval) {
      clearInterval(this._jwtRenewInterval)
    }

    this._jwtRenewInterval = setInterval(function () {
      context.dispatch('renewToken')
    }, interval)
  },
  renewToken: async function (context, {
    http = apiClient,
    tolerance = JWT_TOLERANCE,
    forceUpdate = false
  } = {}) {
    if (ADMIN_LOGGED_AS_USER_USER_DATA) {
      console.warn('skip renewToken, reason: Logged as other user')
      return
    }

    const jwt = context.getters.jwtToken
    if (!jwt) {
      return
    }

    const expireAtWithTolerance = +jwt.expireInUTCMilliseconds - tolerance
    const untilRefresh = expireAtWithTolerance - +new Date()

    if (untilRefresh <= 0 || isNaN(untilRefresh) || forceUpdate === true) {
      const services = await context.dispatch(REST.actions.getServices)
      const {data} = await http.update(services, user.refreshToken(), jwt)

      // без проверки если пользователь нажал выйти во время обновления, он не раслогинится
      if (context.getters.isGuest === false) {
        context.commit('userSetAuthData', {authData: data})
      }
    }
  },
  updateUnread: async function (context, { token = ``} = {}) {
    if (!token) {
      token = context.getters.jwtToken
    }

    try {
      const {data} = await context.dispatch(REST.actions.updateUrlEndpoint, {
        url: notifications.getUnreadCount(),
        params: {
          headers: {'Authorization': `Bearer ${token}`},
          disableGlobalError: true,
        },
      })

      context.commit('setUnreadCount', data.count)
    } catch (e) {
      console.error({ ...e })
    }
  },
  decreaseUnreadCount: function (context) {
    const next = context.state.unreadCount - 1

    context.commit('setUnreadCount', next)
  },
  increaseUnreadCount: function (context) {
    const next = context.state.unreadCount + 1

    context.commit('setUnreadCount', next)
  },
  markAsRead: async function (store, { emitter, id }) {
    try {
      await store.dispatch(REST.actions.createModel, {
        url: notifications.markAsRead(id),
        data: undefined,
      })

      await store.dispatch(`decreaseUnreadCount`)

      emitter.emit('notifications.read', {
        id,
      })
    } catch (e) {
      console.warn(`cant mark as read`, { ...e })
    }
  }
}
