<template>
  <div class="t-tabs">
    <div
      v-for="tab in options"
      :key="tab.id"
      @click="$emit('input', tab.id)"
      class="t-tabs__tab"
      :class="{
        't-tabs__tab--active': tab.id === value,
      }"
    >
      <t-icon
        v-if="tab.icon"
        :name="tab.icon"
      />
      {{tab.name}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-tabs",
    props: {
      value: null,
      options: {
        type: Array,
        default: () => ([
          {
            id: `my-tab`,
            name: `My tab`,
            icon: `settings`
          }
        ])
      }
    },
    created() {
      if (!this.value && this.options[0]) {
        this.$emit('input', this.options[0].id)
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-tabs {
    display: flex;
    font-family: $fontFamily;
    letter-spacing: -0.4px;
    flex-wrap: wrap;
    margin: -8px -23px;

    .t-tabs__tab {
      color: $GrayG100;
      font-size: 12px;
      margin: 8px 23px;
      display: flex;
      align-items: center;

      .t-icon {
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
      }

      &--active {
        color: $BlueB100;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-tabs
        :options="tabsOptions"
        v-model="currentTab"
      />
      currentTab={{currentTab}}
    </div>
  </template>
  <script>
    export default {
      data: () => ({
        currentTab: '',
        tabsOptions: [
          {
            "id": "details",
            "name": "Основные данные",
            "icon": "report"
          },
          {
            "id": "defects",
            "name": "Неисправности",
            "icon": "settings"
          },
          {
            "id": "works",
            "name": "Работы и запасные части",
            "icon": "timer"
          },
          {
            "id": "attachments",
            "name": "Файлы",
            "icon": "attachment"
          },
        ],
      })
    }
  </script>
  ```
</docs>
