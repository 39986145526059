<template>
  <t-modal
    is-opened
    class="build-report-modal"
    fog
    center
  >
    <t-title type="h2">Создание отчета</t-title>
    <div>
      <t-form
        @submit="onSubmit"
        :formValidator="formValidator"
        name="buildReport"
        layout="vertical"
      >
        <t-form-group
          label="Вид отчета"
          name="report_type"
          v-model="model.report_type"
          type="select"
          :options="reportTypes"
          required
        ></t-form-group>

        <template v-if="model.report_type">
          <div class="row">
            <t-form-group
              class="col-md-6"
              label="Период начало"
              name="dateFrom"
              v-model="model.dateFrom"
              type="calendar"
              required
              :lessThanValue="model.dateTo"
            ></t-form-group>
            <t-form-group
              class="col-md-6"
              label="Период конец"
              name="dateTo"
              v-model="model.dateTo"
              type="calendar"
              required
              :greaterThanValue="model.dateFrom"
            ></t-form-group>
          </div>

          <t-form-group
            :disabled="customers.length === 1"
            label="Заказчик"
            name="customerId"
            v-model="model.customerId"
            type="select"
            :options="customers"
            required
          ></t-form-group>

          <t-form-group
            :disabled="customersDepartments.length <= 1"
            label="Подразделение"
            name="customerDepartmentId"
            v-model="model.customerDepartmentId"
            type="select"
            :options="customersDepartments"
          ></t-form-group>

          <t-form-group
            label="Гос номер, марка"
            name="vehicleId"
            v-model="model.vehicleId"
            type="select-suggestion"
            :options="vehicles"
            :remote-method="findVehicles"
            :remote="true"
            :hideClearIcon="false"
          ></t-form-group>

          <t-form-group
            v-if="model.report_type===REPORT_TYPE_WORK_ORDER"
            label="Состояние"
            name="status"
            v-model="model.status"
            type="select"
            :options="statuses"
          ></t-form-group>

          <t-form-group
            v-if="model.report_type===REPORT_TYPE_WORK_ORDER"
            label="Номер"
            name="workOrderId"
            v-model="model.workOrderId"
            type="select-suggestion"
            :options="workNumbers"
            :remote-method="findWorkNumbers"
            :remote="true"
            :hideClearIcon="false"
          ></t-form-group>
        </template>

        <t-form-actions>
          <t-button
            @click="$emit('close')"
            design="secondary-red"
          >
            Отмена
          </t-button>

          <t-button
            type="submit"
            design="primary"
            :disabled="!model.report_type"
          >
            Сформировать
          </t-button>
        </t-form-actions>
      </t-form>
    </div>
  </t-modal>
</template>

<script>
  import {FormValidator} from "../../../components";
  import {ORDER_STATUSES, REPORT_TYPE_WORK_ORDER, REPORT_TYPES} from "../../../configs/config";
  import {REST} from "vue-fast-rest";
  import {reports, voc, workOrders} from "../../../api/endpoints";
  import { saveAs } from 'file-saver';
  import {onlyUniqItemsInRef} from "../../../utils/helpers";
  import moment from 'moment'

  export default {
    name: "BuildReportModal",
    data: () => ({
      vehicles: [],
      workNumbers: [],
      model: {
        report_type: ``,
        dateFrom: ``,
        dateTo: ``,
        customerId: ``,
        customerDepartmentId: ``,
        vehicleId: ``,
        status: ``,
        workOrderId: ``,
      },
    }),
    created() {
      this.formValidator = new FormValidator()

      if (this.$store.getters.userProfile.customers.length === 1) {
        this.model.customerId = this.$store.getters.userProfile.customers[0].id
      }
      this.model.customerDepartmentId = ""

      this.subsGroup = this.$emitter.group(
        this.$emitter.listen('report.newModel', this.onNewReport),
      )

      this.findWorkNumbers(``)
    },
    destroyed () {
      this.subsGroup.unsubscribe()
    },
    watch: {
      'model.customerId': `onCustomerIdChanged`,
      'model.customerDepartmentId': `onDepartmentIdChanged`
    },
    computed: {
      REPORT_TYPE_WORK_ORDER() {
        return REPORT_TYPE_WORK_ORDER
      },
      statuses() {
        return [...ORDER_STATUSES]
      },
      reportTypes() {
        return [...REPORT_TYPES]
      },
      customers() {
        if (this.$store.getters.userProfile && this.$store.getters.userProfile.customers) {
          return this.$store.getters.userProfile.customers
        }
        return []
      },
      customersDepartments() {
        const data = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.customerDepartments(this.model.customerId),
        )

        if (data && data.data && data.data.items) {
          return data.data.items
        }

        return []
      },
    },
    methods: {
      async findVehicles(term) {
        const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.departmentVehicles(this.model.customerDepartmentId, term),
          cache: Infinity,
        });
        this.vehicles = data.data.items
      },
      async findWorkNumbers(term) {
        const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: workOrders.ordersByNumber(term, this.model.sort),
        });
        this.workNumbers = onlyUniqItemsInRef(data.data.items.map(v => ({
          id: v.id,
          name: v.number || `n/a`,
        })))
      },
      onNewReport(reportItem) {
        if (this.loadingAlert) {
          this.$alert.success({
            title: `Отчет успешно сформирован`,
            onSuccessCb: () => {
              this.downloadReportFile(
                reportItem.id,
                reportItem.file_name,
              )
            }
          })
        }
      },
      async onCustomerIdChanged(customerId) {
        await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.customerDepartments(customerId),
          cache: Infinity,
        })

        this.formValidator.lockValidateField()

        // если одно подразделение - ставим его
        if (this.customersDepartments.length === 1) {
          this.model.customerDepartmentId = this.customersDepartments[0].id
        } else {
          this.model.customerDepartmentId = ""
        }

        this.formValidator.unlockValidateField()
        this.formValidator.delayedValidateField(`customerId`)
      },
      async onDepartmentIdChanged(departmentId) {
        this.formValidator.lockValidateField()

        if (!this.$route.params.id) {
          if (departmentId) {
            await Promise.all([
              this.findVehicles(),
            ])
          } else {
            this.vehicles = []
          }

          const existVeh = this.vehicles.find(v => v.id === this.model.vehicleId)
          if (!existVeh) {
            this.model.vehicleId = ``
          }
        }

        this.formValidator.unlockValidateField()
        this.formValidator.delayedValidateField(`customerDepartmentId`)
      },
      async onSubmit() {
        const validateResult = this.formValidator.validate()

        if (validateResult.hasErrors()) {
          return
        }

        this.loadingAlert = this.$alert.loading()

        try {
          const dateToEnd = moment(this.model.dateTo).endOf('day')

          await this.$store.dispatch(REST.actions.createModel, {
            url: reports.createReport(this.model.report_type),
            data: {
              data: {
                dateFrom: moment(this.model.dateFrom).format(),
                dateTo: dateToEnd.format(),
                customerId: this.model.customerId,
                customerDepartmentId: this.model.customerDepartmentId || undefined,
                vehicleId: this.model.vehicleId || undefined,
                status: this.model.status || undefined,
                workOrderId: this.model.workOrderId || undefined,
              },
            },
          })

          await new Promise(resolve => {
            setInterval(() => {
              if (this.reportProcessed) {
                resolve()
              }
            }, 50)
          })

          this.$emit(`close`)
        } catch (e) {
          this.$alert.jsError(e)
        } finally {
          this.loadingAlert.kill()
          this.loadingAlert = null
        }
      },
      async downloadReportFile(id, fileName) {
        let downloadError

        try {
          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            url: reports.downloadReport(id),
            params: {
              responseType: 'blob',
              disableGlobalError: true,
            }
          })

          saveAs(data, fileName)
        } catch (e) {
          downloadError = e
        }

        this.reportProcessed = true

        this.$notification.document({
          title: downloadError
            ? `Ошибка при загрузке ${fileName}`
            : `Документ ${fileName} загружен`,
          message: downloadError ? downloadError.toString() : `  `,
        })
      }
    },
  }
</script>

<style lang="scss">
  @import "../../../assets/saas/common";

  .build-report-modal {
    .t-modal-window {
      width: 400px;

      .t-modal-window__dialog__content {

        .t-modal-window__dialog__content__body {
          padding: 24px;
          min-height: 200px;
          max-height: calc(100vh - 20px);

          .t-form {
            padding: 0;

            .row {
              margin-top: -12px;
              margin-bottom: -12px;
            }

            input {
              background: $GrayG10;
              color: $GrayG100;
            }

            .t-form-actions {
              overflow: hidden;
              margin: 0;
              margin-top: 24px;
              justify-content: space-between;

              .t-button {
                width: 48%;
                margin: 0;

                &.t-button--primary {
                  &:disabled {
                    background: $GrayG100;
                    color: $WhiteW100;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
