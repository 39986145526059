import {captureMessage, withScope, captureException} from '@sentry/browser'

export function recognizeError(jsErrorOrAxiosResponse = {}) {
  const error = {
    name: 'Неизвестная ошибка',
    message: '',
    status: 0,
    additional: ''
  }

  const hasAxiosResponse = !!(jsErrorOrAxiosResponse && jsErrorOrAxiosResponse.response)

  if (hasAxiosResponse) {
    const {status, statusText, data} = jsErrorOrAxiosResponse.response
    error.status = +status
    error.name = statusText || jsErrorOrAxiosResponse.name

    if (
      // Server error format:
      // {"code":401,"message":"Invalid JWT Token"}
      data && data.message
    ) {
      error.message = data.message
    } else if (
      // Server error format:
      // {"type":"https:\/\/tools.ietf.org\/html\/rfc2616#section-10","title":"An error occurred","status":400,"detail":"Bad Request"}
      data && data.title
    ) {
      error.message = `${data.title} ${data.detail || ``}`
    } else if (
      // Server error format:
      // {"code":1,"errorMessage":"\u041d\u0435\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u043d\u0430\u044f \u0441\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 JSON \u0432\u0445\u043e\u0434\u043d\u044b\u0445 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u043e\u0432","data":null}
      // {"Code":1,"ErrorMessage":"\u041d\u0435\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u043d\u0430\u044f \u0441\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 JSON \u0432\u0445\u043e\u0434\u043d\u044b\u0445 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u043e\u0432","Data":null}
      data && (data.errorMessage || data.ErrorMessage)
    ) {
      error.message = `${data.code || data.Code || ``} ${data.errorMessage || data.ErrorMessage || ``}`.trim()
    }  else if (
      // Server error format:
      // Invalid JWT Token
      // or
      // <head><title>401 Authorization Required</title></head><body><center><h1>401 Authorization Required</h1></center><hr><center>nginx/1.17.5</center>
      data && data.toString().length > 0
    ) {
      error.message = data.toString().substring(0, 200).replace(/<[^>]*>?/gm, '')
    }
  } else {
    error.name = jsErrorOrAxiosResponse.name || error.name
    error.additional = jsErrorOrAxiosResponse.additional
    error.message = jsErrorOrAxiosResponse.message
  }

  if (error.message === 'Network Error') {
    error.name = 'Сетевая ошибка'
    error.message = 'Отсутствует интернет, сервер недоступен или проблемы с CORS'
  }

  if (jsErrorOrAxiosResponse.config && jsErrorOrAxiosResponse.config.url) {
    error.additional = jsErrorOrAxiosResponse.config.url
  }

  if (error.status !== 422) {

    // send exception to sentry
    withScope(scope => {

      scope.setExtra('recognized', error)

      if (hasAxiosResponse) {
        scope.setExtra('error', jsErrorOrAxiosResponse)

        // attach server response payload
        scope.setExtra('response.status', jsErrorOrAxiosResponse.response.status)
        scope.setExtra('response.headers', jsErrorOrAxiosResponse.response.headers)
        scope.setExtra('response.data', jsErrorOrAxiosResponse.response.data)

        captureMessage(`HTTP Error ${error.status || 0}`, 'debug')
      } else {

        captureException(jsErrorOrAxiosResponse)
      }

    })
  }

  return error
}
