<template>
  <div class="login-block">
    <div class="login-block__logo"></div>

    <div class="login-block__welcome">
      {{welcome}}
    </div>
    <div class="login-block__welcome-small">
      {{welcomeSmall}}
    </div>

    <div class="login-block__form">
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "login-block",
    props: {
      welcome: {
        type: String,
        default: '',
      },
      welcomeSmall: {
        type: String,
        default: '',
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../assets/saas/common";

  .login-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: $Sh_login;
    border-radius: 16px;
    width: 440px;
    background: $WhiteW100;
    padding: 30px;

    .login-block__logo {
      width: 100%;
      height: 30px;
      display: block;
      background: url('/external/logo.png') no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      top: -82px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .login-block__welcome {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: $BlueB100;
      margin: 0 42px;
    }

    .login-block__welcome-small {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $GrayG100;
      padding: 12px 0 5px;
      margin: 0 72px;
    }


    .login-block__form {
      .t-form {
        padding: 0;
        margin: 30px 0 0;

        .t-form-group {
          margin: 30px 0;
          position: relative;

          .t-form-group__invalid-text {
            border-radius: 0;
            background: transparent;
            position: absolute;
            top: 47px;
            left: 0;
            font-size: 10px;
            padding: 0 24px;
            margin: 0;
          }

          input {
            &::placeholder {
              color: $GrayG100;
            }
          }
        }
      }

      .t-button {
        width: 100%;
      }
    }
  }
</style>
