<template>
  <div class="t-file-uploader">
    <div
      class="t-file-uploader__upload"
      v-if="!disabled"
    >
      <t-button
        icon
        icon-type="attachment"
        design="primary"
      >
        <input
          type="file"
          @change="onChange"
          multiple
          :key="`${Math.random()}_${+new Date()}`"
        />
        Добавить документ
      </t-button>
    </div>
    <div
      class="t-file-uploader__files"
      v-if="value && value.length"
    >
      <t-file-uploaded
        v-for="(file, key) in value"
        :key="key"
        :file="file"
        :disabled="disabled"
        :enableDownload="enableDownload"
        :downloading-files="downloadingFiles"
        @removeFile="removeFile"
        @downloadFile="downloadFile"
      />
    </div>
    <div
      class="t-file-uploader__download-all"
      v-if="enableDownloadAll && value && value.length"
    >
      <t-button
        icon
        icon-type="download"
        @click="downloadAllFiles"
        design="primary"
        :loading="downloadingAll"
      >Скачать все файлы</t-button>
    </div>
  </div>
</template>

<script>
  import {baseFieldMixin} from "../mixins/base-field-mixin"
  import {FieldValidationResult} from "../utils/validator/FieldValidationResult";
  import {formatBytes} from "../../utils/string";

  export default {
    name: "t-file-uploader",
    mixins: [baseFieldMixin],
    data: () => ({
      downloadingAll: false,
      downloadingFiles: [],
    }),
    props: {
      value: {
        type: Array,
        default: () => ([])
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      enableDownload: {
        type: Boolean,
        default: false,
      },
      enableDownloadAll: {
        type: Boolean,
        default: false,
      },
      downloadMethod: {
        type: Function,
        default: async ({ file, files }) => {
          console.warn(`add handler for props downloadMethod`, { file, files })
          await new Promise(resolve => setTimeout(resolve, 900))
        }
      }
    },
    methods: {
      /**
       * Возвращает domElement например для фокусировки на нем
       * если валидация провалена
       * @returns {HTMLElement}
       */
      getElement () {
        const node = this.$el.getElementsByTagName('input')
        if (node) {
          return node[0]
        }
        return null
      },
      async downloadFile(file) {
        this.downloadingFiles.push(file)

        try {
          await this.downloadMethod({
            file,
          })
        } catch (e) {
          this.$notification.jsError(e)
        }

        this.downloadingFiles = this.downloadingFiles.filter(v => v !== file)
      },
      async downloadAllFiles() {
        this.downloadingAll = true

        try {
          await this.downloadMethod({
            files: this.value,
          })
        } catch (e) {
          this.$notification.jsError(e)
        }

        this.downloadingAll = false
      },
      onChange($event) {
        if (!($event.target.files && $event.target.files.length)) {
          return
        }

        const newFiles = []
        for (const file of $event.target.files) {
          newFiles.push(file)
        }

        this.$emit('input', [
          ...this.value,
          ...newFiles,
        ])

        this.validateFieldWithDelay()
      },
      removeFile(file) {
        this.$emit('input', this.value.filter(f => f !== file))

        this.validateFieldWithDelay()
      },
      /**
       * Вызывается formValidator и содержит логику по валидации
       * @returns {FieldValidationResult}
       * @override
       */
      validate () {
        const fieldValRes = new FieldValidationResult()
        fieldValRes.el = this.getElement()

        if (this.required) {
          if (!(Array.isArray(this.value) && this.value.length > 0)) {
            fieldValRes.error = {
              title: `Не заполненное поле`,
              message: `Необходимо загрузить минимум 1 файл`
            }
          }
        }

        if (Array.isArray(this.value) && !fieldValRes.error) {
          for (const file of this.value) {
            if (this.validationRule.max !== null && file.size > this.validationRule.max) {
              fieldValRes.error = {
                title: `Нельзя загружать файлы больше ${formatBytes(this.validationRule.max, 1)}`,
                message: `${file.name} превысил максимально допустимый размер в ${formatBytes(this.validationRule.max)} на ${formatBytes(file.size - this.validationRule.max)}`
              }
            }
          }
        }

        this.emmitValidState(fieldValRes)

        return fieldValRes
      },
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-file-uploader {
    font-family: $fontFamily;
    margin: 20px 0;
    letter-spacing: -0.4px;

    .t-file-uploader__upload {
      .t-button {
        position: relative;
        font-size: 14px;
        font-weight: bold;

        input[type=file] {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .t-file-uploader__files {
      background: $UploadBG;
      border-radius: 16px;
      display: flex;
      flex-wrap: wrap;
      padding: 10px 10px;
      margin: 20px 0;
    }

    .t-file-uploader__download-all {
      margin-top: -8px;
      margin-bottom: 24px;

      .t-button {
        font-size: 14px;
        padding: 0 12px;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-file-uploader
        v-model="files"
        name="files"
      ></t-file-uploader>

      <t-file-uploader
        v-model="files"
        name="files"
        enable-download
      ></t-file-uploader>
    </div>
  </template>
  <script>
    export default {
      data: () => ({
        files: [],
      })
    }
  </script>
  ```
</docs>
