import {DEFAULT_REDIRECT} from '../configs/config'
import {getRouteCopy} from '../utils/url'

export const loginMixin = {
  methods: {
    logoutAndGoToLoginPage () {
      // we need remove all auth data for user can view login page
      this.$store.commit('userDelAuthData')

      const {loginPage} = DEFAULT_REDIRECT

      if (this.$route.name !== loginPage.name) {
        // save redirect path
        const redirectUrl = this.$router.history.pending
          ? getRouteCopy(this.$router.history.pending)
          : getRouteCopy(this.$route)
        this.$store.commit('userSetRedirectUrl', redirectUrl)

        this.$router.push(loginPage, () => {
          this.$store.commit('mutateError', null)
        })
      } else {
        this.$store.commit('mutateError', null)
      }
    },
    loginAndGoToPrevOrMainPage (authData = {}, dontSaveInStorage = false) {
      this.$store.commit('userSetAuthData', {authData, dontSaveInStorage})
      this.$store.commit('authStatus', true)
      this.goToPrevOrMainPage()
    },
    goToPrevOrMainPage () {
      this.$router.push(this.$store.getters.redirectUrl, () => {})
    }
  }
}
