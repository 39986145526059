<template>
  <section
    class="t-page-layout-article"
  >
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: 't-page-layout-article',
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-page-layout-article {
    font-family: $fontFamily;
    padding: 24px;

    background: $WhiteW100;
    box-shadow: $Sh_1;
    border-radius: 16px;
  }
</style>

<docs>
  ```vue
  <template>
    <t-page-layout-article>
      param pam pam
    </t-page-layout-article>
  </template>
  ```
</docs>
