<template>
  <div :class="[
    `t-color-progress`,
    `t-color-progress--size-${size}`,
    `t-color-progress--${design}`,
    ]">
    <div
      class="t-color-progress__title"
      :style="{
         'color': design === `colored-inline` ? selectedOption.color : undefined,
      }"
    >
      {{selectedOption.name}}
    </div>
    <div class="t-color-progress__bar">
      <div
        class="t-color-progress__bar__fill"
        :style="{
          'background-color': selectedOption.color,
          'width': `${selectedOption.progress}%`,
        }"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-color-progress",
    props: {
      title: {
        type: String,
        default: ``,
      },
      size: {
        type: String,
        default: `normal`,
        validator: function (value) {
          return [
            `normal`,
            `small`,
          ].includes(value)
        },
      },
      options: {
        type: Array,
        default: () => [],
        required: true,
      },
      optionId: {
        type: [String, Number],
        default: `n/a`,
      },
      design: {
        type: String,
        default: `block`,
        validator: function (value) {
          return [
            `block`,
            `colored-inline`,
          ].includes(value)
        },
      }
    },
    computed: {
      selectedOption() {
        return this.options.find(v => v.id == this.optionId) || {
          name: this.optionId || `n/a`,
          color: `gray`,
          progress: 0,
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-color-progress {
    width: 100%;
    font-family: $fontFamily;

    .t-color-progress__bar {
      background: $BlackB10;
      border-radius: 5px;
      height: 15px;
      width: 100%;

      .t-color-progress__bar__fill {
        border-radius: 5px;
        height: 100%;
      }
    }

    &.t-color-progress--size-small {
      .t-color-progress__bar {
        height: 6px;
      }
    }

    &.t-color-progress--colored-inline {
      display: flex;
      align-items: center;

      .t-color-progress__title {
        font-size: 12px;
        color: $GreenG100;
        padding-right: 16px;
        font-weight: 600;
      }
    }

    &.t-color-progress--block {
      .t-color-progress__title {
        margin-bottom: 5px;
      }

      .t-color-progress__bar {
        border-radius: 20px;
      }

      .t-color-progress__bar__fill {
        border-radius: 20px;
      }
    }
  }
</style>
