<template>
  <div
    class="t-file-uploaded"
    :class="`t-file-uploaded--type-${getFileIcon(file)}`"
  >
    <div class="t-file-uploaded__icon">
      <t-icon :name="getFileIcon(file)"></t-icon>
    </div>
    <div class="t-file-uploaded__details">
      <b :title="file.name">{{file.name || ` - `}}</b>
      <small>{{file.size | formatBytes}}</small>
    </div>
    <div
      class="t-file-uploaded__remove"
      v-if="!disabled"
    >
      <t-button
        icon
        icon-type="close"
        transparent
        @click="$emit(`removeFile`, file)"
      />
    </div>
    <div
      class="t-file-uploaded__download"
      v-if="enableDownload"
    >
      <t-button
        icon
        :icon-type="downloadingFiles.includes(file) ? `loadingRing` : `download`"
        transparent
        @click="$emit(`downloadFile`, file)"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-file-uploaded",
    props: {
      file: {},
      disabled: {
        type: Boolean,
        default: true,
      },
      enableDownload: {
        type: Boolean,
        default: false,
      },
      downloadingFiles: {
        type: Array,
        default: () => ([])
      },
    },
    methods: {
      getFileIcon(file) {
        if (file.name) {
          const ext = file.name.split('.').pop().toLowerCase().trim()

          if ([
            `jpg`,
            `jpeg`,
            `png`,
            `gif`,
            `bmp`,
          ].includes(ext)) {
            return `capture`
          } else if ([
            `text`,
            `txt`,
            `doc`,
            `docx`,
            `xls`,
            `xlsx`,
          ].includes(ext)) {
            return `report`
          }
        }
        return `file`
      },
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-file-uploaded {
    border-radius: 16px;
    background: $BlueB05;
    padding: 8px;
    margin: 10px 10px;

    display: flex;

    &.t-file-uploaded--type-capture {
      background: $GreenG10;

      .t-file-uploaded__icon {
        color: $GreenG100;
        background: $GreenG20;
      }
    }

    &.t-file-uploaded--type-report {
      background: $YellowY10;

      .t-file-uploaded__icon {
        color: $YellowY100;
        background: $YellowY20;
      }
    }

    .t-file-uploaded__icon {
      width: 40px;
      height: 40px;
      background: $BlueB10;
      mix-blend-mode: normal;
      border-radius: 12px;
      margin-right: 12px;
      padding: 7px 9px;
      color: $BlueB100;
    }

    .t-file-uploaded__details {
      width: 104px;

      > b {
        display: block;
        color: $BlackB100;
        font-weight: bold;
        font-size: 14px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > small {
        display: block;
        color: $GrayG100;
        font-weight: 500;
        font-size: 12px;
      }
    }

    .t-file-uploaded__remove {
      width: 40px;
    }

    .t-file-uploaded__download {
      width: 40px;

      .t-icon--loadingRing {
        margin-top: 6px;
        color: $BlueB40;
      }
    }
  }
</style>
