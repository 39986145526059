<template>
  <div
    class="support support-info"
  >
    <t-round-block>
      <ul>
        <li
          v-for="(item, key) in itemData.data"
          :key="key"
        >
          <a :href="item.url" target="_blank">{{ item.title }}</a>
        </li>
        <li>Телефон технической поддержки - <a href="tel:88002227511">8 800 222 75 11</a></li>
      </ul>
    </t-round-block>
  </div>
</template>

<script>
import {REST} from "vue-fast-rest";
import {user} from "@/api/endpoints";

export default {
  name: "Info",
  computed: {
    itemData() {
      return this.$store.getters[REST.getters.readUrlEndpoint](
        user.supportDocs(),
      )
    },
  },
  async asyncData({store}) {
    await store.dispatch(REST.actions.updateUrlEndpoint, {
      endpoint: user.supportDocs(),
    })
  }
}
</script>

<style lang="scss">
.support.support-info {
  ul {
    margin-bottom: 0;
  }
}
</style>
