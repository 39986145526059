<template>
  <div class="wss"></div>
</template>

<script>
  import Centrifuge from 'centrifuge'
  import {formatFullDate, getNotifyTag} from "../../utils/string";
  import {REST} from "vue-fast-rest";
  import {repairRequests, user, workOrders} from "../../api/endpoints";
  import {replacePlaceholders} from "../../utils/helpers";

  export default {
    name: "wss",
    mounted() {
      this.connect()
      window.notify = this.notify
    },
    destroyed() {
      this.disconnect()
      window.notify = null
    },
    methods: {
      /**
       * @param payload {
    "event": "appl_form.in_work",
    "data": {
        "title": "Информирование по Заявке на ремонт № СИМ000217",
        "message": "Заявка на ремонт переведена в статус: Передана контрагенту от 27.08.2021 09:53",
        "creationDate": "2021-08-27T10:55:15+00:00",
        "meta": {
            "appl_form_id": "201",
            "appl_form_number": "СИМ000217",
            "appl_form_status": "3"
        }
    }
}
       * @param payload {
 "data": {
  "event": "work_order.need_approve",
  "data": {
   "title": "Информирование по Заказ-наряду № СИМ193957",
   "message": "Направлен на согласование от 03.09.2021 13:36.",
   "creationDate": "2021-09-03T13:36:21+00:00",
   "meta": {
    "work_order_id": "42",
    "work_order_number": "СИМ193957",
    "work_order_status": "1"
   }
  }
 }
}
       */
      notify(payload) {
        this.$store.dispatch(`increaseUnreadCount`)

        const { data, event } = payload.data
        const { creationDate, title, message, meta = {} } = data

        this.$emitter.emit('notifications.new', data)

        let actions
        let currentNotify
        const vm = this

        if (meta.appl_form_id) {
          actions = {
            template: `<t-button @click="onClick">Перейти к заявке</t-button>`,
            methods: {
              onClick() {
                currentNotify.close()
                vm.$router.push({
                  name: `requests.requestView`,
                  params: { id: meta.appl_form_id },
                }, () => ``, () => ``)
              }
            }
          }

          const sentData = {
            id: Number(meta.appl_form_id),
            number: meta.appl_form_number || ``,
            status: Number(meta.appl_form_status),
          }

          this.$emitter.emit('repairRequest.updateModel', sentData)
          this.updateListData(repairRequests.ordersListStartPrefix(), sentData)
        }
        if (meta.work_order_id) {
          actions = {
            template: `<t-button @click="onClick">Перейти к заказ наряду</t-button>`,
            methods: {
              onClick() {
                currentNotify.close()
                vm.$router.push({
                  name: `orders.orderView`,
                  params: { id: meta.work_order_id },
                }, () => ``, () => ``)
              }
            }
          }

          const sentData = {
            id: Number(meta.work_order_id),
            number: meta.work_order_number || ``,
            status: Number(meta.work_order_status),
          }

          this.$emitter.emit('order.updateModel', sentData)
          this.updateListData(workOrders.workOrdersListStartPrefix(), sentData)
        }

        if (event === `report.done`) {
          const sentData = {
            id: Number(meta.documentId),
            create_dt: meta.create_dt,
            meta,
            file_size: meta.fileSize || 0,
            file_name: meta.fileName || `report-${meta.documentId}.xlsx`,
          }

          this.$emitter.emit('report.newModel', sentData)
        }

        if (event === `avr.created`) {
          const sentData = {
            id: meta.avrId,
            documentDate: meta.documentDate,
            meta,
          }

          this.$emitter.emit('acts.new', sentData)
        }

        currentNotify = this.$notification.document({
          title: replacePlaceholders(title, meta),
          message: replacePlaceholders(message, meta),
          time: formatFullDate(creationDate),
          actions,
          tag: getNotifyTag(meta.type),
          onRead: async () => {
            await vm.$store.dispatch(`markAsRead`, {
              emitter: vm.$emitter,
              id: meta.id
            })
          }
        })
      },
      connect() {
        if (this.centrifuge) {
          this.disconnect()
        }

        this.centrifuge = new Centrifuge(this.$store.getters.userProfile.wssDsn, {
          sockjsTransports: ["websocket", "xhr-streaming"],
          onRefresh: async (ctx, cb) => {
            await this.updateSelfInfo()

            cb({
              status: 200,
              data: {
                token: this.$store.getters.userProfile.notificationsToken
              },
            })
          },
        })
        this.centrifuge.setToken(this.$store.getters.userProfile.notificationsToken)

        this.centrifuge.subscribe(`events#${this.$store.getters.userProfile.id}`, (message) => {
          this.notify(message)
        })

        this.centrifuge.on('error', (context) => {
          console.error('error', context);
          this.$notification.jsError(context)
        })

        this.centrifuge.on('connect', (context) => {
          console.log('Connected...', context);
        })

        this.centrifuge.connect()
      },
      disconnect() {
        if (!this.centrifuge) {
          return
        }

        this.centrifuge.disconnect()
        this.centrifuge = undefined
      },
      async updateSelfInfo() {
        // получение свежих данных по юзеру
        const {data: { data: selfInfo }} = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: user.selfInfo(),
        })
        // проставка свежих данных
        this.$store.commit('userSetAuthData', {
          authData: {
            user: selfInfo,
          },
          dontSaveInStorage: false,
        })
      },
      updateListData(url, data) {
        for (const endpoint in this.$store.state.rest.endpoints) {
          if (endpoint.startsWith(url)) {
            const value = JSON.parse(JSON.stringify(this.$store.state.rest.endpoints[endpoint]))

            if (value.data && value.data.items) {
              for (const item of value.data.items) {
                if (item.id === data.id) {
                  for (const key in data) {
                    item[key] = data[key]
                  }
                }
              }

              this.$store.commit(REST.mutations.updateEndpoint, {
                endpoint,
                response: {
                  data: value,
                }
              })
            }
          }
        }
      },
    }
  }
</script>
