// As of Babel 7.4.0, this package has been deprecated in
// favor of directly including core-js/stable (to polyfill ECMAScript features)
// and regenerator-runtime/runtime (needed to use transpiled generator functions)
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// for ie 10, fix undefined api url
import "location-origin"
// for ie, add new URL()
import "url-polyfill"
// for ie 11
import 'custom-event-polyfill'
import 'path2d-polyfill'
import 'element-matches-polyfill'

import Vue from 'vue'
import App from './app/main/App.vue'
import router from './router'
import store from './store'

window.store = store

import {getDeviceId} from './utils/random'
import {SENTRY_DSN} from './configs/config'
// интгерация сервиса отслежвания ошибок sentry.io {@link https://github.com/getsentry/sentry}
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
if (SENTRY_DSN && process.env.NODE_ENV === 'production' && !process.env.VUE_APP_IS_E2E_TEST) {
  // eslint-disable-next-line
  console.debug('Sentry enabled', process.env.NODE_ENV)
  Sentry.init({
    release: process.env.VUE_APP_RELEASE,
    environment: process.env.NODE_ENV || 'development',
    dsn: SENTRY_DSN,
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  })
  Sentry.configureScope(scope => {
    scope.setTag('deviceId', getDeviceId())
  })
}

import {stripHtml, cut} from './filters/string'
import {formatBytes, formatDate, formatFullDate, fullName, fullNameWithPosition, getNotifyTag} from "./utils/string";
import {numberFormat} from "./filters/number";

Vue.filter('stripHtml', stripHtml)
Vue.filter('cut', cut)
Vue.filter('formatBytes', formatBytes)
Vue.filter('formatFullDate', formatFullDate)
Vue.filter('formatDate', formatDate)
Vue.filter('fullNameWithPosition', fullNameWithPosition)
Vue.filter('fullName', fullName)
Vue.filter('numberFormat', numberFormat)
Vue.filter('getNotifyTag', getNotifyTag)

import apiClient from './api/ApiClient'
import {plugin} from 'vue-fast-rest'
Vue.use(plugin, { store, restClient: apiClient })

// глобальный event bus
import VueGlobalEmitter from 'vue-global-emitter'
Vue.use(VueGlobalEmitter, { debug: false })

import {handleBeforeResolve, handleBeforeEach} from "./utils/routerHandlers"
import {handleRenderError, handleRouteError} from "./utils/errorHandlers"
import {routerMixin} from "./mixins/router-mixin"
import {titleMixin} from "./mixins/title-mixin"

import {recognizeError} from './utils/recognizeError'

// точка входа всех стилей
// import './assets/styles/main.scss'

// прогресс бар показываемый при загрузке данных
import {createBar} from "./components/index"
const bar = createBar(Vue)

// загрузка переиспользуемых компонентов в глобальную область видимости
import GlobalComponents from './components/index.js'
Vue.use(GlobalComponents, { recognizeError })

// установка хуков на роутер
Vue.mixin(routerMixin)
router.beforeResolve((to, from, next) => handleBeforeResolve({to, from, next}, {router, store, bar}))
router.onError(error => handleRouteError({ error, router, store, bar }))
router.beforeEach((to, from, next) => handleBeforeEach(to, from, next, store))

// проставка заголовка страницы
Vue.mixin(titleMixin)

// утсанвока параметров вуе
Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  // сохраняем старый обработчик, потмоу что его использует уже sentry
  const oldErrHandler = Vue.config.errorHandler
  Vue.config.errorHandler = function (err, vm, info) {
    oldErrHandler && oldErrHandler.call(this, err, vm, info)
    handleRenderError({ err, vm, info, store })
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
