const isQueryParamCantSerialize = (key, val) => {
  const isPrivate = key.startsWith('_')
  const isUndefined = val === "undefined" || typeof val === "undefined"
  return isPrivate === true || isUndefined === true
}

/**
 * @source https://github.com/axios/axios/blob/097948698a057235140cc275504c12d5d20281b8/lib/helpers/buildURL.js#L5
 * @param val
 * @returns {string}
 */
function encode (val) {
  return encodeURIComponent(val).
  replace(/%40/gi, '@').
  replace(/%3A/gi, ':').
  replace(/%24/g, '$').
  replace(/%2C/gi, ',').
  replace(/%20/g, '+').
  replace(/%5B/gi, '[').
  replace(/%5D/gi, ']')
}
/**
 *
 * Преващает this.$route в query params
 *
 * @param route
 * @returns {string}
 */
export function buildURLQueryFromRoute (route = {}) {
  const { query = {} } = route

  const parts = []
  for (let key in query) {
    if (isQueryParamCantSerialize(key, query[key]) === true) {
      continue
    }

    if (Array.isArray(query[key])) {
      query[key].forEach(v => {
        if (v) {
          parts.push(key + '[]=' + encode(v))
        }
      })
    } else if (typeof query[key] === 'object') {
      for (let objKey in query[key]) {
        if (isQueryParamCantSerialize(objKey, query[key][objKey]) === true) {
          continue
        }

        if (query[key][objKey]) {
          parts.push(key + '[' + objKey + ']=' + encode(query[key][objKey]))
        }
      }
    } else {
      if (query[key]) {
        parts.push(key + '=' + encode(query[key]))
      }
    }
  }

  const url = parts.join('&')

  return url.length > 0 ? '?' + url : ''
}

/**
 *
 * Создает копию роута, вырезав из this.$route лишине данные
 * удобно для сериализации или если нужна копия
 *
 * @param route
 * @returns {{query: any, name: string, params: any}}
 */
export function getRouteCopy (route = {}) {
  let { name = '', params = {}, query = {} } = route
  return {
    name,
    params: Object.assign({}, params),
    query: Object.assign({}, query)
  }
}

export function getOnlyPrefixedValues(prefix = '', obj = {}) {
  const out = {}
  for (const key of Object.keys(obj)) {
    const startedKeyName = `${prefix}.`
    if (key.startsWith(startedKeyName)) {
      const stripedKeyName = key.replace(startedKeyName, '')
      out[stripedKeyName] = obj[key]
    }
  }
  return out
}

export function removeItemsWithPrefixFromObject(prefix = '', obj = {}) {
  const out = {}
  for (const key of Object.keys(obj)) {
    const startedKeyName = `${prefix}.`
    if (!key.startsWith(startedKeyName)) {
      out[key] = obj[key]
    }
  }
  return out
}
