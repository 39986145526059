import {Base64} from 'js-base64';

import {NOTIFICATIONS_META_TYPES} from "../configs/config";

export function depersonalize (str) {
  if (!str) {
    str = 'unknown'
  }
  return str.toString().substr(0, str.length / 2) + '***'
}

export function formatBytes(bytes, decimals = 1, k = 1024) {
  if (!bytes) return '0 Байт';

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatDate(dt = ``) {
  return new Date(dt).toLocaleDateString(`ru-RU`)
}

export function formatTime(dt = ``) {
  return new Date(dt).toLocaleTimeString(`ru-RU`).split(`:`).slice(0, 2).join(`:`)
}

export function formatFullDate(dt = ``) {
  return `${formatDate(dt)} ${formatTime(dt)}`
}

export function fullName(us = {}) {
  return [
    us.surname,
    us.name,
    us.patronymic,
  ].filter(v => v).join(` `)
}

export function fullNameWithPosition(us = {}) {
  return [
    us.surname,
    us.name,
    us.patronymic,
    us.position
  ].filter(v => v).join(` `)
}

export function getNotifyTag(type) {
  const tag = NOTIFICATIONS_META_TYPES.find(v => v.id === type)
  if (tag) {
    return tag.name
  }

  return type
}

export function base64decode(encoded) {
  return Base64.decode(encoded)
}

export function decodeJWTtoObject(jwt) {
  const [, b64payload] = jwt.split(`.`)

  return JSON.parse(base64decode(b64payload))
}
