<template>
  <div
    class="admin-logged-as-user-warning"
    v-if="ADMIN_LOGGED_AS_USER_USER_DATA"
  >
    <div class="admin-logged-as-user-warning__content">
      Просмотр от пользователя {{ADMIN_LOGGED_AS_USER_USER_DATA.user.name}}
      {{$store.getters.isGuest ? 'Срок действия токена истек.' : ''}}
    </div>
  </div>
</template>

<script>
  import {ADMIN_LOGGED_AS_USER_USER_DATA} from "../../configs/config"

  export default {
    name: "admin-logged-as-user-warning",
    computed: {
      ADMIN_LOGGED_AS_USER_USER_DATA () {
        return ADMIN_LOGGED_AS_USER_USER_DATA
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .admin-logged-as-user-warning {
    z-index: $adminLoggedAsUserWarnZIndex;

    fontFamily: $fontFamily;
    font-size: 14px;

    position: fixed;
    top: 0;
    background-color: black;
    color: white;
    padding: 0 16px;
    width: 100%;
  }
</style>
