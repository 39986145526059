<template>
  <div
    class="t-tag"
    :class="{
      't-tag--disabled': disabled,
    }"
    @click="$emit(`click`, $event)"
  >
    <div>
      {{name}}
    </div>
    <div
      v-if="!disabled"
      @click.stop.prevent="$emit(`remove`)"
    >
      <t-icon name="close"></t-icon>
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-tag",
    props: {
      name: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-tag {
    color: $BlueB100;
    background: $BlueB10;
    display: flex;
    padding: 8px 12px;
    border-radius: 16px;
    margin: 4.5px 16px;
    margin-right: 0;
    font-size: 14px;
    font-family: $fontFamily;
    font-weight: bold;

    .t-icon {
      color: $RedR100;
      padding-left: 6px;
      cursor: pointer;

      svg {
        height: 12px;
        width: auto;
      }
    }

    &.t-tag--disabled {
      color: $BlackB60;
      background: $GrayG20;
    }
  }
</style>
