<template>
  <t-popup-block
    class="t-confirmation"
  >
    <template slot="title">
      {{title}}
    </template>
    <template slot="message">
      {{message}}
    </template>
    <template slot="actions">
      <t-button
        @click="onReject"
        design="secondary-red"
      >
        {{no}}
      </t-button>
      <t-button
        @click="onConfirmation"
        type="submit"
        design="primary"
      >
        {{yes}}
      </t-button>
    </template>
  </t-popup-block>
</template>

<script>
  import TPopupBlock from "../block/popup-block";

  export default {
    name: "t-confirmation",
    components: {TPopupBlock},
    props: {
      title: {
        type: String,
        default: `Вы уверены что хотите сделать это?`
      },
      message: {
        type: String,
        default: ``
      },
      yes: {
        type: String,
        default: `Да`,
      },
      no: {
        type: String,
        default: `Нет`,
      }
    },
    methods: {
      onConfirmation() {
        this.promise.resolve()
      },
      onReject() {
        this.promise.reject()
      },
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-confirmation {

  }
</style>

<docs>
  ```vue
  <template>
    <p>
      <t-button @click="showconfirmation">
        showconfirmation
      </t-button>

      <t-button @click="showconfirmationWithDesc">
        showconfirmationWithDesc
      </t-button>
    </p>
  </template>

  <script>
    export default {
      methods: {
        async showconfirmation() {
          try {
            await this.$confirmation({
              title: `Создать заявку на ремонт?`,
              yes: `Создать`,
              no: `Отменить`
            })
          } catch (e) {
            console.warn(`no`)
          }
        },
        async showconfirmationWithDesc() {
          try {
            await this.$confirmation({
              title: `Согласовать?`,
              message: `Согласовать заказ-наряд и отправить исполнителю`,
              yes: `Отправить`,
              no: `Отменить`
            })
          } catch (e) {
            console.warn(`no`)
          }
        }
      }
    }
  </script>
  ```
</docs>
