export function numberFormat(number = 0) {
  const [int, exp] = parseFloat(number).toFixed(2).toString().split(`.`)
  const intRev = int.split(``).reverse()

  const str = []
  for (let i = 0; i < intRev.length; i++) {
    str.push(intRev[i])
    if ((i + 1) % 3 === 0) {
      str.push(` `)
    }
  }

  return [str.reverse().join(``), exp].join(`,`)
}
