<template>
  <el-tooltip
    :disabled="disabled"
    :content="content"
    popper-class="t-tooltip"
  >
    <slot/>
  </el-tooltip>
</template>

<script>
  export default {
    name: "t-tooltip",
    props: {
      disabled: {
        type: Boolean,
        default: true,
      },
      content: {
        type: String,
        default: ``,
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-tooltip {
    font-size: 14px;
    font-family: $fontFamily;
  }
</style>
