import {isFormValidatorOrEmpty} from "../utils/validator/props-validators"
import {FieldValidationResult} from "../utils/validator/FieldValidationResult"

export const baseFieldMixin = {
  data: () => ({
    isFocused: false,
  }),
  props: {
    subType: {
      type: String,
      default: 'text'
    },
    value: null,
    formValidator: {
      validator: isFormValidatorOrEmpty
    },
    name: {
      type: String,
      default: null,
      required: true
    },
    nameAdditional: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: null
    },
    validationSuccess: {
      type: Boolean,
      default: null
    },
    selectDateType: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    valueFormat: {
      type: String,
    },
    hideClearIcon: {
      type: Boolean,
      default: false
    },
    transformEmitValue: {
      type: Function
    },
    leftIcon: {
      type: String,
      default: '',
    },
    hasLabel: {
      type: Boolean,
      default: false,
    },
    behavior: {
      type: String,
      default: ``,
    },
    size: {
      type: String,
      default: `normal`
    },
    hideReqDot: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null
    },
    validationRule: {
      type: Object,
      default: () => ({
        // string|email|number|double
        type: `string`,
        min: null,
        max: null,
      })
    },
    label: {
      type: String,
    },
    customEnterReaction: {
      type: Boolean,
    }
  },
  created () {
    if (this.formValidator) {
      this.formValidator.subscribeField(this, this.name)
      this.nameAdditional && this.formValidator.subscribeField(this, this.nameAdditional)
    }
  },
  destroyed () {
    if (this.formValidator) {
      this.formValidator.unsubscribeField(this.name)
      this.nameAdditional && this.formValidator.unsubscribeField(this.nameAdditional)
    }
  },
  computed: {
    materialFocused() {
      return this.isFocused && this.behavior === `material`
    },
  },
  methods: {
    lostFocus() {
      this.isFocused = false
    },
    onFocus() {
      this.isFocused = true
    },
    /**
     * Возвращает domElement например для фокусировки на нем
     * если валидация провалена
     * @returns {HTMLElement}
     */
    getElement () {
      return this.$el
    },
    /**
     * Вызывается formValidator и содержит логику по валидации
     * @returns {FieldValidationResult}
     */
    validate () {
      const fieldValRes = new FieldValidationResult()
      fieldValRes.el = this.getElement()

      if (this.required && !this.value) {
        fieldValRes.error = this.formatErrorObject(
          `Не заполненное поле`,
          `Необходимо ввести ${this.placeholder || this.label || `значение`}`,
        )
      }

      this.emmitValidState(fieldValRes)

      return fieldValRes
    },
    emmitValidState (fieldValRes) {
      if (fieldValRes.error) {
        this.$emit('fieldInvalid')
      } else {
        this.$emit('fieldValid')
      }
    },
    validateField () {
      if (this.formValidator) {
        this.formValidator.validateField(this.name)
      }
    },
    validateFieldWithDelay (delay = 50) {
      if (this.validateTimer) {
        clearTimeout(this.validateTimer)
      }
      this.validateTimer = setTimeout(() => this.validateField(), delay)
    },
    onSelectChange(v) {
      if (this.options) {
        this.$emit(`change`, {
          value: v,
          option: this.options.find(o => o.id == v)
        })
      } else {
        this.$emit(`change`, { value: v })
      }
    },
    formatErrorObject(title, message) {
      if (this.behavior && this.behavior === `material`) {
        return title
      }
      return {
        title,
        message,
      }
    }
  }
}
