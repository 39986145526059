<template>
  <div
    class="t-form-group"
    :class="{
      't-form-group--no-margin': noMargin,
      't-form-group--invalid': error,
      't-form-group--with-label': hasLabel
    }"
  >
    <t-tooltip
      :disabled="Boolean(!required && (label || placeholder))"
      content="Поле обязательно для заполнения"
    >
      <label
        v-if="label || $slots.label"
        :for="inputId"
        class="t-form-group__label"
      >
        <slot name="label">
          {{label}}<div class="t-form-group__label__mark" v-if="hasLabel && required">*</div>
        </slot>
      </label>
    </t-tooltip>
    <slot>
      <component
        :is="componentNameRecognizedByType"
        :hasLabel="hasLabel"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event)"
        :required="required"
        :disabled="disabled"
        :name="name"
        :nameAdditional="nameAdditional"
        :formValidator="getValidator()"
        :hasError="!!error"
        :validationSuccess="validationSuccess"
        :subType="subType"
        :options="options"
        :selectDateType="selectDateType"
        :format="format"
        :valueFormat="valueFormat"
        @techError="onTechError"
        @error="onError"
        :remoteMethod="remoteMethod"
        :remote="remote"
        :filterMethod="filterMethod"
        :id="inputId"
        @fieldInvalid="$emit('fieldInvalid', $event)"
        @fieldValid="$emit('fieldValid', $event)"
        :hideClearIcon="hideClearIcon"
        :transformEmitValue="transformEmitValue"
        ref="field"
        :behavior="behavior"
        :size="size"
        :hideReqDot="hideReqDot"
        :leftIcon="leftIcon"
        @change="$emit('change', $event)"
        :mask="mask"
        :validationRule="validationRule"
        :addTagLabel="addTagLabel"
        :label="label"
        :enableDownload="enableDownload"
        :downloadMethod="downloadMethod"
        :greaterThanValue="greaterThanValue"
        :lessThanValue="lessThanValue"
        :customEnterReaction="customEnterReaction"
        @enter="$emit('enter', $event)"
      />
    </slot>
    <div
      class="t-form-group__invalid-text"
      v-if="techError"
    >{{techError}}</div>
    <div
      class="t-form-group__invalid-text"
      v-if="error"
    >
      <template v-if="error.title">
        <b>{{error.title}}</b>
        {{error.message}}
      </template>
      <template v-else>
        {{error}}
      </template>
    </div>
    <small
      v-if="help"
      class="t-form-group__help-text"
    >{{help}}</small>
  </div>
</template>

<script>
  import {isFormValidatorOrEmpty} from '../utils/validator/props-validators'
  import {findParentComponentByName} from '../utils/helpers'

  export default {
    name: "t-form-group",
    data: () => ({
      error: null,
      techError: null,
      validationSuccess: null
    }),
    props: {
      value: null,
      formValidator: {
        validator: isFormValidatorOrEmpty
      },
      name: {
        type: String,
        default: null,
        required: true
      },
      nameAdditional: {
        type: String
      },
      label: {
        type: String,
        default: null
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      help: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: null
      },
      type: {
        type: String,
        default: 'input'
      },
      subType: {
        type: String,
        default: 'text'
      },
      options: {
        type: Array
      },
      selectDateType: {
        type: String
      },
      format: {
        type: String
      },
      valueFormat: {
        type: String
      },
      noMargin: {
        type: Boolean,
        default: false
      },
      remoteMethod: {
        type: Function
      },
      remote: {
        type: Boolean
      },
      filterMethod: {
        type: Function,
      },
      hideClearIcon: {
        type: Boolean,
        default: true
      },
      transformEmitValue: {
        type: Function
      },
      behavior: {
        type: String,
        default: ``,
      },
      size: {
        type: String,
        default: `normal`
      },
      hideReqDot: {
        type: Boolean,
        default: false,
      },
      leftIcon: {
        type: String,
        default: ``,
      },
      mask: {
        type: String,
        default: null
      },
      validationRule: {
        type: Object,
        default: () => null,
      },
      addTagLabel: {
        type: String,
      },
      enableDownload: {
        type: Boolean,
        default: false,
      },
      downloadMethod: {
        type: Function,
      },
      greaterThanValue: {
        validator: () => true,
      },
      lessThanValue: {
        validator: () => true,
      },
      customEnterReaction: {
        type: Boolean,
      }
    },
    computed: {
      inputId () {
        return `input-name-${this.name}`
      },
      componentNameRecognizedByType () {
        return `t-${this.type}`
      },
      form () {
        return findParentComponentByName(this, 't-form')
      },
      hasLabel () {
        return Boolean(this.label || this.$slots.label)
      }
    },
    methods: {
      getField() {
        return this.$refs.field
      },
      onError (error) {
        this.error = error
        this.validationSuccess = !error
      },
      getValidator () {
        return this.formValidator || this.form.formValidator
      },
      onTechError (e) {
        const err = this.$tServices.recognizeError(e)
        this.techError = `${err.name} ${err.message}`
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-form-group {
    margin: 17px 0;

    .t-form-group__label {
      font-family: $fontFamily;
      font-weight: bold;
      font-size: 10px;
      letter-spacing: 0.833333px;
      text-transform: uppercase;
      color: $BlackB60;
      margin-bottom: 4px;

      .t-form-group__label__mark {
        display: inline-block;
        color: $RedR100;
      }
    }

    &.t-form-group--with-label {
      margin: 11px 0;
    }

    &.t-form-group--no-margin {
      margin: 0;
    }

    .t-form-group__invalid-text {
      font-family: $fontFamily;
      color: $Error;
      border-radius: 16px;
      padding: 12px;
      font-size: 13px;
      background: $ErrorBg;
      margin: 17px 0;

      b {
        display: block;
        font-size: 16px;
        margin-bottom: 6px;
      }
    }

    .t-form-group__help-text {
      font-size: 13px;
      font-style: italic;
    }
  }
</style>
