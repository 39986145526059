<template>
  <t-page-layout-article
    title="Dev setting"
    :onBackRedirectToRoute="{path: '/'}"
  >
    <div class="row">
      <div class="col-md-6">
        <t-form
          @submit="onSubmit"
          :formValidator="formValidator"
          name="dev-settings"
        >
          <t-form-group
            label="deviceId"
            name="deviceId"
            :value="deviceId"
            disabled
          ></t-form-group>
          <t-form-group
            label="VUE_APP_VERSION"
            name="VUE_APP_VERSION"
            :value="VUE_APP_VERSION"
            disabled
          ></t-form-group>
          <t-form-group
            label="VUE_APP_RELEASE"
            name="VUE_APP_RELEASE"
            :value="VUE_APP_RELEASE"
            disabled
          ></t-form-group>
          <t-form-group
            label="VUE_APP_LAST_BUILD"
            name="VUE_APP_LAST_BUILD"
            :value="VUE_APP_LAST_BUILD"
            disabled
          ></t-form-group>
          <t-form-group
            label="VUE_APP_RELEASE_STAGE"
            name="VUE_APP_RELEASE_STAGE"
            :value="VUE_APP_RELEASE_STAGE"
            disabled
          ></t-form-group>
          <t-form-group
            label="SENTRY_DSN"
            name="SENTRY_DSN"
            :value="SENTRY_DSN"
            disabled
          ></t-form-group>
          <t-form-group
            label="ROOT_API (путь к корню srv api)"
            name="ROOT_API"
            v-model="model.ROOT_API"
          ></t-form-group>
          <t-button
            type="submit"
            :loading="loading"
          >
            Сохранить
          </t-button>
        </t-form>
      </div>

      <div class="col-md-6">
        <div>
          <t-button
            full-width
            @click="removeUserData()"
          >Удалить польз данные(jwt токен и проч)</t-button>
        </div>

        <hr>

        <div>
          Проверка трекера ошибок

          <t-button @click="generateError()">
            generateError()
          </t-button>
          <t-button @click="generateDelayedError()">
            generateDelayedError()
          </t-button>
          <t-button @click="showJsErrorNotify()">
            showJsErrorNotify()
          </t-button>
        </div>

        <hr>

        <div>
          Ссылка на просмотр под этим пользователем:
          <pre>{{jwtLink}}
            </pre>
        </div>
      </div>
    </div>
  </t-page-layout-article>
</template>

<script>
  import {FormValidator} from "../../components"
  import {ROOT_API, SENTRY_DSN} from "../../configs/config"
  import {getDeviceId} from "../../utils/random"

  export default {
    name: "SettingUpdate",
    created () {
      this.formValidator = new FormValidator()
    },
    data: () => ({
      loading: false,
      model: {
        ROOT_API: String(ROOT_API || ''),
      }
    }),
    methods: {
      onSubmit () {
        if (this.formValidator.validate().hasErrors() || this.loading) {
          return
        }

        this.loading = true

        for (let keyName in this.model) {
          window.localStorage.setItem(keyName, JSON.stringify(this.model[keyName]))
        }

        this.reload()
      },
      generateError () {
        throw new Error('generateError')
      },
      generateDelayedError () {
        setTimeout(() => {
          throw new Error('generateDelayedError')
        }, 0)
      },
      showJsErrorNotify () {
        const error = new Error('Error title')
        error.message = 'Error msg'
        this.$notification.jsError(error)
      },
      reload () {
        setTimeout(() => {
          document.location.reload(true)
        }, 1000)
      },
      removeUserData () {
        window.localStorage.clear()
        this.$notification.document({
          message: 'Удалено',
        })
        this.reload()
      }
    },
    computed: {
      jwtLink () {
        if (this.$store.getters.isGuest) {
          return ''
        }
        return `${window.location.origin}?jwt=${this.$store.getters.userData.jwt.token}`
      },
      deviceId () {
        return getDeviceId()
      },
      /**
       * @return {string}
       */
      VUE_APP_VERSION () {
        return process.env.VUE_APP_VERSION
      },
      /**
       * @return {string}
       */
      VUE_APP_LAST_BUILD () {
        return process.env.VUE_APP_LAST_BUILD
      },
      /**
       * @return {string}
       */
      VUE_APP_RELEASE_STAGE () {
        return process.env.VUE_APP_RELEASE_STAGE
      },
      SENTRY_DSN () {
        return SENTRY_DSN
      },
      /**
       * @return {string}
       */
      VUE_APP_RELEASE () {
        return process.env.VUE_APP_RELEASE
      },
    }
  }
</script>
