<template>
  <div class="t-tag-input-wrapper row">
    <div
      class="t-tag-input"
      :class="{
         'col-md-6': !disabled,
         'col-md-12': disabled,
      }"
    >
      <div
        class="t-tag-input__select"
        v-if="!disabled"
      >
        <t-input
          :name="name"
          :placeholder="placeholder"
          v-model="inputModel"
          ref="input"
          :hasError="hasError"
          :has-label="hasLabel"
          :required="required"
          @enter="onEnter"
          custom-enter-reaction
        />
      </div>
      <div
        class="t-tag-input__tags"
        v-if="value && value.length"
        :class="{
        't-tag-input__tags--disabled': disabled,
        }"
      >
        <div
          v-for="name in value"
          :key="name"
          class="t-tag-input__tags__tag"
        >
          <div>
            {{name}}
          </div>
          <div
            @click="removeTag(name)"
            v-if="!disabled"
          >
            <t-icon name="close"></t-icon>
          </div>
        </div>
      </div>
    </div>
    <div
      class="t-tag-input-button col-md-6"
      v-if="!disabled"
    >
      <t-button
        :class="{
          't-button--active-tag': this.inputModel.length > 0,
        }"
        @click="addTag"
        icon
        icon-type="plus"
      >{{addTagLabel}}</t-button>
    </div>
  </div>
</template>

<script>
  import {baseFieldMixin} from "../mixins/base-field-mixin"
  import {FieldValidationResult} from "../utils/validator/FieldValidationResult";

  export default {
    name: "t-tag-input",
    mixins: [baseFieldMixin],
    data: () => ({
      loading: false,
      inputModel: '',
    }),
    props: {
      required: {
        type: Boolean,
        default: false
      },
      addTagLabel: {
        type: String,
        default: `Добавить`,
      }
    },
    methods: {
      onEnter($event) {
        $event.stopPropagation()
        $event.preventDefault()

        this.addTag()
      },
      addTag() {
        if (!this.inputModel) {
          return;
        }

        this.$emit('input', [...new Set([
          ...this.value,
          this.inputModel,
        ])])

        this.inputModel = ''

        this.validateFieldWithDelay()
      },
      removeTag(name) {
        this.$emit('input', this.value.filter(tagName => tagName !== name))

        this.validateFieldWithDelay()
      },
      /**
       * @override
       */
      getElement () {
        return this.$refs.input.getElement()
      },
      /**
       * Вызывается formValidator и содержит логику по валидации
       * @returns {FieldValidationResult}
       * @override
       */
      validate () {
        const fieldValRes = new FieldValidationResult()
        fieldValRes.el = this.getElement()

        if (this.required) {
          if (!(Array.isArray(this.value) && this.value.length > 0)) {
            fieldValRes.error = {
              title: `Не заполненное поле`,
              message: `Необходимо добавить минимум 1 тэг`
            }
          }
        }

        this.emmitValidState(fieldValRes)

        return fieldValRes
      },
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-tag-input {
    font-family: $fontFamily;
    position: relative;

    .t-tag-input__tags {
      display: flex;
      flex-wrap: wrap;
      margin-right: -9px;
      margin-left: -9px;
      margin-top: 26px;

      &.t-tag-input__tags--disabled {
        margin-top: 0;
      }

      .t-tag-input__tags__tag {
        display: flex;
        padding: 3.5px 8px;
        background: $GrayG20;
        border-radius: 8px;
        margin: 4.5px 9px;
        margin-right: -1px;
        color: $BlackB60;
        font-size: 14px;

        .t-icon {
          padding-left: 6px;
          color: $GrayG100;
          cursor: pointer;

          svg {
            height: 16px;
            width: auto;
          }
        }
      }
    }
  }

  .t-tag-input-button {
    .t-button {
      height: 46px;
      font-size: 14px;
      width: 100%;
      color: $GrayG100;
      background: $WhiteW100;
      border: 1px solid $GrayG100;

      &.t-button--active-tag {
        color: $WhiteW100;
        background: $BlueB100;
        border: 1px solid $BlueB100;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <t-tag-input
        v-model="tags"
        name="tags"
      ></t-tag-input>
    </div>
  </template>

  <script>
    export default {
      data: () => ({
        tags: [`man`, `woman`]
      }),
    }
  </script>
  ```
</docs>

