<template>
  <component
    :is="tagName"
    novalidate
    :class="[`t-form`, `t-form--${layout}`]"
    @submit.stop.prevent="onSubmit"
    data-e2e="form"
    :name="`form-${name}`"
  >
    <slot></slot>
  </component>
</template>

<script>
  export default {
    name: "t-form",
    props: {
      formValidator: {
        required: true
      },
      tagName: {
        type: String,
        default: 'form'
      },
      name: {
        type: String,
        default: 'unknown',
        required: true
      },
      layout: {
        type: String,
        default: 'vertical',
      }
    },
    methods: {
      onSubmit () {
        this.$emit('submit')
      }
    }
  }
</script>

<style lang="scss">
  .t-form {
    width: 100%;
    padding: 6px 0;

    &.t-form--horizontal {
      display: flex;

      .t-form-group {
        margin: 0 9px;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <t-form
      @submit="onSubmit"
      :formValidator="formValidator"
      name="example"
    >
      <t-form-group
        label="Email"
        name="email"
        required
        v-model="model.email"
      ></t-form-group>
      <t-form-group
        label="Password"
        name="password"
        subType="password"
        v-model="model.password"
        required
      ></t-form-group>

      <t-form-actions>
        <t-button
          :disabled="loading"
          design="secondary-red"
        >
          Отмена
        </t-button>
        <t-button
          :loading="loading"
          type="submit"
          design="primary"
        >
          Submit
        </t-button>
      </t-form-actions>
    </t-form>
  </template>

  <script>
    import {FormValidator} from "../../components"

    export default {
      data: () => ({
        loading: false,
        model: {
          email: '',
          password: ''
        }
      }),
      created () {
        // create validator instance
        this.formValidator = new FormValidator()
      },
      methods: {
        async onSubmit() {
          // validate
          if (this.formValidator.validate().hasErrors() || this.loading) {
            return
          }

          try {
            this.loading = true

            // send ajax response to server
            await new Promise(resolve => setTimeout(() => {
              this.loading = false
              resolve()
            }, 3000))
          } catch (e) {
            this.loading = false
            alert(e.toString())
          }
        }
      }
    }
  </script>
  ```
</docs>
