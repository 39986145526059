<template>
  <button
    :type="type ? type : false"
    class="t-button"
    :class="classes"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <t-icon
      v-if="icon && !loading"
      :name="iconType"
    />
    <t-icon
      name="loading"
      v-if="loading"
    />
    <div
      class="t-button-text"
      v-if="$slots.default"
      :style="{ padding: padding !== undefined && `0 ${padding}px` }"
    >
      <slot></slot>
    </div>
  </button>
</template>

<script>
  export default {
    name: "t-button",
    props: {
      type: {
        type: String,
        default: 'button'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      icon: {
        type: Boolean,
        default: false
      },
      iconType: {
        type: String,
        default: 'home'
      },
      loading: {
        type: Boolean,
        default: false
      },
      design: {
        type: String,
        default: ``,
        validator: function (value) {
          return [
            ``,
            `primary`,
            `secondary-red`,
            `red`,
            `transparent-red`,
            `transparent-blue`,
            `fill-blue`,
            `fill-red`,
          ].includes(value)
        },
      },
      padding: {
        type: Number,
        default: undefined,
      },
      transparent: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classes () {
        const classes = []

        if (this.icon) {
          classes.push('t-button--icon')
        }

        if (this.icon) {
          classes.push('t-button--with-icon')
        }

        if (!this.$slots.default) {
          classes.push('t-button--no-text')
        }

        if (this.design) {
          classes.push(`t-button--${this.design}`)
        }

        if (this.loading) {
          classes.push(`t-button--loading`)
        }

        if (this.transparent) {
          classes.push(`t-button--transparent`)
        }

        if (this.active) {
          classes.push(`t-button--active`)
        }

        return classes
      },
      isDisabled () {
        return this.disabled || this.loading
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-button {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    border: none;

    height: 40px;
    min-width: 40px;
    border-radius: 16px;

    background: $GrayG10;
    color: $BlackB100;

    display: flex;
    justify-content: center;
    align-items: center;

    &.t-button--primary {
      background: $BlueB100;
      color: $WhiteW100;

      &:disabled {
        background: $GrayG10;
        color: $BlackB100;
      }
    }

    &.t-button--secondary-red {
      background: $WhiteW100;
      color: $RedR100;
      border: 2px solid $RedR100;
    }

    &.t-button--red {
      background: $ErrorBg;
      color: $Error;
      border: 2px solid $ErrorBg;
    }

    &.t-button--transparent-red {
      background: transparent;
      color: $RedR100;
      border: 2px solid transparent;
      font-size: 11px;
      letter-spacing: -0.2px;
      text-transform: uppercase;
    }
    &.t-button--transparent-blue {
      background: transparent;
      color: $BlueB100;
      border: 2px solid transparent;
      font-size: 11px;
      letter-spacing: -0.2px;
      text-transform: uppercase;
    }
    &.t-button--fill-red {
      height: 32px;
      background: $RedR10;
      color: $RedR100;
      border-radius: 10px;
      padding: 6px 10px;

      &:disabled {
        background: $RedBtnDisabledBg;
        color: $RedBtnDisabledColor;
      }

      &.t-button--active {
        background: $RedR100;
        color: $WhiteW100;
      }
    }
    &.t-button--fill-blue {
      height: 32px;
      background: $BlueB10;
      color: $BlueB100;
      border-radius: 10px;
      padding: 6px 10px;

      &:disabled {
        background: $BlueBtnDisabledBg;
        color: $BlueBtnDisabledColor;
      }

      &.t-button--active {
        background: $BlueB100;
        color: $WhiteW100;
      }
    }

    &.t-button--loading {
      .t-button-text {
        visibility: hidden;
      }

      .t-icon--loading {
        position: absolute;
      }
    }

    &.t-button--no-text {
      &.t-button--loading {
        .icon-loading div:nth-child(1) {
          left: -2px;
        }
        .icon-loading div:nth-child(3) {
          left: 2px;
        }
      }
    }

    &.t-button--transparent {
      background: transparent;
    }

    .t-button-text {
      padding: 10px 6px;
    }

    .t-icon {
      .t-icon__wrapper {
        margin-top: -2px;
      }
    }

    &.t-button--icon {
      box-sizing: border-box;
    }

    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  button {
    &.t-button{
      outline: 0;
    }
  }
</style>

<docs>
  ```vue
  <t-button type="submit">My btn</t-button>
  <br/>
  <t-button design="primary">My btn</t-button>
  <br/>
  <t-button design="secondary-red">Отмена</t-button>
  <br/>
  <t-button
    design="primary"
    icon
    icon-type="plus"
  >Добавить тэг</t-button>
  <br/>
  <t-button icon icon-type="settings"/>
  <br/>
  <t-button loading icon icon-type="settings"/>
  <br/>
  <t-button loading type="submit">Wait</t-button>
  <br/>
  <t-button design="transparent-red">My btn</t-button>
  <br/>
  <t-button design="transparent-blue">My btn</t-button>
  <br/>
  <t-button design="fill-red">My btn</t-button>
  <br/>
  <t-button design="fill-blue">My btn</t-button>
  <br/>
  ```
</docs>
