import * as MarchingSquaresJS from 'marchingsquares'
// Ramer-Douglas-Peucker неэфективен для кривых вида букв
// import simplifyJs from 'simplify-js'
import {simplify_line} from 'visvalingam-simplifier'
import * as martinez from 'martinez-polygon-clipping'

const padding = 5
const fontSize = 150
const minArea = 20
const cachedPolygons = {}

export function textToPoly(text, {
  fontFamily = 'Arial',
  align = 'center',
  textHeight = 200,
  paperWidth = 800,
  paperHeight = 400,
  useCache = true
} = {}) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const [screenWidth, screenHeight] = getWHByFontMeasures(text, {fontSize, fontFamily, padding})

  canvas.width = screenWidth
  canvas.height = screenHeight

  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, screenWidth, screenHeight)

  ctx.fillStyle = '#000'
  ctx.font = `${fontSize}px ${fontFamily}`
  ctx.fillText(text, padding / 2, fontSize + padding / 2)

  // document.body.appendChild(canvas)
  let polygons = useCache ? getFromCache(text, ctx.font) : null

  if (!polygons) {
    polygons = ctx |> (ctx => getGridOfPixels(ctx, {screenWidth, screenHeight}))
      |> (data => MarchingSquaresJS.isoBands(data, 0, 1))
      |> joinBoundedPoly
      |> simplify

    if (useCache) {
      setInCache(polygons, text, ctx.font)
    }
  }

  polygons = interpolatePolygonsToHeight(polygons, screenHeight, textHeight)

  const bounds = getBounds(polygons)

  const wh = calcRealWidtHeightByBounds(bounds)

  if (align === 'center') {
    polygons = alignPolygonsToCenter(polygons, bounds, wh, paperWidth, paperHeight)
  }

  return {polygons, bounds, wh}
}

function getCacheKey(text, font) {
  return `${text}:${font}`
}

function getFromCache(text, font) {
  const value = cachedPolygons[getCacheKey(text, font)]
  return value ? JSON.parse(JSON.stringify(value)) : null
}

function setInCache(polygons, text, font) {
  cachedPolygons[getCacheKey(text, font)] = JSON.parse(JSON.stringify(polygons))
}

function getWHByFontMeasures (text, {fontSize, fontFamily, padding}) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  ctx.font = `${fontSize}px ${fontFamily}`
  const textMeasure = ctx.measureText(text)

  return [
    Math.round(textMeasure.width + padding),
    Math.round(Math.abs(parseInt(ctx.font)) + padding)
  ]
}

function putInfoToDataGrid (data, x, y, value) {
  if (!data[y]) {
    data[y] = []
  }
  data[y][x] = value
}

function getGridOfPixels(ctx, {screenWidth, screenHeight}) {
  const data = []

  const imageData = ctx.getImageData(0, 0, screenWidth, screenHeight)
  const pixels = imageData.data
  for (let y = 0; y < screenHeight; ++y) {
    for (let x = 0; x < screenWidth; ++x) {
      const pixelAtXYOffset = (4 * y * screenWidth) + (4 * x)
      const red = pixels[pixelAtXYOffset + 1]

      // find color black on horizontal
      if (red > 0) {
        putInfoToDataGrid(data, x, y, 100)
      } else {
        putInfoToDataGrid(data, x, y, 0)
      }
    }
  }

  return data
}

function checkIntersection(poly, opposite) {
  return martinez.intersection([poly], [opposite])
}

function joinBoundedPoly(isolines) {
  const polys = []
  const skipIndex = []
  for (let i = 0; i < isolines.length; i++) {
    if (skipIndex.indexOf(i) > -1) {
      continue
    }

    const boundedPolyIdx = []
    for (let opI = i + 1; opI < isolines.length; opI++) {
      if (skipIndex.indexOf(opI) > -1) {
        continue
      }

      const intersectedArea = checkIntersection(isolines[i], isolines[opI])
      if (intersectedArea) {
        boundedPolyIdx.push(opI)
      }
    }

    if (boundedPolyIdx.length) {
      const next = []
      for (const id of boundedPolyIdx) {
        next.push(...isolines[id])
      }
      polys.push([...isolines[i], ...next])
      skipIndex.push(...boundedPolyIdx)
    } else {
      polys.push(isolines[i])
    }
  }
  return polys
}

function simplify(polygons) {
  const out = []

  for (const poly of polygons) {
    // const simplified = simplifyJs(poly.map(([x, y]) => ({x, y})), 2)
    const simplified = simplify_line(poly.map(([x, y]) => ({x, y})), minArea)
    out.push(simplified)
  }

  return out
}

function getBounds(polygons) {
  const leftTopCorner = {x: Infinity, y: Infinity}
  const rightBtmCorner = {x: 0, y: 0}

  for (const poly of polygons) {
    for (const p of poly) {
      if (p.x < leftTopCorner.x) {
        leftTopCorner.x = p.x
      }
      if (p.y < leftTopCorner.y) {
        leftTopCorner.y = p.y
      }
      if (p.x > rightBtmCorner.x) {
        rightBtmCorner.x = p.x
      }
      if (p.y > rightBtmCorner.y) {
        rightBtmCorner.y = p.y
      }
    }
  }

  return {leftTopCorner, rightBtmCorner}
}

function calcRealWidtHeightByBounds({leftTopCorner, rightBtmCorner}) {
  return {
    width: Math.abs(rightBtmCorner.x - leftTopCorner.x),
    height: Math.abs( rightBtmCorner.y - leftTopCorner.y),
  }
}

function alignPolygonsToCenter(polygons, bounds, wh, width, height) {
  const topOffset = Math.round((height - wh.height) / 2)
  const leftOffset = Math.round((width - wh.width) / 2)
  for (const poly of polygons) {
    for (const p of poly) {
      p.x = p.x + leftOffset - bounds.leftTopCorner.x
      p.y = p.y + topOffset - bounds.leftTopCorner.y
    }
  }
  return polygons
}

function interpolatePolygonsToHeight(polygons, screenHeight, height) {
  const q = height / screenHeight
  for (const poly of polygons) {
    for (const p of poly) {
      p.x = p.x * q
      p.y = p.y * q
    }
  }
  return polygons
}
