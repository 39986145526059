<template>
  <div
    class="t-input-wrapper"
    :class="{
      't-input-wrapper--disabled': disabled,
      't-input-wrapper--material': behavior === `material`,
      't-input-wrapper--required': required,
    }"
  >
    <label
      class="t-input-wrapper__material-placeholder"
      :class="{
        't-input-wrapper__material-placeholder--invalid': hasError
      }"
      v-show="materialFocused"
    >
      <span class="t-input-wrapper__material-placeholder__text">
        {{placeholder}}<div
          class="t-input-wrapper__material-placeholder__text__mark"
          v-if="!hideReqDot && required"
        >*</div>
      </span>
    </label>
    <t-icon
      v-if="leftIcon"
      :name="leftIcon"
      class="t-input-wrapper__left-icon"
    />
    <input
      :type="usedSubtype"
      class="t-input"
      :class="additionalClasses"
      :name="name"
      :value="value"
      @input="onInput"
      @blur="lostFocus();validateField()"
      @focus="onFocus()"
      v-on:keydown.enter="onEnter"
      v-bind:disabled="disabled"
      ref="field"
      :placeholder="placeholder"
    />
    <div
      v-if="subType === 'password'"
      @click="show=!show"
      class="t-input-wrapper__eye"
    >
      <t-icon :name="show ? `eyeOff` : `eye`"/>
    </div>
  </div>
</template>

<script>
  import {baseFieldMixin} from "../mixins/base-field-mixin"
  import maskable from "../mixins/maskable";
  import {FieldValidationResult} from "../utils/validator/FieldValidationResult";

  export default {
    name: "t-input",
    mixins: [baseFieldMixin, maskable],
    props: {
      customEnterReaction: {
        type: Boolean,
        default: false,
      }
    },
    data: () => ({
      show: false,
    }),
    computed: {
      additionalClasses() {
        return {
          't-input--is-invalid': this.hasError,
          't-input--small': this.size === 'small',
          't-input--material': this.behavior === `material`,
          't-input--default': this.behavior !== `material`,
          't-input--has-left-icon': !!this.leftIcon,
        }
      },
      usedSubtype() {
        if (this.subType === 'password' && this.show) {
          return 'text'
        }
        return this.subType
      },
    },
    methods: {
      onEnter($event) {
        if (this.customEnterReaction) {
          this.$emit(`enter`, $event)
        } else {
          this.validateField()
        }
      },
      getElement () {
        return this.$refs.field
      },
      onInput ($event) {
        let value = ($event.target.value || ``).trim()

        if (this.mask) {
          this.resetSelections($event.target)
          this.lazyValue = this.maskText(this.unmaskText(value))
          this.setSelectionRange()
          value = this.maskText(this.lazyValue)
        }

        this.$emit('input', value)
      },
      /**
       * Вызывается formValidator и содержит логику по валидации
       * @returns {FieldValidationResult}
       * @override
       */
      validate () {
        const fieldName = this.label || this.placeholder || `значение`
        const fieldValRes = new FieldValidationResult()
        fieldValRes.el = this.getElement()

        if (this.required && !this.value) {
          fieldValRes.error = this.formatErrorObject(
            `Не заполненное поле`,
            `Необходимо ввести ${fieldName}`,
          )

          this.emmitValidState(fieldValRes)
          return fieldValRes
        }

        if (this.mask && this.value && this.mask.length !== this.value.length) {
          fieldValRes.error = this.formatErrorObject(
            `Не заполнена маска`,
            `Значение должно соответствовать ${this.mask}`
          )

          this.emmitValidState(fieldValRes)
          return fieldValRes
        }

        if (this.value) {
          if (this.validationRule && this.validationRule.type === `number`) {
            if (isNaN(+this.value)) {
              fieldValRes.error = this.formatErrorObject(
                `Укажите число`,
                `Необходимо ввести в ${fieldName} числовое значение`
              )

              this.emmitValidState(fieldValRes)
              return fieldValRes
            } else if (this.validationRule.max && this.value > this.validationRule.max) {
              fieldValRes.error = this.formatErrorObject(
                `Слишком большое число`,
                `Необходимо ввести в ${fieldName} число меньше ${this.validationRule.max + 1}`
              )

              this.emmitValidState(fieldValRes)
              return fieldValRes
            } else if (this.validationRule.min && this.value < this.validationRule.min) {
              fieldValRes.error = this.formatErrorObject(
                `Слишком маленькое число`,
                `Необходимо ввести в ${fieldName} число больше ${this.validationRule.min - 1}`
              )

              this.emmitValidState(fieldValRes)
              return fieldValRes
            }
          }
        }

        this.emmitValidState(fieldValRes)
        return fieldValRes
      },
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-input-wrapper {
    position: relative;
    font-family: $fontFamily;

    .t-input-wrapper__left-icon {
      position: absolute;
      color: inherit;
      left: 8px;
    }

    &.t-input-wrapper--disabled {
      .t-input-wrapper__left-icon {
        color: $BlackB60;
      }
    }

    .t-input-wrapper__eye {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      color: $GrayG100;
    }

    .t-input-wrapper__material-placeholder {
      position: absolute;
      top: -1px;
      left: 17px;
      font-size: 12px;
      padding: 0 9px;
      color: $BlueB100;
      height: 3px;
      background-color: $WhiteW100;

      .t-input-wrapper__material-placeholder__text {
        margin-top: -7px;
        display: block;

        .t-input-wrapper__material-placeholder__text__mark {
          display: inline-block;
          color: $Error;
        }
      }

      &.t-input-wrapper__material-placeholder--invalid {
        color: $Error;
      }
    }

    &.t-input-wrapper--material {
      .t-input {
        background: $GrayG05;
        color: $BlackB100;
      }

      .t-fake-placeholder {
        left: 24px;
        color: $GrayG100;
      }
    }
  }

  .t-input {
    width: 100%;
    font-family: $fontFamily;
    border: 1px solid transparent;
    background: $GrayG20;
    border-radius: 16px;
    padding: 11px 15px;
    font-size: 14px;
    font-weight: 500;
    color: $BlackB80;
    outline: none;
    height: 46px;

    &.t-input--has-left-icon {
      padding-left: 32px;
    }

    &.t-input--material {
      padding: 11px 24px;

      &:focus {
        background: $WhiteW100;
      }

      &:active {
        background: $WhiteW100;
      }
    }

    &.t-input--small {
      height: 28px;
      padding: 4px 8px;
      border-radius: 8px;

      &.t-input--has-left-icon {
        padding-left: 28px;
      }
    }

    &::placeholder {
      color: $Placeholder;
    }

    &:focus {
      border: 1px solid $Focus;
    }

    &:active {
      border: 1px solid $Focus;
    }

    &:disabled {
      border: 1px solid transparent;
      background-color: $BlackB10;
      color: $BlackB60;
      cursor: not-allowed;
    }

    &.t-input--is-invalid {
      border: 1px solid $Error;

      &.t-input--default {
        background: $ErrorBg;
      }
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      <div>
        <br>Norm
        <t-input
          v-model="value"
          name="norm"
        />

        <br>Small
        <t-input
          v-model="value"
          name="small"
          size="small"
        />

        <br>Pass
        <t-input
          v-model="value"
          sub-type="password"
          name="password"
        />

        <br>material
        <t-input
          v-model="value"
          behavior="material"
          name="material"
          placeholder="material"
        />

        <br>material req
        <t-input
          v-model="value"
          behavior="material"
          name="material"
          placeholder="material"
          required
        />
      </div>
    </div>
  </template>

  <script>
    export default {
      data: () =>({
        value: '',
      })
    }
  </script>
  ```
</docs>
