/**
 *
 use example
 in Vue component:
 export default {
    title: 'dsfsdfds'
}
 in Vue component:
 export default {
    title: function() {
       return this.serverData.title
    }
}
 disable get title:
 title: false
 in routes:
 ...mponent: Orders, name: 'orders', meta: {title: 'Orders'}
 * @param vm{Vue}
 */
function getTitle(vm) {
  let {title} = vm.$options
  if (title) {
    title = typeof title === 'function'
      ? title.call(vm)
      : title
  } else if (title !== false && vm.$route && vm.$route.meta && vm.$route.meta.title) {
    title = vm.$route.meta.title
  }
  return title
}

/**
 * Получение и отображение информации находящейся в
 * @link https://github.com/vuejs/vue-hackernews-2.0/blob/d82eec8098b09945b8828a3d531e90bb0469d931/src/util/title.js
 */
export const titleMixin = {
  created() {
    this.updateTitle()
  },
  watch: {
    '$route'() {
      this.updateTitle()
    }
  },
  methods: {
    updateTitle() {
      const title = getTitle(this)
      if (title) {
        document.title = title
      }
    }
  }
}
