<template>
  <div
    class="t-icon"
    :class="[{
      't-icon--loading': name === 'loading',
    }, `t-icon--${name}`]"
    @click="$emit('click', $event)"
  >
    <div class="t-icon__wrapper">
      <component :is="name"></component>
    </div>
  </div>
</template>

<script>
  import Settings from './iconSettings'
  import Exit from './iconExit'
  import Burger from './iconBurger'
  import Readme from './iconReadme'
  import Bell from './iconBell'
  import Refresh from './iconRefresh'
  import Left from './iconLeft'
  import Right from './iconRight'
  import Loading from './iconLoading'
  import Close from './iconClose'
  import Attachment from './iconAttachment'
  import File from './iconFile'
  import Search from './iconSearch'
  import Plus from './iconPlus'
  import Tick from './iconTick'
  import Eye from './iconEye'
  import EyeOff from './iconEyeOff'
  import Calendar from './iconCalendar'
  import Alarm from './iconAlarm'
  import CircleTick from './iconCircleTick'
  import EReader from './iconEReader'
  import Report from './iconReport'
  import Timer from './iconTimer'
  import Performance from './iconPerformance'
  import BigLoadingRing from './iconBigLoadingRing'
  import BigError from './iconBigError'
  import BigDone from './iconBigDone'
  import Copy from './iconCopy'
  import Download from './iconDownload'
  import Hyperlink from './iconHyperlink'
  import LoadingRing from './iconLoadingRing'
  import IconCapture from './iconCapture'
  import IconResetFilter from './iconResetFilter'
  import IconHelp from './iconHelp'

  export default {
    name: 't-icon',
    components: {
      exit: Exit,
      settings: Settings,
      burger: Burger,
      loading: Loading,
      readme: Readme,
      bell: Bell,
      refresh: Refresh,
      left: Left,
      right: Right,
      close: Close,
      attachment: Attachment,
      file: File,
      search: Search,
      plus: Plus,
      tick: Tick,
      eye: Eye,
      eyeOff: EyeOff,
      calendar: Calendar,
      alarm: Alarm,
      circleTick: CircleTick,
      eReader: EReader,
      report: Report,
      performance: Performance,
      timer: Timer,
      bigLoadingRing: BigLoadingRing,
      bigError: BigError,
      bigDone: BigDone,
      download: Download,
      copy: Copy,
      hyperlink: Hyperlink,
      loadingRing: LoadingRing,
      capture: IconCapture,
      resetFilter: IconResetFilter,
      help: IconHelp,
    },
    props: {
      name: {
        type: String,
        required: true,
        default: 'settings'
      }
    },
  }
</script>

<style lang="scss">
  .t-icon {
    position: relative;
    display: inline-block;

    svg {
      path {
        fill: currentColor;
      }
    }

    &.t-icon--bigError {
      svg {
        path:nth-child(2) {
          fill: white;
        }
      }
    }

    .t-icon__wrapper {
      display: block;
      position: relative;
      min-width: 16px;
      min-height: 16px;
    }
  }
</style>

<docs>
  ```vue
  <template>
    <div>
      Использование:
      <div class="icons-doc">
        <div class="icons-doc__icon">
          <t-icon name="exit" ref="icon"/>
        </div>
      </div>

      Список иконок:
      <div class="icons-doc">
        <div
          v-for="icon in icons"
          class="icons-doc__icon"
        >
          <div>
            {{icon}}
          </div>
          <div>
            <t-icon :name="icon"></t-icon>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
    export default {
      data: () => ({
        icons: [],
      }),
      mounted() {
        this.icons = Object.keys(this.$refs.icon.$options.components)
          .filter(s => s.indexOf('t-') === -1)
      }
    }
  </script>

  <style lang="scss">
    .icons-doc {
      display: flex;
      flex-wrap: wrap;
    }

    .icons-doc__icon {
      padding: 10px;
    }
  </style>

</docs>
