<template>
  <div
    class="t-document-list"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "t-document-list",
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-document-list {
    .t-document {
      margin: 12px 0;
    }
  }
</style>
