<template>
  <div
    class="requests requests-journal"
  >
    <t-filter-block>
      <t-form
        name="requestsFilter"
        :formValidator="formValidator"
      >
        <t-form-group
          placeholder="Сортировка"
          name="sort"
          v-model="model.sort"
          type="select"
          :options="sort"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
        <t-form-group
          placeholder="№ Заявки"
          name="_rn"
          v-model="model.number"
          @change="onSomeFieldChangedInFilter"
          type="select-suggestion"
          :options="numbers"
          :remote-method="findNumbers"
          :remote="true"
        ></t-form-group>
        <t-form-group
          placeholder="Подразделение заказчика"
          name="_rd"
          v-model="model[keys.DEPARTMENT_KEY]"
          type="select-suggestion"
          :options="model.departments"
          @change="onSomeFieldChangedInFilter"
          :remote-method="findDepartments"
          :remote="true"
        ></t-form-group>
        <t-form-group
          placeholder="Гос номер, модель ТС"
          name="_rv"
          v-model="model[keys.VEHICLE_KEY]"
          @change="onSomeFieldChangedInFilter"
          type="select-suggestion"
          :options="model.vehicles"
          :remote-method="findVehicles"
          :remote="true"
        ></t-form-group>
        <t-form-group
          placeholder="Вид ремонта"
          name="_rt"
          v-model="model['work_type.id']"
          type="select"
          @change="onSomeFieldChangedInFilter"
          :options="workTypes"
        ></t-form-group>
        <t-form-group
          placeholder="Статус"
          name="_rs"
          v-model="model.status"
          type="select"
          :options="filterStatuses"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
      </t-form>

      <template slot="actions">
        <t-button
          iconType="resetFilter"
          icon
          transparent
          @click="resetFilters"
        ></t-button>

        <t-pagination-simple
          :meta="itemMeta"
        ></t-pagination-simple>
      </template>
    </t-filter-block>

    <repair-requests-table
      :item-data="itemData"
      :activeItemId="activeItemId"
    ></repair-requests-table>

    <router-view/>
  </div>
</template>

<script>
  import {FormValidator} from "../../components";
  import {queryToModel} from "../../utils/helpers";
  import {REST} from "vue-fast-rest"
  import {repairRequests, voc} from "../../api/endpoints";
  import {DEFAULT_ORDERS, REPAIR_REQ_STATUSES, REPAIR_REQ_STATUSES_IN_FILTERS} from "../../configs/config";
  import {onlyUniqItemsInRef} from "../../utils/helpers";
  import RepairRequestsTable from "./components/repair-requests-table";

  const DEPARTMENT_KEY = 'root_department_id'
  const VEHICLE_KEY = 'vehicle.id'

  export default {
    components: {RepairRequestsTable},
    data: () => ({
      model: {
        sort: '',
        number: '',
        [VEHICLE_KEY]: '',
        'work_type.id': '',
        [DEPARTMENT_KEY]: '',
        departments: [],
        status: '',
        vehicles: [],
      },
      numbers: [],
    }),
    created () {
      this.formValidator = new FormValidator()

      this.$watch(
        () => this.model[DEPARTMENT_KEY],
        this.onDepartmentIdChanged,
      )

      queryToModel(this.$route.query, this.model, {
        onTransform: this.transformQueryToModel,
        disableArrays: true,
      })

      this.fillDepartmentsRefs()

      this.fillNumbersRefs(!this.model.number)
    },
    watch: {
      '$route': {
        handler() {
          queryToModel(this.$route.query, this.model, {
            onTransform: this.transformQueryToModel,
            disableArrays: true,
          })

          if (!this.model.number) {
            this.fillNumbersRefs(true)
          }
        }
      }
    },
    methods: {
      fillNumbersRefs(fromCurrentPage = false) {
        const itemsOnCurrentPage = this.itemData.items.map(v => ({
          id: v.number,
          name: v.number || `n/a`,
        }))

        if (fromCurrentPage) {
          this.numbers = itemsOnCurrentPage
          return
        }

        this.numbers = onlyUniqItemsInRef(this.numbers, itemsOnCurrentPage)
      },
      fillDepartmentsRefs() {
        const { data: { items: departments } } = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.department(),
        )

        this.model.departments = onlyUniqItemsInRef(this.model.departments, departments)
      },
      transformQueryToModel(v, name) {
        if (!v && name === `sort`) {
          return DEFAULT_ORDERS.repairRequests
        }
        if (name === `vehicles`) {
          if (v && this.model.vehicles.length === 0) {
            return JSON.parse(v)
          }
          return this.model.vehicles
        }
        if (name === `departments`) {
          if (v && this.model.departments.length === 0) {
            return JSON.parse(v)
          }
          return this.model.departments
        }
        if ([`sort`, `status`, `number`].includes(name)) {
          return `${v || ``}`
        }
        return v ? +v : ``
      },
      async onDepartmentIdChanged(departmentId) {
        if (departmentId) {
          await this.findVehicles()
        } else {
          this.model.vehicles = []
        }

        const found = this.model.vehicles.find(v => v.id === +this.model[VEHICLE_KEY])
        if (!found) {
          this.model[VEHICLE_KEY] = ""
        }

        this.onSomeFieldChangedInFilter()
      },
      onSomeFieldChangedInFilter () {
        const query = {
          ...this.$route.query,
          ...this.model,
          vehicles: JSON.stringify(this.model.vehicles.filter(v => v.id == this.model[VEHICLE_KEY]).map(v => ({
            id: v.id,
            name: v.name,
          }))),
          departments: JSON.stringify(this.model.departments.filter(v => v.id == this.model[DEPARTMENT_KEY]).map(v => ({
            id: v.id,
            name: v.name,
          }))),
        }

        this.$router.push({ query }, () => {}, () => {})
      },
      resetFilters() {
        this.$router.push({name: 'requests'}, () => {}, () => {})
      },
      async findVehicles(term) {
        const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.departmentVehicles(this.model[DEPARTMENT_KEY], term),
          cache: Infinity,
        });
        this.model.vehicles = data.data.items
      },
      async findNumbers(term) {
        if (term && term.length > 0) {
          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            endpoint: repairRequests.ordersByNumber(term, this.model.sort),
          });
          this.numbers = data.data.items.map(v => ({
            id: v.number,
            name: v.number || `n/a`,
          }))
        } else {
          this.fillNumbersRefs(true)
        }
      },
      async findDepartments(term) {
        if (term && term.length > 0) {
          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            endpoint: voc.department(term),
          })
          this.model.departments = data.data.items.map(v => ({
            id: v.id,
            name: v.name,
          }))
        } else {
          this.fillDepartmentsRefs()
        }
      },
    },
    computed: {
      keys() {
        return {
          DEPARTMENT_KEY,
          VEHICLE_KEY,
        }
      },
      activeItemId() {
        return this.$route.params.id
      },
      sort() {
        return [
          {id: 'created_at-', name: 'Сначала новые'},
          {id: 'created_at+', name: 'Сначала старые'},
        ]
      },
      statuses() {
        return [...REPAIR_REQ_STATUSES]
      },
      filterStatuses() {
        return [...REPAIR_REQ_STATUSES_IN_FILTERS]
      },
      workTypes() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          voc.workType(),
        )
      },
      departments() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          voc.department(),
        )
      },
      itemData () {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          repairRequests.ordersList(this.$route),
        ).data
      },
      itemMeta() {
        return {
          page: this.$route.query.page || 0,
          page_count: this.itemData.page_count,
          page_size: this.itemData.page_size,
          total_count: this.itemData.total_count,
          count: this.itemData.items.length,
        }
      },
    },
    async asyncData ({ store, route }) {
      await Promise.all([
        store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.workType(),
          cache: Infinity,
        }),
        store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.department(),
          cache: Infinity,
        }),
      ])

      const endpoint = repairRequests.ordersList(route)
      const loadedList = store.getters[REST.getters.readUrlEndpoint](endpoint)

      // загружаем данные для списка только если это:
      if (
        // открытие списка без модалки
        !route.params.id
        // переход в модалку и список еще не был загружен
        || (route.params.id && !loadedList)
      ) {
        await store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint,
        })
      }
    },
  }
</script>

<style lang="scss">
  .requests-journal {

  }
</style>
