<template>
  <div
    class="notifications notifications-list"
  >
    <t-filter-block>
      <t-form
        name="notifications-list"
        :formValidator="formValidator"
      >
        <t-form-group
          placeholder="Тип уведомления"
          name="type"
          v-model="model.meta_type"
          type="select"
          :options="metaTypes"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
        <t-form-group
          placeholder="Вид документа"
          name="type"
          v-model="model.meta_object_type"
          type="select"
          :options="metaObjectTypes"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
      </t-form>

      <template slot="actions">
        <t-button
          iconType="resetFilter"
          icon
          transparent
          @click="resetFilters()"
        />
      </template>
    </t-filter-block>

    <t-document-list>
      <div v-if="showedItems.length < 1">
        Нет уведомлений
      </div>
      <t-document
        v-for="doc in showedItems"
        :key="doc.id"
        :time="$options.filters.formatFullDate(doc.created_at)"
        :title="replacePlaceholders(doc.title, doc.meta)"
        @close="removeMessage(doc)"
        :is-unread="readed.includes(doc.id) === false && doc.is_read === false"
        @mouseover="markAsRead(doc)"
        @click="markAsRead(doc)"
      >
        <span slot="tags">{{$options.filters.getNotifyTag(doc.meta.type)}}</span>
        {{replacePlaceholders(doc.message, doc.meta)}}
        <template slot="footer">
          <router-link
            v-if="doc.meta && doc.meta.appl_form_id"
            :to="{name: 'requests.requestView', params: { id: doc.meta.appl_form_id }}"
          >
            Перейти
          </router-link>
          <router-link
            v-else-if="doc.meta && doc.meta.work_order_id"
            :to="{name: 'orders.orderView', params: { id: doc.meta.work_order_id }}"
          >
            Перейти
          </router-link>
        </template>
      </t-document>

      <infinite-loading
        :identifier="infiniteIdentifier"
        @infinite="infiniteHandler"
      >
        <div slot="spinner">
          <t-icon name="loading"/>
        </div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>

        <div slot="error" slot-scope="{ trigger }">
          <p>
            Ошибка - {{loadMoreErr.name}} {{loadMoreErr.message}}
          </p>
          <p>
            Нажмите <a href="javascript:;" @click="trigger">сюда</a> чтобы повторить загрузку
          </p>
        </div>
      </infinite-loading>
    </t-document-list>
  </div>
</template>

<script>
  import {FormValidator} from "../../components";
  import {queryToModel, replacePlaceholders} from "../../utils/helpers";
  import {REST} from "vue-fast-rest";
  import {notifications} from "../../api/endpoints";
  import {NOTIFICATIONS_META_OBJECT_TYPES, NOTIFICATIONS_META_TYPES} from "../../configs/config";
  import InfiniteLoading from 'vue-infinite-loading';
  import {recognizeError} from "../../utils/recognizeError";

  export default {
    components: {
      InfiniteLoading,
    },
    data: () => ({
      infiniteIdentifier: 1,
      loadMoreErr: {},
      readed: [],
      removed: [],
      model: {
        meta_type: '',
        meta_object_type: '',
      },
    }),
    created () {
      this.formValidator = new FormValidator()

      queryToModel(this.$route.query, this.model)

      this.subsGroup = this.$emitter.group(
        this.$emitter.listen('notifications.new', this.onSocketMessage),
        this.$emitter.listen('notifications.read', this.onReadMessage),
      )
    },
    destroyed () {
      this.subsGroup.unsubscribe()
    },
    watch: {
      '$route': {
        handler() {
          queryToModel(this.$route.query, this.model)
        }
      }
    },
    computed: {
      metaTypes() {
        return [
          ...NOTIFICATIONS_META_TYPES,
        ]
      },
      metaObjectTypes() {
        return [
          ...NOTIFICATIONS_META_OBJECT_TYPES,
        ]
      },
      showedItems() {
        return this.itemData.items.filter(v => this.removed.includes(v.id) === false)
      },
      itemData() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          notifications.messages(this.$route),
        )
      },
      itemMeta() {
        return {
          page: this.$route.query.page || 0,
          page_count: this.itemData.page_count,
          page_size: this.itemData.page_size,
          total_count: this.itemData.total_count,
          count: this.itemData.items.length,
        }
      },
    },
    methods: {
      onSomeFieldChangedInFilter () {
        this.$router.push({
          query: {
            ...this.$route.query,
            ...this.model
          }
        }, () => {
          this.infiniteIdentifier += 1
        })
      },
      async removeMessage(doc) {
        this.removed.push(doc.id)

        try {
          await this.$store.dispatch(REST.actions.deleteModel, {
            url: notifications.remove(doc.id),
          })
        } catch (e) {
          this.removed = this.removed.filter(id => id !== doc.id)
          this.$alert.jsError(e)
        }
      },
      resetFilters() {
        this.$router.push({name: 'notifications'}, () => {
          this.infiniteIdentifier += 1
        }, () => {})
      },
      async markAsRead(doc) {
        if (doc.is_read === true || this.readed.includes(doc.id)) {
          return
        }

        this.readed.push(doc.id)

        try {
          await this.$store.dispatch(REST.actions.createModel, {
            url: notifications.markAsRead(doc.id),
            data: undefined,
          })

          await this.$store.dispatch(`decreaseUnreadCount`)
        } catch (e) {
          this.readed = this.readed.filter(id => id !== doc.id)
        }
      },
      replacePlaceholders(text, obj) {
        return replacePlaceholders(text, obj)
      },
      async infiniteHandler($state) {
        try {
          this.loadMoreErr = {}

          const prevItems = JSON.parse(JSON.stringify(this.itemData.items))
          const latest = prevItems[prevItems.length - 1]
          if (!(latest && latest.id)) {
            $state.complete()
            return
          }

          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            url: notifications.messages({
              query: {
                ...this.$route.query,
                records_to_id: latest.id,
              }
            }),
            params: {
              disableGlobalError: true,
            }
          })

          const nextItems = []
          data.items.forEach(r => {
            const exist = prevItems.find(v => v.id === r.id)
            if (!exist) {
              nextItems.push(r)
            }
          })

          // если нет новых элементов
          if (nextItems.length === 0) {
            $state.complete()
            return
          }

          this.$store.commit(REST.mutations.updateEndpoint, {
            endpoint: notifications.messages(this.$route),
            response: {
              data: {
                ...data,
                items: [
                  ...prevItems,
                  ...nextItems,
                ],
              }
            },
          })

          $state.loaded()
        } catch (e) {
          this.loadMoreErr = recognizeError(e)

          $state.error()

          throw e;
        }
      },
      onReadMessage({ id }) {
        this.readed.push(id)
      },
      onSocketMessage(message) {
        try {
          const prevItems = JSON.parse(JSON.stringify(this.itemData.items))
          const notify = {
            ...message,
            created_at: message.creationDate,
            id: message.meta.id,
          }

          // пропуск если сообщение не проходит по фильтрам
          if (this.model.meta_type && notify.meta.type !== this.model.meta_type) {
            return
          }
          if (this.model.meta_object_type && notify.meta.object_type !== this.model.meta_object_type) {
            return
          }

          // иначе добавляем в начало списка
          this.$store.commit(REST.mutations.updateEndpoint, {
            endpoint: notifications.messages(this.$route),
            response: {
              data: {
                items: [
                  notify,
                  ...prevItems,
                ],
              }
            },
          })
        } catch (e) {
          console.error(e)
        }
      },
    },
    async asyncData ({ store, route }) {
      await store.dispatch(REST.actions.updateUrlEndpoint, {
        endpoint: notifications.messages(route),
      })
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .notifications-list {
    .t-filter-block {
      .t-filter-block__actions {
        width: 70%;
        justify-content: flex-end;
        padding-left: 12px;

        .t-button {
          color: $Error;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: $mdDeviceWidth) {
    .notifications-list {
      .t-filter-block {
        .t-filter-block__actions {
          justify-content: flex-start;
        }
      }
    }
  }
</style>
