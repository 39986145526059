<template>
  <div
    class="t-notification"
    :class="{
      't-notification--kill': readyForKill,
    }"
    @click="onRead"
  >
    <div
      class="t-notification__close"
      @click="close"
    >
      <div class="t-notification__close__caption">Очистить</div>
      <t-button
        icon
        icon-type="close"
        transparent
      />
    </div>
    <div
      class="t-notification__time"
      v-if="time"
    >
      {{time}}
    </div>
    <div
      class="t-notification__tag"
      v-if="tag"
    >
      {{tag}}
    </div>
    <div
      class="t-notification__title"
      v-if="title"
    >
      {{title}}
    </div>
    <div class="t-notification__message">
      {{message}}
    </div>
    <div
      v-if="actions"
      class="t-notification__actions"
    >
     <component :is="`actions-comp`"></component>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 't-notification',
    data: () => ({
      readyForKill: false,
    }),
    props: {
      time: {
        type: String,
      },
      tag: {
        type: String,
      },
      title: {
        type: String,
      },
      message: {
        type: String,
        required: true,
      },
      duration: {
        type: Number,
        default: 30000,
      },
      actions: {
        type: Object,
        default: () => null,
      },
      onRead: {
        type: Function,
        default: () => null,
      }
    },
    beforeMount() {
      if (this.actions) {
        Vue.component(`actions-comp`, this.actions)
      }
    },
    mounted() {
      setTimeout(this.close, this.duration)
    },
    methods: {
      close() {
        this.readyForKill = true
        setTimeout(this.kill, 250)
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  #t-notification-list--right {
    position: fixed;
    top: 77px;
    right: 0;
    z-index: $confirmationZIndex;

    .t-notification {
      animation: element-from-left-to-right 0.25s linear;

      &.t-notification--kill {
        animation: element-hide 0.25s linear;
      }
    }
  }

  .t-notification {
    font-family: $fontFamily;
    letter-spacing: -0.4px;
    position: relative;
    margin: 23px;
    padding: 24px;
    background: $NotifyBG;
    border-radius: 8px;
    filter: $Shadows_Pop_UP;
    width: 400px;

    .t-notification__close {
      position: absolute;
      top: 24px;
      right: 24px;
      display: flex;
      align-items: center;
      height: 24px;
      cursor: pointer;

      .t-button {
        height: 16px;
        min-width: 16px;
        margin-right: -2px;
        padding: 2px 8px;
        padding-left: 0;
      }

      svg {
        height: 14px;
        width: auto;
      }

      .t-notification__close__caption {
        display: none;
      }

      &:hover {
        background: $GrayG10;
        color: $Error;
        border-radius: 10px;

        svg {
          color: $Error;
        }

        .t-notification__close__caption {
          padding: 2px 8px;
          font-size: 10px;
          display: block;
        }
      }
    }

    .t-notification__time {
      color: $BlackB80;
      font-size: 10px;
      padding: 4px 0;
    }

    .t-notification__tag {
      color: $PurpleP100;
      font-size: 10px;
      padding: 6px 0;
      font-weight: bold;
    }

    .t-notification__title {
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      color: $NotifyTitle;
      padding: 6px 0;
    }

    .t-notification__message {
      font-size: 14px;
      color: $NotifyContent;
      padding: 10px 0;
      line-height: 20px;
    }

    .t-notification__actions {
      margin: 3px 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      a {
        font-size: 14px;
        color: $BlueB100;
      }

      > div {
        display: flex;
        width: 100%;

        .t-button {
          margin-left: 12px;
          width: 50%;

          &:first-child {
            margin-left: 0;
          }

          .t-button-text {
            font-size: 14px;
          }
        }
      }
    }
  }
</style>

<docs>
  ```vue
<template>
  <p>
    <t-button @click="openDoc">
      openDoc
    </t-button>

    <t-button @click="openDocWithTwoBtns">
      openDoc with two buttons
    </t-button>

    <t-button @click="openDocWithLink">
      openDoc with link
    </t-button>

    <t-button @click="openErr">
      JS Error
    </t-button>

  </p>
</template>

<script>
  export default {
    methods: {
      openDoc() {
        const openDocWithTwoBtns = this.openDocWithTwoBtns

        const notify = this.$notification.document({
          time: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
          tag: 'ТЕГ',
          title: 'Заголовок',
          message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sem viverra quis nec.',
          actions: {
            template: `<t-button @click="onClick">openDoc with two buttons</t-button>`,
            methods: {
              onClick() {
                notify.close()
                openDocWithTwoBtns()
              }
            }
          }
        })
      },
      openDocWithTwoBtns() {
        this.$notification.document({
          time: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
          tag: 'ТЕГ',
          title: 'Заголовок',
          message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sem viverra quis nec.',
          actions: {
            template: `<div>
               <t-button>Действие</t-button> <t-button>Еще что-то</t-button>
            </div>`
          }
        })
      },
      openDocWithLink() {
        this.$notification.document({
          time: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
          tag: 'ТЕГ',
          title: 'Заголовок',
          message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sem viverra quis nec.',
          actions: {
            template: `<router-link :to="{path: '/'}">Перейти</router-link>`
          }
        })
      },
      openErr() {
        try {
          throw new Error('js error message')
        } catch (e) {
          this.$notification.jsError(e);
        }
      }
    }
  }
</script>
```
</docs>
