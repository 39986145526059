export class FieldValidationResult {
  /**
   * Текст ошибки
   * @type {string|object}
   */
  error = ''

  /**
   * @type {HTMLElement}
   */
  el
}
