var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  "t-color-progress",
  ("t-color-progress--size-" + _vm.size),
  ("t-color-progress--" + _vm.design) ]},[_c('div',{staticClass:"t-color-progress__title",style:({
       'color': _vm.design === "colored-inline" ? _vm.selectedOption.color : undefined,
    })},[_vm._v(" "+_vm._s(_vm.selectedOption.name)+" ")]),_c('div',{staticClass:"t-color-progress__bar"},[_c('div',{staticClass:"t-color-progress__bar__fill",style:({
        'background-color': _vm.selectedOption.color,
        'width': ((_vm.selectedOption.progress) + "%"),
      })})])])}
var staticRenderFns = []

export { render, staticRenderFns }