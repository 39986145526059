<template>
  <t-page-layout-article class="requests repair-request">
    <div
      v-if="openForViewInModal"
      class="repair-request__header"
    >
      <div class="repair-request__header__title">
        <t-title type="h3">Заявка на ТО и ТР</t-title>
      </div>

      <div class="repair-request__header__fields">
        <t-form
          @submit="onSubmit"
          :formValidator="formValidator"
          name="RepairRequest"
          layout="horizontal"
        >
          <t-form-group
            disabled
            name="transformedFields.number"
            :value="transformedFields.number"
            size="small"
            :style="{display: transformedFields.number ? undefined : `none`}"
          ></t-form-group>
          <t-form-group
            disabled
            name="transformedFields.date"
            v-model="transformedFields.date"
            size="small"
            leftIcon="calendar"
          ></t-form-group>
          <t-form-group
            disabled
            name="transformedFields.time"
            v-model="transformedFields.time"
            size="small"
            leftIcon="alarm"
          ></t-form-group>
        </t-form>
      </div>

      <div
        class="repair-request__header__close"
      >
        <t-button
          iconType="close"
          icon
          transparent
          @click="onClose"
        ></t-button>
      </div>
    </div>
    <t-title
      v-else
      type="h2"
    >
      Создание Заявки на ремонт
    </t-title>

    <t-form
      @submit="onSubmit"
      :formValidator="formValidator"
      name="RepairRequest"
    >

      <t-title type="h3-primary">Основные данные</t-title>
      <div
        class="row"
        v-if="openForViewInModal"
      >
        <div class="col-lg-5">
          <t-form-group
            disabled
            label="Состояние заявки"
            name="status"
            v-model="model.status"
            type="select"
            :options="statuses"
          ></t-form-group>
          <t-form-group
            disabled
            label="ФИО, должность"
            name="account.summary"
            v-model="model.account.summary"
          ></t-form-group>
          <t-form-group
            disabled
            label="Организация исполнитель"
            name="company_id"
            v-model="model.company_id"
            type="select"
            :options="companies"
          ></t-form-group>
        </div>
        <div class="col-lg-5">
          <t-form-group
            disabled
            label="Наименование организации"
            name="customer_id"
            v-model="model.customer_id"
            type="select"
            :options="customers"
          ></t-form-group>

          <t-form-group
            disabled
            label="Подразделение организации"
            name="department_id"
            v-model="model.department_id"
            type="select"
            :options="customersDepartments"
          ></t-form-group>

          <t-form-group
            disabled
            label="Контактный номер"
            name="contact_phone"
            v-model="model.contact_phone"
            mask="+7(###) ###-##-##"
          ></t-form-group>
        </div>
        <div class="col-lg-2">

        </div>
      </div>
      <div
        class="row"
        v-else
      >
        <div class="col-lg-4">
          <t-form-group
            v-if="model.number"
            disabled
            label="Номер заявки"
            name="number"
            v-model="model.number"
          ></t-form-group>

          <t-form-group
            :disabled="companies.length === 1"
            label="Организация исполнитель"
            name="company_id"
            v-model="model.company_id"
            type="select"
            :options="companies"
            required
          ></t-form-group>

          <t-form-group
            :disabled=disableField
            label="Контактный номер"
            name="phone"
            v-model="model.contact_phone"
            required
            placeholder="+7(999) 999-99-99"
            mask="+7(###) ###-##-##"
          ></t-form-group>
        </div>
        <div class="col-lg-4">
          <t-form-group
            disabled
            label="Состояние заявки"
            name="status"
            v-model="model.status"
            type="select"
            :options="statuses"
          ></t-form-group>

          <t-form-group
            disabled
            label="ФИО, должность"
            name="account.summary"
            v-model="model.account.summary"
          ></t-form-group>

        </div>
        <div class="col-lg-4">
          <t-form-group
            :disabled="customers.length === 1"
            label="Наименование организации"
            name="customer_id"
            v-model="model.customer_id"
            type="select"
            :options="customers"
            required
          ></t-form-group>

          <t-form-group
            :disabled="customersDepartments.length <= 1"
            label="Подразделение организации"
            name="department_id"
            v-model="model.department_id"
            type="select"
            :options="customersDepartments"
            required
          ></t-form-group>
        </div>
      </div>

      <t-title type="h3-primary">Данные ТС</t-title>
      <div class="row repair-request__vehicle-info">
        <div class="col-lg-4">
          <t-form-group
            :disabled=disableField
            label="Гос номер, марка"
            name="vehicle_id"
            v-model="model.vehicle_id"
            required
            @change="onVehicleChange"
            type="select-suggestion"
            :options="vehicles"
            :remote-method="findVehicles"
            :remote="true"
            :hideClearIcon="false"
          ></t-form-group>

          <t-form-group
            :disabled=disableField
            label="Основной ремонт"
            name="work_type_id"
            v-model="model.work_type_id"
            required
            type="select"
            :options="workTypes"
          ></t-form-group>

          <t-form-group
            :disabled=disableField
            label="Пробег"
            name="mileage"
            v-model="model.mileage"
            required
            :validation-rule="{
              type: `number`,
              min: 0,
              max: 999999,
            }"
          ></t-form-group>

        </div>
        <div class="col-lg-4">
          <t-form-group
            disabled
            label="Год выпуска ТС"
            name="vehicle.year"
            v-model="model.vehicle.year"
          ></t-form-group>

          <t-form-group
            :disabled=disableField
            label="Сопутствующий ремонт"
            name="second_work_type_id"
            v-model="model.second_work_type_id"
            type="select"
            :options="workTypes"
          ></t-form-group>

          <t-form-group
            :disabled=disableField
            label=" "
            placeholder="Наличие прицепа"
            name="has_trailer"
            v-model="model.has_trailer"
            type="checkbox"
          ></t-form-group>

        </div>
        <div class="col-lg-4">
          <t-form-group
            disabled
            label="VIN номер ТС"
            name="vehicle.vin"
            v-model="model.vehicle.vin"
          ></t-form-group>

          <t-form-group
            :disabled=disableField
            required
            label="Город"
            name="city_id"
            v-model="model.city_id"
            type="select-suggestion"
            :options="cities"
            :remote-method="findCities"
            :remote="true"
            :hideClearIcon="false"
          ></t-form-group>

          <t-form-group
            v-if="model.has_trailer"
            :disabled=disableField
            label="Гос номер, марка прицепа"
            name="trailer_id"
            v-model="model.trailer_id"
            required
            type="select-suggestion"
            :options="trailers"
            :remote-method="findTrailers"
            :remote="true"
            :hideClearIcon="false"
          ></t-form-group>
        </div>
      </div>
      <div class="row repair-request__tags-block repair-request__vehicle-info">
        <t-form-group
          class="col-lg-8"
          :disabled=disableField
          label="Текущая неисправность"
          name="defect_list"
          v-model="model.defect_list"
          type="tag-input"
          required
          addTagLabel="Добавить неисправность"
        ></t-form-group>
        <div class="col-lg-4">
        </div>
      </div>

      <template
        v-if="!openForViewInModal || model.attachments.length"
      >
        <t-title type="h3-primary">Вложенные файлы</t-title>
        <t-form-group
          v-model="model.attachments"
          name="attachments"
          :disabled="disableField"
          type="file-uploader"
          :enable-download="disableField"
          :downloadMethod="downloadMethod"
          :validationRule="{
            max: 10 * 1024 * 1024,
          }"
        ></t-form-group>
      </template>

      <t-form-actions
        v-if="!openForViewInModal"
      >
        <t-button
          @click="onClose"
          design="secondary-red"
        >
          Отмена
        </t-button>

        <t-button
          type="submit"
          design="primary"
        >
          Создать
        </t-button>
      </t-form-actions>


    </t-form>
  </t-page-layout-article>
</template>

<script>
  import {REST} from "vue-fast-rest"
  import {FormValidator} from "../../components";
  import {repairRequests, voc} from "../../api/endpoints";
  import {repairRequestUpdateAsyncData} from "./async-methods";
  import {formatDate, formatTime, fullNameWithPosition} from "../../utils/string";
  import {STATUS_CREATED, REPAIR_REQ_STATUSES} from "../../configs/config";
  import {onlyUniqItemsInRef} from "../../utils/helpers";
  import { saveAs } from 'file-saver';

  const FormModel = () => ({
    number: ``,
    created_at: "",
    status: STATUS_CREATED,
    company_id: "",
    customer_id: "",
    department_id: "",
    city_id: null,
    contact_phone: "",
    vehicle_id: "",
    trailer_id: "",
    vehicle: {
      year: "",
      vin: "",
    },
    account: {
      summary: "",
      contact_phone: "",
    },
    work_type_id: null,
    second_work_type_id: null,
    has_trailer: false,
    mileage: "",
    defect_list: [],
    attachments: [],
  })

  export default {
    name: "RepairRequest",
    props: {
      openForViewInModal: {
        type: Boolean,
        default: false,
      }
    },
    data: () => ({
      cities: [],
      trailers: [],
      vehicles: [],
      model: new FormModel(),
    }),
    created () {
      this.formValidator = new FormValidator()

      // предзаполнение из профиля юзера
      // фио с должностью
      this.model.account.summary = fullNameWithPosition(this.$store.getters.userProfile)

      // если ток одно значение - заполняем
      if (this.companies.length === 1) {
        this.model.company_id = this.companies[0].id
      }
      if (this.$store.getters.userProfile.customers.length === 1) {
        this.model.customer_id = this.$store.getters.userProfile.customers[0].id
      }
      this.model.department_id = ""

      this.mapDataToModel()

      this.subsGroup = this.$emitter.group(
        this.$emitter.listen('repairRequest.updateModel', this.onSocketMessage),
      )
    },
    destroyed() {
      this.subsGroup.unsubscribe()
    },
    watch: {
      '$route.params.id': `mapDataToModel`,
      'model.customer_id': `onCustomerIdChanged`,
      'model.department_id': `onDepartmentIdChanged`
    },
    computed: {
      item() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          repairRequests.orderView(this.$route.params.id)
        ).data
      },
      statuses() {
        return [...REPAIR_REQ_STATUSES]
      },
      workTypes() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          voc.workType(),
        )
      },
      companies() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          voc.company(),
        )
      },
      customers() {
        if (this.$store.getters.userProfile && this.$store.getters.userProfile.customers) {
          return this.$store.getters.userProfile.customers
        }
        return []
      },
      customersDepartments() {
        const data = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.customerDepartments(this.model.customer_id),
        )

        if (data && data.data && data.data.items) {
          return data.data.items
        }

        return []
      },
      disableField() {
        if (this.openForViewInModal) {
          return true
        }
        return false
      },
      transformedFields() {
        return {
          date: formatDate(this.model.created_at),
          time: formatTime(this.model.created_at),
          number: `${this.model.number || ''}`
        }
      },
    },
    methods: {
      fillCityRef() {
        const city = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.city(),
        )

        this.cities = onlyUniqItemsInRef(this.cities, city)
      },
      fillRefs() {
        this.fillCityRef()
      },
      mapDataToModel() {
        if (this.$route.params.id) {
          this.model = Object.assign({}, {
            ...new FormModel(),
            ...this.item,
          })

          // фио должность
          this.model.account.summary = fullNameWithPosition(this.model.account)

          this.model.attachments = this.model.appl_form_files.map(file => ({
            ...file,
            id: file.id,
            name: file.original_name || `n/a`,
            size: file.file_size || 0,
          }));

          if (this.model.city) {
            this.cities = [{ ...this.model.city }]
          }
          if (this.model.vehicle) {
            this.vehicles = [{ ...this.model.vehicle }]
          }
          if (this.model.trailer) {
            this.trailers = [{ ...this.model.trailer }]
          }
        } else {
          // предзаполнение из профиля юзера если новая заявка
          this.model.contact_phone = `${this.$store.getters.userProfile.phone || ``}`

          // набиваем справочники, чтобы при клике по нему
          // были какие-то элементы
          this.fillRefs()
        }

        if (this.model.trailer_id) {
          this.model.has_trailer = true
        }
      },
      onVehicleChange(v) {
        if (v && v.option) {
          this.model.vehicle.year = v.option.year || ``
          this.model.vehicle.vin = v.option.vin || ``

          if (v.option.department_id) {
            this.model.department_id = v.option.department_id
          }
        } else {
          this.model.vehicle.year = ``
          this.model.vehicle.vin = ``
        }
      },
      async onSubmit() {
        if (this.openForViewInModal) {
          return
        }

        const validateResult = this.formValidator.validate()

        // Запретить выбирать одинаковые значения из справочника /voc/work-type
        const hasSameWorkTypeValues = (this.model.work_type_id || this.model.second_work_type_id) && this.model.work_type_id === this.model.second_work_type_id

        if (validateResult.hasErrors() || this.loadingAlert || hasSameWorkTypeValues) {
          if (hasSameWorkTypeValues) {
            const err = {
              title: `Одинаковые значения`,
              message: `Поля Основной ремонт и Сопутствующий ремонт должны иметь разные значения`,
            }
            this.formValidator.markFieldAsInvalid(`work_type_id`, err);
            this.formValidator.markFieldAsInvalid(`second_work_type_id`, err);
          }

          console.warn(`validate errors`, validateResult.getErrors())
          return
        }

        try {
          await this.$confirmation({
            title: `Создать заявку на ремонт?`,
            yes: `Создать`,
            no: `Отменить`,
          })
        } catch (e) {
          return
        }

        await this.save()
      },
      async uploadFiles(id) {
        for (const file of this.model.attachments) {
          const fd = new FormData()
          fd.append(`appl_form_id`, `${id}`)
          fd.append(`appl_form_file`, file)

          await this.$store.dispatch(REST.actions.createModel, {
            url: repairRequests.orderUploadFile(),
            data: fd,
          })
        }
      },
      async save() {
        this.loadingAlert = this.$alert.loading()

        try {
          const {data} = await this.$store.dispatch(REST.actions.createModel, {
            url: repairRequests.orderCreate(),
            data: {
              data: {
                company_id: this.model.company_id,
                department_id: this.model.department_id,
                customer_id: this.model.customer_id,
                vehicle_id: this.model.vehicle_id,
                trailer_id: this.model.has_trailer
                  ? this.model.trailer_id
                  : null,
                work_type_id: this.model.work_type_id,
                second_work_type_id: this.model.second_work_type_id,
                city_id: this.model.city_id,
                mileage: Number(this.model.mileage || 0),
                defect_list: this.model.defect_list,
                contact_phone: this.model.contact_phone,
              },
            },
          })

          await this.uploadFiles(data.data.id)

          this.$alert.success({
            title: `Данные успешно отправлены`,
            onSuccessCb: () => this.$router.push({
              name: 'requests.requestsJournal',
            })
          })
        } catch (e) {
          this.$alert.jsError(e)
        } finally {
          this.loadingAlert.kill()
          this.loadingAlert = null
        }
      },
      onClose() {
        if (this.openForViewInModal) {
          // если форма открыта в модалке
          // возвращаемся к списку ничего не загружая
          this.$router.skipAsyncPush({
            name: 'requests.requestsJournal',
            query: this.$route.query,
          })
          return
        }

        this.$router.push({
          name: 'requests.requestsJournal',
          query: this.$route.query,
        })
      },
      async findCities (term) {
        if (term && term.length > 0) {
          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            endpoint: voc.city(term),
            cache: Infinity,
          });
          this.cities = data
        } else {
          this.fillCityRef()
        }
      },
      async findVehicles(term) {
        const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.departmentVehicles(this.model.department_id, term),
          cache: Infinity,
        });
        this.vehicles = data.data.items
      },
      async findTrailers(term) {
        const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.departmentVehicles(this.model.department_id, term),
          cache: Infinity,
        });
        this.trailers = data.data.items
      },
      async downloadMethod({ file }) {
        const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          url: file.http_url,
          params: {
            responseType: 'blob',
            disableGlobalError: true,
          }
        })

        saveAs(data, file.name)
      },
      onSocketMessage(data) {
        if (data.id !== this.model.id) {
          return
        }

        this.model = {
          ...this.model,
          ...data,
        }
      },
      async onCustomerIdChanged(customerId) {
        this.formValidator.lockValidateField()

        if (!this.$route.params.id) {
          this.model.department_id = ``

          this.model.vehicle_id = ``
          this.model.trailer_id = ``
          this.model.vehicle.year = ``
          this.model.vehicle.vin = ``
          this.vehicles = []
          this.trailers = []
        }

        await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.customerDepartments(customerId),
          cache: Infinity,
        })

        if (!this.$route.params.id) {
          // если одно подразделение - ставим его
          if (this.customersDepartments.length === 1) {
            this.model.department_id = this.customersDepartments[0].id
          }
        }

        this.formValidator.unlockValidateField()
        this.formValidator.delayedValidateField(`customer_id`)
      },
      async onDepartmentIdChanged(departmentId) {
        this.formValidator.lockValidateField()

        if (!this.$route.params.id) {
          if (departmentId) {
            await Promise.all([
              this.findVehicles(),
              this.findTrailers()
            ])
          } else {
            this.vehicles = []
            this.trailers = []
          }

          const existVeh = this.vehicles.find(v => v.id === this.model.vehicle_id)
          if (!existVeh) {
            this.model.vehicle_id = ``
            this.model.vehicle.year = ``
            this.model.vehicle.vin = ``
          }

          const existTrailer = this.trailers.find(v => v.id === this.model.trailer_id)
          if (!existTrailer) {
            this.model.trailer_id = ``
          }
        }

        this.formValidator.unlockValidateField()
        this.formValidator.delayedValidateField(`department_id`)
      },
    },
    asyncData (options) {
      return repairRequestUpdateAsyncData(options)
    },
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .repair-request {
    .repair-request__header {
      font-family: $fontFamily;

      display: flex;
      align-items: center;

      .repair-request__header__title {
        width: 220px;

        .t-title {
          margin-bottom: 0;
        }
      }

      .repair-request__header__fields {
        width: 100%;

        .t-form-group {
          max-width: 115px;
          margin-left: 0;

          input {
            &:disabled {
              background-color: $GrayG20;
              color: $GrayG100;
            }
          }

          &:nth-child(2) {
            width: 115px;
          }

          &:nth-child(3) {
            width: 77px;
          }
        }
      }

      .repair-request__header__close {
        .t-button {
          color: $RedR100;
          margin-top: -6px;
        }
      }
    }

    .repair-request__vehicle-info {
      input {
        &::placeholder {
          text-transform: uppercase;
        }
      }
    }

    .repair-request__tags-block {
      margin-top: -17px;
    }
  }

  @media (max-width: $mdDeviceWidth) {
    .repair-request {
      .repair-request__header {
        display: block;

        .repair-request__header__close {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
</style>
