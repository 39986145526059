import {ERROR_PAGE_NAME} from "../configs/config"

export function handleRenderError ({ vm, err, info, store }) {
  // eslint-disable-next-line
  console.debug('handleRenderError', err, info)
  if (!err) {
    err = new Error('Ошибка рендеринга')
  }

  if (info) {
    err.message = `${err.message || ''}, vue: ${info}`
  }

  const {showGlobalErrorOnVueError = false} = vm.$options

  if (vm.$notification && showGlobalErrorOnVueError === false) {
    // если есть всплывашка, выводить в нее
    vm.$notification.jsError(err)
  } else {
    // иначе валим все приложение
    store.commit('mutateError', err)
  }

}

export function handleRouteError ({ store, router, error, bar }) {
  let msg = 'Ошибка навигации '
  let from = ''
  let to = ''

  if (router.history.current && router.history.current.name) {
    from = router.history.current.name
    msg += ' при переходе от' + from
  }
  if (router.history.pending && router.history.pending.name) {
    to = router.history.pending.name
    msg += ' до ' + to
  }

  if (error) {
    msg += '<br>' + error.toString()
  }

  // redirect, for prevent some troubles:
  // - chunks
  // - deep error in store
  router.push({name: ERROR_PAGE_NAME, params: {error: new Error(msg)}}, () => {
    store.commit('mutateError', new Error(msg))
    bar.finish()
  }, () => bar.finish())

  // fix: infinity error loop
  // throw new Error(msg)
}

/**
 * @param vm
 * @param e
 */
export function handleApiActionError(vm, e) {
  const isTokenExpiredError = e && e.response && e.response.status && +e.response.status === 401

  if (isTokenExpiredError === false) {
    vm.$notification.jsError(e)
  }
}
