<template>
  <main-layout
    v-if="!$store.getters.isGuest"
  >
    <t-error
      :error="showedError"
      @goToLogin="logoutAndGoToLoginPage"
    ></t-error>
  </main-layout>
  <main-layout-clean
    v-else
  >
    <t-error
      class="error-for-guest"
      :error="showedError"
      @goToLogin="logoutAndGoToLoginPage"
    ></t-error>
  </main-layout-clean>
</template>

<script>
  import MainLayout from "../../common/layouts/main-layout"

  import {loginMixin} from '../../mixins/login-mixin'
  import MainLayoutClean from "../../common/layouts/main-layout-clean";

  export default {
    components: {MainLayoutClean, MainLayout},
    props: ['error'],
    mixins: [loginMixin],
    computed: {
      defaultError () {
        return {
          status: '404',
          name: 'Компонент страницы не найден'
        }
      },
      showedError () {
        return this.$store.getters.error || this.error || this.defaultError
      }
    }
  }
</script>

<style lang="scss">
  .error-for-guest {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }
</style>
