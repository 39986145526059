import {DEFAULT_ORDERS, ROOT_API} from '../configs/config'
import { buildURLQueryFromRoute } from '../utils/url'
import moment from "moment";

// swager url
// https://api.altest.tetron.ru/api/doc/order
// https://api.altest.tetron.ru/api/doc/voc
// https://api.altest.tetron.ru/api/doc/account
// https://api.altest.tetron.ru/api/doc/notify

export const voc = {
  // Справочник компаний
  company: () => `${ROOT_API}al/api/v1/voc/company`,
  // Список подразделений заказчиков, доступных в рамках аккаунта
  department: (name = ``) => `${ROOT_API}al/api/v1/order/voc/department${buildURLQueryFromRoute({
    query: {
      name: name ? `%${name}%` : undefined,
      page_size: 50,
    },
  })}`,
  // Список транспортных средств, доступных в рамках аккаунта
  vehicle: (name = ``) => `${ROOT_API}al/api/v1/order/voc/vehicle${buildURLQueryFromRoute({
    query: {
      name: name ? `%${name}%` : undefined,
      page_size: 50,
    },
  })}`,
  // Справочник видов работ - список ремонтов и плановых ТО.
  workType: () => `${ROOT_API}al/api/v1/voc/work-type`,
  // Справочник городов
  city: (name = ``) => `${ROOT_API}al/api/v1/voc/city${buildURLQueryFromRoute({
    query: {
      name: name ? `%${name}%` : undefined
    },
  })}`,
  // Подразделения заказчика
  customerDepartments: (customerId = 0) => `${ROOT_API}al/api/v1/order/voc/department?owner_id=${customerId}`,
  // Машины подразделения
  departmentVehicles: (rootDepartmentId = 0, name = ``) => `${ROOT_API}al/api/v1/order/voc/vehicle${buildURLQueryFromRoute({
    query: {
      root_department_id: rootDepartmentId,
      name: name ? `%${name}%` : undefined,
      page_size: 50,
    },
  })}`,
}

export const user = {
  login: () => `${ROOT_API}al/api/v1/account/login`,
  refreshToken: () => `${ROOT_API}al/api/v1/account/token/refresh`,
  selfInfo: () => `${ROOT_API}al/api/v1/account/self-info`,
  updateSelfInfo: () => `${ROOT_API}al/api/v1/account/self-info`,
  supportDocs: () => `${ROOT_API}al/api/v1/account/docs`,
}

export const repairRequests = {
  ordersListStartPrefix: () => `${ROOT_API}al/api/v1/order/appl-form?`,
  ordersList: ({query = {}} = {}) => `${ROOT_API}al/api/v1/order/appl-form${buildURLQueryFromRoute({
    query: {
      ...query,
      // https://task.tetron.ru/issue/AL-88
      // 1.1. По умолчанию установлена (самые последние сверху)
      sort: (query && query.sort) ? query.sort : DEFAULT_ORDERS.repairRequests,
      expand: `vehicle,workType,department,account,work_orders`,
      vehicles: undefined,
    }
  })}`,
  ordersByNumber: (term = ``, sort) => `${ROOT_API}al/api/v1/order/appl-form${buildURLQueryFromRoute({
    query: {
      number: term ? `%${term}%` : undefined,
      // По умолчанию установлена (самые последние сверху)
      sort: sort || DEFAULT_ORDERS.repairRequests,
      expand: `vehicle,workType,department,account,work_orders`,
    },
  })}`,
  orderCreate: () => `${ROOT_API}al/api/v1/order/appl-form`,
  orderUploadFile: () => `${ROOT_API}al/api/v1/order/appl-form/file`,
  orderView: (id) => `${ROOT_API}al/api/v1/order/appl-form/${id}?expand=account,applFormFiles,vehicle,city,trailer`,
}

export const workOrders = {
  workOrdersListStartPrefix: () => `${ROOT_API}al/api/v1/order/work-order?`,
  workOrdersList: ({query = {}} = {}) => `${ROOT_API}al/api/v1/order/work-order${buildURLQueryFromRoute({
    query: {
      ...query,
      // По умолчанию установлена (самые последние сверху)
      sort: (query && query.sort) ? query.sort : DEFAULT_ORDERS.workOrders,
      expand: `vehicle,work_type,customer,department`,
      departments: undefined,
    }
  })}`,
  workOrderView: (id) => `${ROOT_API}al/api/v1/order/work-order/${id}?expand=*`,
  approveWorkOrder: (id) => `${ROOT_API}al/api/v1/order/work-order/${id}/approval`,
  ordersByNumber: (term = ``, sort) => `${ROOT_API}al/api/v1/order/work-order${buildURLQueryFromRoute({
    query: {
      number: term ? `%${term}%` : undefined,
      // По умолчанию установлена (самые последние сверху)
      sort: sort || DEFAULT_ORDERS.workOrders,
      expand: `vehicle,work_type,customer,department`,
    },
  })}`,
}

export const notifications = {
  messages:  ({query = {}} = {}) => `${ROOT_API}al/api/v1/notify/websocket/message${buildURLQueryFromRoute({
    query: {
      ...query,
      sort: `id-`,
    },
  })}`,
  markAsRead: (id) => `${ROOT_API}al/api/v1/notify/websocket/message/${id}/read`,
  remove: (id) => `${ROOT_API}al/api/v1/notify/websocket/message/${id}`,
  getUnreadCount: () => `${ROOT_API}al/api/v1/notify/websocket/message/unread`,
}

export const reports = {
  createReport: (objectType) => `${ROOT_API}al/api/v1/order/${objectType}/report`,
  listOfReports:  ({query = {}} = {}) => `${ROOT_API}al/api/v1/order/document${buildURLQueryFromRoute({
    query: {
      sort: DEFAULT_ORDERS.reports,
      ...query,
      dt_to: query.dt_from ? moment(query.dt_from).endOf('day').toISOString() : undefined,
    },
  })}`,
  downloadReport: (id)  => `${ROOT_API}al/api/v1/order/document/${id}`,
}

export const acts = {
  listOfActs:  ({query = {}} = {}) => `${ROOT_API}al/api/v1/order/avr${buildURLQueryFromRoute({
    query: {
      sort: DEFAULT_ORDERS.acts,
      ...query,
      expand: `applFormList,workOrderList`,
    }
  })}`,
}
