/**
 * Удаляет html форматирвоание
 * @param value
 * @returns {string | string}
 */
export function stripHtml(value) {
  const div = document.createElement('div')
  div.innerHTML = value
  return div.textContent || div.innerText || ''
}

export function cut(value = '', len = 120) {
  value = value.toString()
  return value.length > len ? value.substr(0, len) + '...' : value
}
