<template>
  <div class="t-form-actions">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "t-form-actions"
  }
</script>

<style lang="scss">
  .t-form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
    margin-bottom: -6px;
    margin-left: -6px;
    margin-right: -6px;

    button {
      margin: 0 6px;
    }

    .t-button--secondary-red {
      .t-button-text {
        padding: 0px 57px;
      }
    }
    .t-button--primary {
      .t-button-text {
        padding: 0px 102px;
      }
    }
  }
</style>
