import {FormValidationResult} from "./FormValidationResult"

export class FormValidator {
  _fields = {}
  _validateFieldLocked = false

  subscribeField(inputCtx, name) {
    this._fields[name] = inputCtx
  }

  unsubscribeField(name) {
    delete this._fields[name]
  }

  /**
   * Вызывается при валидации компонентов входящих в форму
   * @returns {FormValidationResult}
   */
  validate() {
    const validationResult = new FormValidationResult()

    for (let name in this._fields) {
      const {error} = this.validateField(name)
      if (error) {
        validationResult.addError(name, error)
      }
    }

    return validationResult
  }

  /**
   * Получение ошибок валидации от сервера и их проставка в элементы формы
   */
  handleServerErrors() {

  }

  lockValidateField() {
    this._validateFieldLocked = true
  }

  unlockValidateField() {
    setTimeout(() => {
      this._validateFieldLocked = false
    })
  }

  markFieldAsInvalid (name, text = 'Неверные данные') {
    const component = this._fields[name]
    component.$emit('error', text)
  }

  delayedValidateField (name) {
    setTimeout(() => {
      this.validateField(name)
    })
  }

  validateField (name) {
    if (this._validateFieldLocked) {
      return
    }

    const component = this._fields[name]

    const {error} = component.validate()
    component.$emit('error', error)

    return {error}
  }
}
