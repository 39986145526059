<template>
  <t-div-table
    :table-data="item"
    @rowClick="onRowClick"
    :activeItemId="activeItemId"
    class="repair-requests-table"
  >
    <div
      slot="account"
      slot-scope="{row}"
    >
      {{row.account | fullName}}
    </div>
    <div
      slot="number"
      slot-scope="{row}"
    >
      {{row.number || 'n/a'}}
      <time>
        {{row.created_at | formatFullDate}}
      </time>
    </div>
    <div
      slot="car"
      slot-scope="{row}"
    >
      <div v-if="row.vehicle">
        {{row.vehicle.name}}
      </div>
    </div>
    <div
      slot="work_orders"
      slot-scope="{row}"
    >
      <template
        v-if="(row.work_orders || []).length > 0"
      >
        <div
          v-for="order in row.work_orders"
          :key="order.id"
          @click.stop=""
        >
          <router-link
            :to="{
              name: `orders.orderView`,
              params: { id: order.id },
            }"
            target="_blank"
          >
            {{order.number}}
          </router-link>
        </div>
      </template>
      <template v-else>
        -
      </template>
    </div>
    <div
      slot="status"
      slot-scope="{row}"
    >
      <t-color-progress
        :optionId="row.status"
        :options="statuses"
      />
    </div>
  </t-div-table>
</template>

<script>
  import {REPAIR_REQ_STATUSES} from "../../../configs/config";

  export default {
    name: "repair-requests-table",
    props: {
      itemData: {
        type: Object,
        default: () => ({
          items: [],
        }),
      },
      activeItemId: undefined,
    },
    methods: {
      onRowClick(row) {
        this.$router.push({
          name: 'requests.requestView',
          query: this.$route.query,
          params: { id: row.id },
        }, () => {}, () => {})
      },
    },
    computed: {
      statuses() {
        return [...REPAIR_REQ_STATUSES]
      },
      item() {
        return {
          data: this.itemData.items,
          "_meta": {
            "showedFields": {
              "number": {
                "title": "№ Заявки"
              },
              "car": {
                "title": "Гос номер. модель ТС",
              },
              "work_type": {
                "title": "Вид ремонта",
              },
              "status": {
                "title": "Статус",
              },
              "work_orders": {
                "title": "№ Заказ-наряда"
              },
              "account": {
                "title": "ФИО ответственного"
              },
              "department": {
                "title": "Подразделение заказчика",
              },
            }
          }
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../assets/saas/common";

  .repair-requests-table {
    .t-div-table__col--status {
      .t-color-progress {
        margin-top: -8px;

        .t-color-progress__bar {
          width: 100px;
        }
      }

      .t-div-table__col__title {
        display: none;
      }
    }

    .t-div-table__row {
      cursor: pointer;

      .t-div-table__col {
        width: 11%;

        a {
          color: $BlueB100;
          text-decoration: none;
        }

        &.t-div-table__col--number {
          flex-grow: 0;
          width: 90px;
        }

        &.t-div-table__col--id {
          flex-grow: 0;
          width: 90px;
        }

        &.t-div-table__col--status {
          flex-grow: 0;
          width: 150px;
        }

        time {
          font-size: 10px;
          display: block;
          margin-top: 6px;
        }
      }
    }
  }
</style>
