<template>
  <el-date-picker
    class="t-calendar"
    :class="additionalClasses"
    :type="selectDateType"
    :placeholder="placeholder"
    :value="value"
    :format="format"
    :valueFormat="valueFormat"
    @input="$emit('input', $event)"
    @change="onChange"
    :picker-options="{
      'firstDayOfWeek': 1,
      'disabledDate': getDisabledDate,
    }"
    :disabled="disabled"
    :name="name"
    :value-format="valueFormat"
    :clearable="!hideClearIcon"
    popper-class="t-calendar-popper"
  />
</template>

<script>
  import {baseFieldMixin} from "../mixins/base-field-mixin"

  export default {
    name: "t-calendar",
    mixins: [baseFieldMixin],
    props: {
      valueFormat: {
        type: String,
      },
      greaterThanValue: {
        validator: () => true,
      },
      lessThanValue: {
        validator: () => true,
      }
    },
    watch: {
      'value': 'validateField',
    },
    computed: {
      additionalClasses() {
        return {
          't-calendar--is-invalid': this.hasError,
          't-calendar--small': this.size === 'small',
        }
      },
    },
    methods: {
      /**
       * Возвращает domElement например для фокусировки на нем
       * если валидация провалена
       * @returns {HTMLElement}
       */
      getElement () {
        const node = this.$el.getElementsByTagName('input')
        if (node) {
          return node[0]
        }
        return null
      },
      onChange($event) {
        this.$emit(`change`, $event)
      },
      getDisabledDate(dt) {
        let isDisabled = false

        if (this.greaterThanValue) {
          isDisabled =  dt < new Date(this.greaterThanValue)
        }
        if (isDisabled === false && this.lessThanValue) {
          isDisabled = dt > new Date(this.lessThanValue)
        }

        return isDisabled
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-calendar {
    font-family: $fontFamily;
    width: 100%;

    &.t-calendar--is-invalid {
      &.el-date-editor {
        input {
          border: 1px solid $Error;
          background: $ErrorBg;
        }
      }
    }

    &.t-calendar--small {
      &.el-date-editor {
        input {
          height: 28px;
          padding: 4px 8px;
          border-radius: 8px;
        }
      }
    }

    &.el-date-editor {
      width: 100%;

      input {
        width: 100%;
        border: 1px solid transparent;
        background: $GrayG20;
        color: $BlackB80;
        border-radius: 16px;
        padding: 11px 15px;
        font-size: 14px;
        font-weight: 500;
        outline: none;
        height: 46px;

        &:focus {
          border: 1px solid $Focus;
        }

        &:active {
          border: 1px solid $Focus;
        }

        &:disabled {
          background-color: $BlackB10;
          color: $BlackB60;
          border: 1px solid transparent;
        }

        &::placeholder {
          color: $Placeholder;
        }
      }

      .el-input__icon {
        display: none;
      }
    }
  }

  .t-calendar-popper {
    font-family: $fontFamily;

    &.el-popper {
      .popper__arrow {
        display: none;
      }
    }

    &.el-popper[x-placement^=bottom] {
      margin-top: 7px;
    }
  }
</style>

<docs>
  ```vue
  <template>
    <p>
      <br>Norm
      <t-calendar
        v-model="form.date"
        name="date"
      ></t-calendar>

      <br>Small
      <t-calendar
        v-model="form.date"
        name="small"
        size="small"
      ></t-calendar>

      data: {{form.date}} <br>
      used: https://element.eleme.io/#/en-US/component/date-picker
    </p>
  </template>

  <script>
    export default {
      data: () => ({
        form: {
          date: ''
        }
      })
    }
  </script>
  ```
</docs>
