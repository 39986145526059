import Vue from 'vue'

import Confirmation from "./confirmation.vue"

function createConfirm(props) {
  const confirm = new Vue({
    ...Confirmation,
    propsData: {
      ...props,
    },
  }).$mount()

  document.body.appendChild(confirm.$el)

  const destroy = () => {
    confirm.$destroy()
    confirm.$el.parentNode.removeChild(confirm.$el)
  }

  return new Promise(((resolve, reject) => {
    confirm.promise = {
      reject: () => {
        destroy()
        reject()
      },
      resolve: () => {
        destroy()
        resolve()
      },
    }
  }))
}

export default function () {
  return (props) => createConfirm(props)
}
