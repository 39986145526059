import {decodeJWTtoObject} from "@/utils/string";

export const USER_DATA = 'AUTOLOCKSMITH-USER-DATA'

export const SENTRY_DSN = (function () {
  // если есть данные в public/external/config.js
  if (window.APP_GLOBAL_CONFIG && window.APP_GLOBAL_CONFIG.SENTRY_DSN) {
    return window.APP_GLOBAL_CONFIG.SENTRY_DSN
  }

  // если даныне были переданы при сборке контейнера
  if (process.env.VUE_APP_SENTRY_DSN) {
    return process.env.VUE_APP_SENTRY_DSN
  }

  return 'https://2529d725f73445ea9f033a6ae13a52cb@sentry-test.tetron.ru/77'
})()

export const ADMIN_LOGGED_AS_USER_JWT_QUERY_PARAM_NAME = 'jwt'

export const ADMIN_LOGGED_AS_USER_USER_DATA = (function () {
  if (window && window.location) {
    // если в url есть http://site.com/?jwt=sdfdsf345345
    // то пробуем его получить, распарсить и использовать вместо данных, которые в local storage
    const url = new URL(window.location)
    const token = url.searchParams.get(ADMIN_LOGGED_AS_USER_JWT_QUERY_PARAM_NAME)
    if (!token) {
      return null
    }

    try {
      const user = {
        jwt: {
          token
        },
        user: {
          id: null,
          name: 'Unknown name',
          office: {name: ''},
          role: null
        }
      }

      const encodedPayload = decodeJWTtoObject(token)

      user.jwt.token = token
      user.user.name = encodedPayload.login

      console.warn('Logged as other user, user payload ', encodedPayload)

      return user
    } catch (e) {
      console.error(e)
      alert(`Не удалось войти под другим пользователем!`)
      return null
    }
  }

  return null
}())

export const ROOT_API = (function () {
  // берем из локал сторандж
  const storageValue = window.localStorage.getItem('ROOT_API') ? JSON.parse(window.localStorage.getItem('ROOT_API')) : null
  if (storageValue) {
    return storageValue
  }

  // если e2e, то ставим стаб
  if (process.env.VUE_APP_IS_E2E_TEST) {
    return 'http://example.com/stub/'
  }

  // если есть данные в public/external/config.js
  if (window.APP_GLOBAL_CONFIG && window.APP_GLOBAL_CONFIG.ROOT_API !== null) {
    return window.APP_GLOBAL_CONFIG.ROOT_API
  }

  // если даныне были переданы при сборке контейнера
  if (process.env.VUE_APP_ROOT_API) {
    return process.env.VUE_APP_ROOT_API
  }

  // возвращаем url из адресной строки браузера
  const domain = window.location.origin
  return `${domain}/`
}())

export const HIDDEN_ROUTES_FOR_REGISTERED = [
  'user.login'
]

export const LOGIN_PAGE_NAME = 'user.login'
export const MAIN_PAGE_NAME = 'requests.requestsJournal'

export const DEFAULT_REDIRECT = {
  loginPage: {name: LOGIN_PAGE_NAME},
  mainPage: {name: MAIN_PAGE_NAME}
}

// за сколько миллисикунд по истчения токена его проверят
export const JWT_TOLERANCE = 6 * 60 * 1000

// как часто проверять нужно истек ли токен
export const JWT_RENEW_CHECK_INTERVAL = 2 * 60 * 1000

export const DEVELOPER_MENU_ROUTE_PREFIX = 'development'

export const USER_SERVERLESS_DATA = 'AUTOLOCKSMITH-USER-SERVERLESS_DATA'

export const ERROR_PAGE_NAME = 'main.error'

export const STATUS_CREATED = 2

export const STATUS_REGISTERED = 1

// Статусы заявки-на ремонт
export const REPAIR_REQ_STATUSES = [
  {
    id: STATUS_REGISTERED, // Технический статус, означает, что заявка на ремонт получена в backend, после загрузки в 1С изменяется на IN_WORK
    name: `Создана`,
    color: `#8F92A1`,
    progress: 25,
  },
  {
    id: STATUS_CREATED,
    name: `Создана`,
    color: `#8F92A1`,
    progress: 50,
  },
  {
    id: 3,
    name: `Передана контрагенту`,
    color: `#00A337`,
    progress: 75,
  },
  {
    id: 4, // Не обработана, не показываем заказчику
    name: `Передана контрагенту`,
    color: `#00A337`,
    progress: 85,
  },
  {
    id: 5, // Передана на субподряд, не показываем заказчику
    name: `Передана контрагенту`,
    color: `#00A337`,
    progress: 93,
  },
  {
    id: 6,
    name: `Создан заказ-наряд`,
    color: `#FF9900`,
    progress: 100,
  },
  {
    id: 7,
    name: `Отклонена исполнителем`,
    color: `#D00000`,
    progress: 100,
  },
]
// Статусы заявки-на ремонт в фильтрах
export const REPAIR_REQ_STATUSES_IN_FILTERS = [
  {
    id: `${STATUS_CREATED},${STATUS_REGISTERED}`,
    name: `Создана`,
  },
  {
    id: `3,4,5`,
    name: `Передана контрагенту`,
  },
  {
    id: `6`,
    name: `Создан заказ-наряд`,
  },
  {
    id: `7`,
    name: `Отклонена исполнителем`,
  },
]

// Заказ наряд Создан, не согласован
export const ORDER_STATUSES_NEW = 1

// Заказ наряд Согласован
export const ORDER_STATUSES_APPROVED = 2

// Заказ наряд Ожидает ремонта
export const ORDER_STATUSES_WAIT_FOR_REPAIR = 3

// Заказ наряд Поставлен на ремонт
export const ORDER_STATUSES_ON_REPAIR = 4

// Статусы заказ-наряда
export const ORDER_STATUSES = [
  {
    id: ORDER_STATUSES_NEW,
    name: `Создан, не согласован`,
    color: `#8F92A1`,
    progress: 30,
  },
  {
    id: ORDER_STATUSES_APPROVED,
    name: `Согласован`,
    color: `#00A337`,
    progress: 45,
  },
  {
    id: ORDER_STATUSES_WAIT_FOR_REPAIR,
    name: `Ожидает ремонта`,
    color: `#FF9900`,
    progress: 60,
  },
  {
    id: ORDER_STATUSES_ON_REPAIR,
    name: `Поставлен на ремонт`,
    color: `#00875A`,
    progress: 75,
  },
  {
    id: 5,
    name: `Ремонт произведен`,
    color: `#002A95`,
    progress: 90,
  },
  {
    id: 6,
    name: `Обработан`,
    color: `#6900A9`,
    progress: 100,
  },
  {
    id: 7,
    name: `Отменен`,
    color: `#D00000`,
    progress: 100,
  },
]

// типы уведомлений (они же тэги)
export const NOTIFICATIONS_META_TYPES = [
  { id: 'notify', name: 'Напоминание' },
  { id: 'approve', name: 'Согласование' },
  { id: 'info', name: 'Информирование' },
]

// типы сущностей уведомлений
export const NOTIFICATIONS_META_OBJECT_TYPES = [
  { id: 'appl_form', name: 'Заявка на ремонт'},
  { id: 'work_order', name: 'Заказ-наряд' },
  { id: 'report', name: 'Отчет' },
  { id: 'avr', name: 'АВР' },
  { id: 'file', name: 'Вложение' },
]

export const REPORT_TYPE_WORK_ORDER = `work-order`

export const REPORT_TYPES = [
  // { id: 'appl-form', name: 'Отчет по Заявкам на ремонт'},
  { id: REPORT_TYPE_WORK_ORDER, name: 'Отчет по Заказ-нарядам' },
]

export const DEFAULT_ORDERS = {
  reports: `create_dt-`,
  repairRequests: `created_at-`,
  workOrders: `create_dt-`,
  acts: `create_dt-`,
}
