<template>
  <div class="t-popup-block">
    <div class="t-popup-block__box">
      <div class="t-popup-block__box__container">
        <slot/>
        <div
          class="t-popup-block__box__container__title"
          v-if="$slots.title"
        >
          <slot name="title"/>
        </div>

        <div
          class="t-popup-block__box__container__message"
          v-if="$slots.message"
        >
          <slot name="message"/>
        </div>

        <div
          v-if="$slots.actions"
          class="t-popup-block__box__container__actions"
        >
          <slot name="actions"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "t-popup-block"
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .t-popup-block {
    font-family: $fontFamily;
    letter-spacing: -0.4px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $BlackB20;
    z-index: $confirmationZIndex;

    .t-popup-block__box {
      position: relative;
      height: 100%;
      width: 100%;

      .t-popup-block__box__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 200px;

        background: $WhiteW100;
        box-shadow: $Sh_confirmation;
        border-radius: 16px;
        padding: 32px;

        .t-popup-block__box__container__title {
          font-weight: bold;
          font-size: 22px;
          line-height: 30px;
          color: $BlackB100;
          text-align: center;
          margin-bottom: 16px;
          padding: 0 40px;
        }

        .t-popup-block__box__container__message {
          font-size: 16px;
          color: $SteelGray500;
          text-align: center;
          max-width: 385px;
          margin-bottom: 16px;
          min-height: 1px;
        }

        .t-popup-block__box__container__actions {
          display: flex;
          margin: 0 -8px;

          .t-button {
            width: 50%;
            margin: 0 8px;
          }
        }
      }
    }
  }
</style>
