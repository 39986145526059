<template>
  <div
    class="main-layout"
    :class="{
    'main-layout--has-system-error': showError
    }"
    data-e2e="main-layout"
  >
    <admin-logged-as-user-warning></admin-logged-as-user-warning>

    <div class="main-layout__wrapper">
      <navbar class="main-layout__wrapper__nav"/>

      <div class="main-layout__wrapper__grid">
        <left-menu class="main-layout__wrapper__menu"/>

        <main class="main-layout__wrapper__main">
          <slot></slot>
          <t-error
            class="content-view"
            v-if="showError"
            :error="error"
            @goToLogin="logoutAndGoToLoginPage()"
          ></t-error>
          <router-view
            v-else
          ></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {loginMixin} from '../../mixins/login-mixin'
  import {ERROR_PAGE_NAME, ADMIN_LOGGED_AS_USER_USER_DATA, LOGIN_PAGE_NAME, DEVELOPER_MENU_ROUTE_PREFIX} from '../../configs/config'
  import Navbar from '../navbar/navbar'
  import AdminLoggedAsUserWarning from "../warnings/admin-logged-as-user-warning"
  import LeftMenu from "../menu/left-menu"

  export default {
    name: 'main-layout',
    components: {AdminLoggedAsUserWarning, Navbar, LeftMenu },
    mixins: [loginMixin],
    data: () => ({}),
    computed: {
      showError() {
        // показываем ошибку если она есть и если это не форма входа, и не страница показа ошибки
        // т.к. на форме входа может ошибка авторизации - 401 и из-за этого не выйдет авторизоваться
        const isLoginPage = this.$route.name === LOGIN_PAGE_NAME
        const isErrorPage = this.$route.name === ERROR_PAGE_NAME
        const isDeveloperPage = this.$route.name.indexOf(DEVELOPER_MENU_ROUTE_PREFIX) > -1

        if (this.error && isLoginPage === false && isErrorPage === false && isDeveloperPage === false) {
          return true
        }
        return false
      },
      ...mapGetters([
        'error'
      ]),
      ADMIN_LOGGED_AS_USER_USER_DATA () {
        return ADMIN_LOGGED_AS_USER_USER_DATA
      }
    },
    asyncData: () => Promise.resolve()
  }
</script>

<style lang="scss">
  @import "../../assets/saas/common";

  .main-layout {
    position: relative;
    height: 100%;
    display: flex;
    width: 100%;
    min-width: $minWidth;

    .main-layout__wrapper {
      height: 100%;
      width: 100%;

      .main-layout__wrapper__grid {
        display: flex;
        height: calc(100% - #{$topNavHeight});
      }

      .main-layout__wrapper__nav {

      }

      .main-layout__wrapper__menu {
        min-width: $leftMenuWidth;
      }

      .main-layout__wrapper__main {
        position: relative;
        width: 100%;
        overflow: auto;
        background: #F4F5F6;
        border-radius: 16px 16px 0px 0px;
        padding: 20px;
      }
    }
  }

  @media (max-width: $mdDeviceWidth) {
    .main-layout {
      .main-layout__wrapper {
        .main-layout__wrapper__menu {
          min-width: 210px;
        }
      }
    }
  }
</style>
