export const RANDOM_PREFIX = [
  "closed", "noisy", "loud", "quiet", "light", "dark", "easy", "difficult", "new", "old", "light", "heavy", "fast", "slow", "full", "empty", "long", "short", "describe", "high", "low", "loose", "tight", "straight", "crooked", "wide", "narrow", "good", "bad", "hot", "cold", "soft", "hard", "neat", "messy", "beautiful", "pretty", "ugly", "wet", "dry", "big", "little", "clean", "dirty", "expensive", "cheap", "inexpensive", "large", "small", "sharp", "dull", "shiny", "dim", "fancy", "plain", "deep", "shallow"
]

export const RANDOM_NAME = [
  "bear", "fox", "rabbit", "wolf", "pig", "deer", "moose", "donkey", "buffalo", "camel", "giraffe", "hippopotamus", "zebra", "kangaroo", "elephant", "llama", "horse", "foal", "armadillo", "leopard", "lion", "tiger", "bison", "koala", "hyena", "panda", "rhinoceros", "monkey", "gorilla", "chimpanzee", "gibbon", "baboon", "anteater", "mouse", "possum", "skunk", "bat", "beaver", "gopher", "squirrel", "rat", "chipmunk", "porcupine", "raccoon"
]

export const DEVICE_NAME = 'DEVICE_NAME'

export function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function getRandomPrefix() {
  return RANDOM_PREFIX[getRandomInt(0, RANDOM_PREFIX.length - 1)] || RANDOM_PREFIX[0]
}

export function getRandomName() {
  return RANDOM_NAME[getRandomInt(0, RANDOM_NAME.length - 1)] || RANDOM_NAME[0]
}

export function getDeviceId() {
  let deviceId = null
  if (typeof window !== "undefined") {
    deviceId = window.localStorage.getItem(DEVICE_NAME) ? JSON.parse(window.localStorage.getItem(DEVICE_NAME)) : null
    if (deviceId === null) {
      deviceId = `${getRandomPrefix()} ${getRandomName()}`.trim().split(' ').join('_')
      window.localStorage.setItem(DEVICE_NAME, JSON.stringify(deviceId))
    }
  }
  return deviceId
}
