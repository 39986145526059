import {configureScope} from '@sentry/browser'

import {ADMIN_LOGGED_AS_USER_USER_DATA, HIDDEN_ROUTES_FOR_REGISTERED, USER_DATA, USER_SERVERLESS_DATA} from "../configs/config"
import {recognizeError} from "../utils/recognizeError"
import {depersonalize} from "../utils/string"

const getTransformedJwt = ({token, expire_at, refresh_token}) => {
  return {
    token,
    expire_at,
    refresh_token,
    expireInUTCMilliseconds: +new Date(expire_at * 1000)
  }
}

export default {
  /**
   * Устанавливаем занчение в userServerlessData
   * @param state
   * @param key - ключ у которого надо установить данные
   * @param data - данные для установки
   * @param strategy - заменить данные, доабвить и т.п.
   */
  setUserServerlessDataItem: function (state, {key = '', data = null, strategy = 'replace'} = {}) {
    switch (strategy) {
      case 'replace':
        if (Array.isArray(state._userServerlessData[key]) && !data) {
          state._userServerlessData[key] = []
        } else {
          state._userServerlessData[key] = data
        }
        break
      case 'add':
        if (Array.isArray(state._userServerlessData[key])) {
          if (Array.isArray(data)) {
            state._userServerlessData[key].push(...data)
          } else {
            state._userServerlessData[key].push(data)
          }
        } else {
          state._userServerlessData[key] = Object.assign(state._userServerlessData[key], data)
        }
        break
      default:
        console.warn('Unknown strategy', strategy)
    }

    if (ADMIN_LOGGED_AS_USER_USER_DATA) {
      console.warn('skip save setUserServerlessDataItem in localStorage, reason: Logged as other user')
    } else {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(USER_SERVERLESS_DATA, JSON.stringify(state._userServerlessData))
      }
    }
  },
  /**
   * @param state
   * @param authData
   * @param dontSaveInStorage - предотвращает рекурсивное обвноелние между несколкьими вкладками
   */
  userSetAuthData: function (state, {authData = {}, dontSaveInStorage = false} = {}) {
    let userData = Object.assign({}, state._userData || {})
    const newUserData = {}

    // if payload has user object
    if (authData.user) {
      newUserData.user = authData.user
    }

    // if payload has jwt
    if (authData.jwt) {
      newUserData.jwt = authData.jwt
    }

    // if payload has token
    if (authData.token) {
      newUserData.jwt = getTransformedJwt(authData)
    }

    if (ADMIN_LOGGED_AS_USER_USER_DATA && newUserData.jwt) {
      console.warn('setting new jwt token skipped - used old, reason: Logged as other user')
      newUserData.jwt = {...ADMIN_LOGGED_AS_USER_USER_DATA.jwt}
    }

    state._userData = Object.assign({}, userData, newUserData)

    if (!state._userData.user || !state._userData.jwt) {
      throw new Error(`Данные юзера не валидны, ожидается: {user: {id, name}, token: ".., а не: ${JSON.stringify(authData)}`)
    }

    if (ADMIN_LOGGED_AS_USER_USER_DATA) {
      console.warn('skip userSetAuthData in localStorage, reason: Logged as other user')
    } else {
      if (typeof window !== 'undefined' && dontSaveInStorage === false) {
        window.localStorage.setItem(USER_DATA, JSON.stringify(state._userData))
      }
    }

    // https://docs.sentry.io/enriching-error-data/context/?platform=javascript#capturing-the-user
    // id, username, email
    configureScope(scope => {
      scope.setUser({
        id: state._userData.user.id,
        username: depersonalize(state._userData.user.name),
      })
    })

  },
  userDelAuthData: state => {
    state._userData = null

    if (typeof window !== 'undefined') {
      if (ADMIN_LOGGED_AS_USER_USER_DATA) {
        console.warn('skip removeItem from localStorage, reason: Logged as other user')
      } else {
        window.localStorage.removeItem(USER_DATA)
      }
    }
  },
  userSetRedirectUrl: (state, route) => {
    if (route && route.name && HIDDEN_ROUTES_FOR_REGISTERED.indexOf(route.name) > -1) {
      return
    }
    state._redirectUrl = Object.assign({}, route)
  },
  authStatus: (state, loggedIn = false) => {
    state._authStatus = Object.assign({}, {loggedIn, emittedAt: +new Date()})
  },
  mutateError: (state, error) => {
    if (error === null) {
      state._error = null
    } else {
      state._error = Object.assign({}, recognizeError(error))
    }
  },
  setUnreadCount: (state, value = 0) => {
    state.unreadCount = value < 0 ? 0 : value
  },
  cleanAllCaches: (state) => {
    state.rest = Object.assign({}, {
      endpoints: {},
      cache: {},
      endpointsState: {},
      notReactiveEndpoints: {}
    })
  }
}
