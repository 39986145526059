import {DefaultRestClient} from 'vue-fast-rest'

export class ApiClient extends DefaultRestClient {

  /**
   * @param injected
   * @param error
   * @param reqParams
   * @param reqParams.params - параметры для axios
   * @returns {Promise<Q.Promise<Promise<never>|*> | * | undefined | Promise<T | Promise<never> | *>|Promise<Promise<never>|*>>}
   */
  async onError (injected = {}, error = {}, reqParams = {}) {
    // console.log('reqParams', reqParams)
    const {store} = injected
    const {response = {}, config = {}} = error
    const {authRepeats = 0, params = {}} = reqParams
    const {disableGlobalError = false} = params
    const isActionMethod = ['post', 'delete', 'patch'].indexOf(config.method) > -1

    if (+response.status === 401) {
      // eslint-disable-next-line
      console.debug('ApiClient', 'authServerService', authRepeats, injected)

      store.commit('authStatus', false)
      disableGlobalError === false && store.commit('mutateError', error)
    } else {
      // выкидываем глоабльное исключение если не post/path/delete(прежполагается что такие исключения будут обрабатываться вручную)
      // и нет запрета на его использвоание
      if (isActionMethod === false && disableGlobalError === false) {
        store.commit('mutateError', error)
      }
    }

    return Promise.reject(error)
  }
}

export default new ApiClient()
