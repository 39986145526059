<template>
  <div
    class="orders orders-journal"
  >
    <t-filter-block>
      <t-form
        name="ordersFilter"
        :formValidator="formValidator"
      >
        <t-form-group
          placeholder="Сортировка"
          name="sort"
          v-model="model.sort"
          type="select"
          :options="sort"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
        <t-form-group
          placeholder="№ Заказ-наряда"
          name="_oz"
          v-model="model.number"
          @change="onSomeFieldChangedInFilter"
          type="select-suggestion"
          :options="numbers"
          :remote-method="findNumbers"
          :remote="true"
        ></t-form-group>
        <t-form-group
          placeholder="Вид ремонта"
          name="_owt"
          v-model="model['appl_form.work_type.id']"
          type="select"
          @change="onSomeFieldChangedInFilter"
          :options="workTypes"
        ></t-form-group>
        <t-form-group
          placeholder="Статус"
          name="_os"
          v-model="model.status"
          type="select"
          :options="statuses"
          @change="onSomeFieldChangedInFilter"
        ></t-form-group>
        <t-form-group
          placeholder="Подразделение заказчика"
          name="_od"
          v-model="model[keys.DEPARTMENT_KEY]"
          type="select-suggestion"
          :options="model.departments"
          @change="onSomeFieldChangedInFilter"
          :remote-method="findDepartments"
          :remote="true"
        ></t-form-group>
        <t-form-group
          placeholder="Гос номер, модель ТС"
          name="_ov"
          v-model="model[keys.VEHICLE_KEY]"
          @change="onSomeFieldChangedInFilter"
          type="select-suggestion"
          :options="model.vehicles"
          :remote-method="findVehicles"
          :remote="true"
        ></t-form-group>
      </t-form>

      <template slot="actions">
        <t-button
          iconType="resetFilter"
          icon
          transparent
          @click="resetFilters"
        ></t-button>

        <t-pagination-simple
          :meta="itemMeta"
        ></t-pagination-simple>
      </template>
    </t-filter-block>

    <orders-table
      :itemData="itemData"
      :activeItemId="activeItemId"
    />

    <router-view/>
  </div>
</template>

<script>
  import {FormValidator} from "../../components";
  import {onlyUniqItemsInRef, queryToModel} from "../../utils/helpers";
  import {REST} from "vue-fast-rest";
  import {voc, workOrders} from "../../api/endpoints";
  import {DEFAULT_ORDERS, ORDER_STATUSES} from "../../configs/config";
  import OrdersTable from "./components/orders-table";

  const DEPARTMENT_KEY = 'root_department_id'
  const VEHICLE_KEY = 'appl_form.vehicle_id'

  export default {
    components: {OrdersTable},
    data: () => ({
      model: {
        sort: '',
        number: '',
        'appl_form.work_type.id': '',
        status: '',
        [DEPARTMENT_KEY]: '',
        [VEHICLE_KEY]: '',
        departments: [],
        vehicles: [],
      },
      numbers: [],
    }),
    created () {
      this.formValidator = new FormValidator()

      this.$watch(
        () => this.model[DEPARTMENT_KEY],
        this.onDepartmentIdChanged,
      )

      queryToModel(this.$route.query, this.model, {
        onTransform: this.transformQueryToModel,
        disableArrays: true,
      })

      this.fillDepartmentsRefs()

      this.fillNumbersRefs(!this.model.number)
    },
    watch: {
      '$route': {
        handler() {
          queryToModel(this.$route.query, this.model, {
            onTransform: this.transformQueryToModel,
            disableArrays: true,
          })

          if (!this.model.department_id) {
            this.fillDepartmentsRefs()
          }

          if (!this.model.number) {
            this.fillNumbersRefs(true)
          }
        }
      }
    },
    methods: {
      transformQueryToModel(v, name) {
        if (!v && name === `sort`) {
          return DEFAULT_ORDERS.workOrders
        }
        if (name === `vehicles`) {
          if (v && this.model.vehicles.length === 0) {
            return JSON.parse(v)
          }
          return this.model.vehicles
        }
        if (name === `departments`) {
          if (v && this.model.departments.length === 0) {
            return JSON.parse(v)
          }
          return this.model.departments
        }
        if ([`sort`, `number`].includes(name)) {
          return `${v || ``}`
        }
        return v ? +v : ``
      },
      async findDepartments(term) {
        if (term && term.length > 0) {
          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            endpoint: voc.department(term),
          })
          this.model.departments = data.data.items.map(v => ({
            id: v.id,
            name: v.name,
          }))
        } else {
          this.fillDepartmentsRefs()
        }
      },
      async findVehicles(term) {
        const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.departmentVehicles(this.model[DEPARTMENT_KEY], term),
          cache: Infinity,
        });
        this.model.vehicles = data.data.items
      },
      async findNumbers(term) {
        if (term && term.length > 0) {
          const { data } = await this.$store.dispatch(REST.actions.updateUrlEndpoint, {
            endpoint: workOrders.ordersByNumber(term, this.model.sort),
          });
          this.numbers = onlyUniqItemsInRef(data.data.items.map(v => ({
            id: v.number,
            name: v.number || `n/a`,
          })))
        } else {
          this.fillNumbersRefs(true)
        }
      },
      fillNumbersRefs(fromCurrentPage = false) {
        const itemsOnCurrentPage = this.itemData.items.map(v => ({
          id: v.number,
          name: v.number || `n/a`,
        }))

        if (fromCurrentPage) {
          this.numbers = onlyUniqItemsInRef(itemsOnCurrentPage)
          return
        }

        this.numbers = onlyUniqItemsInRef(this.numbers, itemsOnCurrentPage)
      },
      fillDepartmentsRefs() {
        const { data: { items: departments } } = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.department(),
        )

        this.model.departments = onlyUniqItemsInRef(this.model.departments, departments)
      },
      onSomeFieldChangedInFilter () {
        this.$router.push({
          query: {
            ...this.$route.query,
            ...this.model,
            vehicles: JSON.stringify(this.model.vehicles.filter(v => v.id == this.model[VEHICLE_KEY]).map(v => ({
              id: v.id,
              name: v.name,
            }))),
            departments: JSON.stringify(this.model.departments.filter(v => v.id == this.model[DEPARTMENT_KEY]).map(v => ({
              id: v.id,
              name: v.name,
            }))),
          }
        }, () => {})
      },
      onRowClick(row) {
        this.$router.push({
          name: 'orders.orderView',
          params: { id: row.id },
        }).catch(() => {})
      },
      resetFilters() {
        this.$router.push({name: 'orders'}, () => {}, () => {})
      },
      async onDepartmentIdChanged(departmentId) {
        if (departmentId) {
          await this.findVehicles()
        } else {
          this.model.vehicles = []
        }

        const found = this.model.vehicles.find(v => v.id === +this.model[VEHICLE_KEY])
        if (!found) {
          this.model[VEHICLE_KEY] = ""
        }

        this.onSomeFieldChangedInFilter()
      },
    },
    computed: {
      keys() {
        return {
          DEPARTMENT_KEY,
          VEHICLE_KEY,
        }
      },
      workTypes() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          voc.workType(),
        )
      },
      departments() {
        const { data: { items: departments } } = this.$store.getters[REST.getters.readUrlEndpoint](
          voc.department(),
        )

        return departments
      },
      activeItemId() {
        return this.$route.params.id
      },
      sort() {
        return [
          {id: 'create_dt-', name: 'Сначала новые'},
          {id: 'create_dt+', name: 'Сначала старые'},
        ]
      },
      statuses() {
        return [...ORDER_STATUSES]
      },
      itemData() {
        return this.$store.getters[REST.getters.readUrlEndpoint](
          workOrders.workOrdersList(this.$route),
        ).data
      },
      itemMeta() {
        return {
          page: this.$route.query.page || 0,
          page_count: this.itemData.page_count,
          page_size: this.itemData.page_size,
          total_count: this.itemData.total_count,
          count: this.itemData.items.length,
        }
      },
    },
    async asyncData ({ store, route }) {
      await Promise.all([
        store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.workType(),
          cache: Infinity,
        }),
        store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint: voc.department(),
          cache: Infinity,
        }),
      ])

      const endpoint = workOrders.workOrdersList(route)
      const loadedList = store.getters[REST.getters.readUrlEndpoint](endpoint)

      // загружаем данные для списка только если это:
      if (
        // открытие списка без модалки
        !route.params.id
        // переход в модалку и список еще не был загружен
        || (route.params.id && !loadedList)
      ) {
        await store.dispatch(REST.actions.updateUrlEndpoint, {
          endpoint,
        })
      }
    },
  }
</script>

<style lang="scss">
  .orders-journal {

  }
</style>
